import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { InputAdornment, Paper, Popper } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer, toast } from "react-toastify";
import { MuiFileInput } from "mui-file-input";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { images } from "../../../../config/images";
import CheckIcon from "@mui/icons-material/Check";
import {
  getTagConfiguration,
  getAlarmConfiguration,
  exportCSVConfiguration,
  importCSVConfiguration,
  addConfigurationApi,
  updateConfigurationApi,
  getDeviceById,
  getDeviceByMachineData,
  getTagsGroups,
  getDeviceData,
} from "../../service";
import TabConfigure1 from "./ConfigureExpand/TabConfigure1";
import "./Configure.css";
import Loader from "../../../../components/Loader/Loader";
import { Grid } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import {
  ScientiFicCalculator,
  UpdateSoftTag,
} from "../ScientificCalculator/ScientificCalculator";
import ConfigureShimmer from "./ConfigureShimmer";
import ConfigureScrollerShimmer from "./ConfigureScrollerShimmer";
import { setHeaderDetails } from "../../../Header/headerSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function Configure() {
  const [value, setValue] = React.useState("1");
  const [alarmConfData, setAlarmConfData] = useState([]);
  const [tagConfData, setTagConfData] = useState([]);
  const [openImportCSVModal, setOpenImportCSVModal] = useState("");
  const [openTagConfigurationModal, setTagConfigurationModal] = useState("");
  const [openAlarmConfigurationModal, setAlarmConfigurationMOdal] =
    useState("");
  const [softTagModal, setSoftTagModal] = useState(false);
  const [addTagModal, setTagModal] = useState(false);
  const [updateSofttag, setupdateSofttag] = useState(false);
  const [file, setFile] = React.useState(null);
  const [isDisable, setDisable] = useState(false);
  const [openCalModel, setOpenCalModel] = useState(false);
  const [expression, setExpression] = useState("");
  const [isShimmerConfigure, setisShimmerConfigure] = useState(false);
  const [selectedTags, setselectedTags] = useState([]);
  const [selectedTagFormula,setSelectTagFormula] = useState("")
  const [selectSoftTag,setSelectSoftTag] = useState("")
  const [selectTagId,setSelectTagId] = useState("")
  const [selectTagName,setSelectTagName] = useState("")
  const [apiFormula, setApiFormula] = useState(""); // State for API formula
  const [tagIds, setTagIds] = useState([]); // State for storing tag IDs
  const [formulaData,setFormulaData] = useState("")
  const [updateSoftTagId,setUpdateSoftTagId] = useState([])

  console.log("isDisable",selectedTagFormula)

  const { user_access } = useSelector((state) => state.userState);
  // console.log("selectedTagselectedTag", expression);
  const navigate = useNavigate();
  const location = useParams();

  const param = useParams();

  const initialData = {
    soft_tag_id: "",
    soft_tag_formula: "",
    tag_name: "",
    tag_comment: "",
    device_tag: "",
    tag_unit: "",
    aggregation: "",
    tag_read_write_type: "",
    signal_type: "",
    low_range: "",
    high_range: "",
    alert: "",
    alarm_mail: "",
    alert_priority: "",
    alert_message_1: "",
    alert_message_0: "",
    setpoint_hi: "",
    setpoint_lo: "",
    setpoint_hh: "",
    setpoint_ll: "",
    hys: "",
    almsup: "",
    device: location.id,
    soft_tag_real_data:"",
    soft_tag_real_formula:""
  };

  const [addConfiguration, setAddConfiguration] = useState(initialData);
  const [editIcone, setEditIcone] = useState({});
  const [tagConfId, setTagConfId] = useState("");

  const [alarmConfId, setAlarmConfId] = useState("");
  const [deviceName, setDeviceName] = useState([]);
  const [lockStatus, setLoackStatus] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [machineName, setMachineName] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceID, setDeviceID] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState({});
  const [addTagCount, setaddTagCount] = useState(true);

  const [TagName, setTagName] = useState();
  const [Tag_Description, setTag_Description] = useState();

  const containerRef = useRef(null);
  console.log("addConfiguration",addConfiguration)

  const [tagGroups, settagGroups] = useState();
  const dispatch = useDispatch();

  const tagArray = [
    "HIKEMM1A_WIXV_01",
    "HIKEMM1A_WIXV_02",
    "HIKEMM1A_WIXV_03",
    "HIKEMM1A_WIXV_04",
  ];

  const [inputTag, setinputTag] = useState();

  const [RemoveTag, setRemoveTag] = useState();
  const [openPopperKey, setOpenPopperKey] = useState(null);

  console.log("addConfiguration",addConfiguration)

  // useEffect(() => {
  //   setAnchorEl(null);
  // }, []);

  const openMachine = Boolean(anchorEl);

  useEffect(() => {
    handlegetDeviceByMachine();
  }, []);

  useEffect(() => {
    console.log("addConfiguration", addConfiguration);
  }, [addConfiguration]);

  useEffect(() => {
    console.log("TagName....", TagName);
    console.log("Description....", Tag_Description);
  }, [TagName, Tag_Description]);
  const handleReverseRemoveTag = () => {
    setRemoveTag("");
  };

  useEffect(() => {
    if (deviceID) {
      GetTagGroupbyDeviceID();
    }
  }, [deviceID]);

  const GetTagGroupbyDeviceID = async () => {
    try {
      const params = {
        device_id: deviceID,
      };
      const tagGroup = await getTagsGroups(params);
      console.log("tagGroup....", tagGroup.data.payload.group_data.length);
      if (tagGroup.data.payload.tags_data.length > 0) {
        console.log("No tagGroup", tagGroup.data.payload.tags_data);
      }

      const group = tagGroup.data.payload;
      settagGroups(group);
      // console.log("Object.keys", Object.keys(group));

      // group.forEach((e) => {
      //   console.log("Object.values(e)[0].tags", Object.values(e))
      //   const valuesArray = Object.keys(e);
      //   // console.log("Tag Group:", key);
      //   // valuesArray.forEach((value) => {
      //   //   console.log("Tag ID:", value.id);
      //   //   // data.push()
      //   // });
      // });
    } catch (e) {
      console.log("Error from GetTagGroupbyDeviceID....", e);
    }
  };

  const getDeviceName = async () => {
    const params = {
      machine: location.id,
    };
    const res = await getDeviceData(params);
    if (res.status == 200) {
      setDeviceName(res.data.payload[0].device_name);
      dispatch(
        setHeaderDetails({
          headerName: res.data.payload[0].device_name,
        })
      );
    }
    console.log("res", res.data);
  };

  useEffect(() => {
    getDeviceName();
  }, []);

  const reverse_softTagModal_handle = (e) => {
    console.log("TagName....", TagName);
    console.log("Tag_Description...", Tag_Description);
    setSoftTagModal(e);
    setTagModal(false);
    setselectedTags([]);
  };

  const handleReverseClosepopup = () => {
    try {
      console.log("TagName....", TagName);
      setTagName("");
      setTagModal(false);
      setTag_Description("");
      setupdateSofttag(false);
      setselectedTags([]);
      setAddConfiguration({
        ...addConfiguration,
        soft_tag_id: "",
        soft_tag_formula: "",
      });

      if (deviceID) {
        getTagConfigurationData();
      }
    } catch (e) {
      console.log("Error from handleReverseClosepopup", e);
    }
  };

  const handleOpenSoftTag = () => {
    const specialCharPattern = /[ !@#$%^&*(),.?":{}|<>+\-*/=~`]/g; // Regular expression to match spaces, special characters, and operators
  
    if (specialCharPattern.test(TagName)) {
      notifyError("Tag Name should not contain spaces, special characters, or operators");
    } else if (TagName && Tag_Description) {
      setTagModal(true);
      setSoftTagModal(false);
    } else if (!TagName && Tag_Description) {
      notifyError("Tag Name is Required");
    } else if (TagName && !Tag_Description) {
      notifyError("Description is Required");
    } else if (!TagName && !Tag_Description) {
      notifyError("Tag Name and Description are Required");
    } else {
      console.log("Error from handleOpenSoftTag.....");
    }
  };
  
  

  const handlebutton_disable_reverse = (e) => {
    setaddTagCount(e);
  };
  const handleReverseaddTag = () => {
    setinputTag("");
  };
  const handlegetDeviceByMachine = async () => {
    const params = {
      machine_id: param.id,
    };
    try {
      const resp = await getDeviceByMachineData(params);
      if (resp.status == 200 || resp.status == 201) {
        setMachineName(resp.data.payload.machine_name);
        setDeviceData(resp.data.payload.device_name);
        setDeviceID(
          resp.data.payload
            ? resp.data.payload.device_name.length
              ? resp.data.payload.device_name[0].id
              : ""
            : ""
        );
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  // const handleClick = async (event, groupKey) => {
  //   console.log("......handle click",event.currentTarget)
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  //   // setAnchorEl({
  //   //   ...anchorEl,
  //   //   [groupKey]: anchorEl[groupKey] ? null : event.currentTarget,
  //   // });
  // };

  const handleClick = (event, dropdownId) => {
    setOpenPopperKey(openPopperKey === dropdownId ? null : dropdownId);
    // setAnchorEl({
    //   ...anchorEl,
    //   [dropdownId]: anchorEl[dropdownId] ? null : event.currentTarget,
    // });
  };

  const isOpen = (groupKey) => Boolean(anchorEl[groupKey]);

  const handleEditRow = (id) => {
    setTagConfId(id);
    if (!lockStatus) {
      setEditIcone((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };


const handleupdatesofttag = (e) => {
    console.log("tagMapping", e);
    setUpdateSoftTagId(e.is_soft_tag ? e.id : [])
    setFormulaData(e)
    if (!lockStatus) {
        setupdateSofttag(true);
        
        // Create a mapping of soft_tag_id to tag_name
        const softTagMapping = {};
        e.soft_tag.forEach(tag => {
            softTagMapping[tag.tag] = tag.tag_name; // Map ID to name
        });

        // Split the formula using a regex that captures the operators
        const softTagRealFormula = e.soft_tag_real_formula.split(/([+\-*\/])/).map((item) => {
            const trimmedItem = item.trim();
            // Check if the item is a soft_tag_id and replace it
            const replacedItem = softTagMapping[trimmedItem] || trimmedItem; // Replace with name or keep original
            
            console.log(`Replacing "${trimmedItem}" with "${replacedItem}"`);
            return replacedItem; 
        }).filter(Boolean) 
          .join(''); 

        console.log("Updated Formula:", softTagRealFormula);
        

        setAddConfiguration({
            ...addConfiguration,
            soft_tag_id: e.soft_tag_id,
            soft_tag_formula: softTagRealFormula, // Use modified formula
            soft_tag_real_formula : e.soft_tag_real_formula
        });
        
        setTagName(e.tag_name);
        if (e.device_tag_list) {
            setselectedTags(e.device_tag_list);
        }
    }
};



  const handleEditAlarmRow = (id) => {
    setAlarmConfId(id);
    if (!lockStatus) {
      setEditIcone((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const handleInputChange = (id, field, value) => {
    setTagConfData((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      const updatedRow = { ...prev[index], [field]: value };
      if (field == "signal_type") {
        const updatedRow = { ...prev[index], [field]: value, ["low_range"]: " ", ["high_range"]: " " };
        const updatedData = [...prev];
        updatedData[index] = updatedRow;
        return updatedData;
      }
      const updatedData = [...prev];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  const handleAlarmInputChange = (id, field, value) => {
    setAlarmConfData((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      const updatedRow = { ...prev[index], [field]: value };
      const updatedData = [...prev];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value == "2") {
      scrollRight();
    }
  }, [value]);

  const downloadCSV = (data) => {
    fetch(data)
      .then((response) => response.blob())
      .then((blob) => {
        const csvData = new Blob([blob], { type: "text/csv" });
        const csvUrl = URL.createObjectURL(csvData);
        window.open(csvUrl);
      });
  };

  // const getAlarmConfigurationData = async () => {
  //   setisShimmerConfigure(true);
  //   const params = {
  //     device: deviceID,
  //   };
  //   try {
  //     const resp = await getAlarmConfiguration(params);
  //     if (resp.status == 200 || resp.status == 201) {
  //       setTimeout(() => {
  //         setisShimmerConfigure(false);
  //       }, 1000);
  //       const payload = resp.data.payload;

  //       setAlarmConfData(payload);
  //     }
  //   } catch (error) {
  //     setisShimmerConfigure(false);
  //     setTagConfData([]);
  //     console.log("error", error.response.data.message);
  //     // notifyError(error.response.data.message);
  //   }
  // };

  const getTagConfigurationData = async () => {
    setisShimmerConfigure(true);
    const params = {
      device: deviceID,
    };
    try {
      const resp = await getTagConfiguration(params);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerConfigure(false);
        }, 1000);
        setTagConfData(resp.data.payload);
      }
    } catch (error) {
      setisShimmerConfigure(false);
      setTagConfData([]);
      console.log("error", error.response.data.message);
      // notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (deviceID) {
      getTagConfigurationData();
    }
  }, [deviceID]);

  const submitAddConfiguration = async () => {
    const resp = await addConfigurationApi(addConfiguration);
    if (resp.status == 200) {
      notifySuccess(resp.data.message);
      // handleOpenTagConfiguration();
      getTagConfigurationData();
    }
  };

  const handleSoftTagclick = () => {
    setSoftTagModal(true);
  };

  const handleExportCSVConfiguration = async () => {
    const param = {
      device: deviceID,
    };

    const resp = await exportCSVConfiguration(param);

    if (resp.status == 200) {
      console.log("resp", resp);

      const url = resp.data.payload.filename;
      const filename = url.split("/").pop();

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      notifySuccess(resp.data.message);
    }
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile);
  };

  //Import CSV Modal
  const handleImportCSV = () => {
    if (lockStatus) {
      return true;
    }

    setOpenImportCSVModal(!openImportCSVModal);
    setFile(null);
  };

  //TagConfiguration Modal
  // const handleOpenTagConfiguration = () => {
  //     setTagConfigurationModal(!openTagConfigurationModal);
  //     setAddConfiguration(initialData)
  // }
  //AlarmConfiguration Modal
  const handleOpenAlarmConfiguration = () => {
    setAlarmConfigurationMOdal(!openAlarmConfigurationModal);
  };

  //Add configuration
  const handleChangeAddConfiguration = (e) => {
    setAddConfiguration({
      ...addConfiguration,
      [e.target.name]: e.target.value,
    });
  };

  function isCSVFile(fileName) {
    if (fileName.endsWith(".csv")) {
      return false;
    } else {
      notifyError("Please Add CSV file");
      return true;
    }
  }

  const handleImportCSVConfiguration = async () => {
    if (file == null) {
      notifyError("Please Add CSV file");
      return true;
    }   

    // check CSV file or Not
    // isCSVFile(file.name);

    if (!file.name.endsWith(".csv")) {
      notifyError("Please Add CSV file");
      return true;
    }
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("device", deviceID);
      formData.append("excel_file", file);
      const resp = await importCSVConfiguration(formData);

      if (resp.status == 200) {
        setInterval(() => {
          setLoading(false);
        }, 2000);

        notifySuccess("Import successfully");
        handleImportCSV();
        setFile(null);
        getTagConfigurationData();
        // getAlarmConfigurationData();
      }
    } catch (error) {
      console.log("error", error);
      setInterval(() => {
        setLoading(false);
      }, 2000);
      notifyError(error.response.data.message);
    }
  };

  const submitupdateTagConfiguration = async (tagId) => {
    const param = tagConfData.find((f) => f.id == tagId);

    if (lockStatus) {
      notifyError("Please Unlock");
      return true;
    }

    const resp = await updateConfigurationApi(tagId, param);

    if (resp.status == 200) {
      setEditIcone((prev) => ({ ...prev, [tagId]: !prev[tagId] }));
      notifySuccess("update Successfully");
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      let scrollAmount = 875; // Default scroll amount

      // Adjust the scroll amount based on the viewport width
      if (window.matchMedia("(max-width: 1024px)").matches) {
        scrollAmount = 790; // Adjusted scroll amount for tablet screens
      }

      containerRef.current.scrollLeft += scrollAmount;
    }
  };
  const hasDuplicates = (dataArray, tagName) => {
    const count = dataArray.filter((item) => item.tag_name === tagName).length;
    return count > 1;
  };

  const calculateDisableState = () => {
    let isDisabled = false;

    console.log("");
    for (const e of tagConfData) {
      if (!(e.tag_name && e.tag_name.length <= 18)) {
        isDisabled = true;
        break;
      }
      if (hasDuplicates(tagConfData, e.tag_name)) {
        isDisabled = true;
        break;
      }

      if (!(e.tag_comment && e.tag_comment.length <= 24)) {
        isDisabled = true;
        break;
      }

      if (
        !(e.tag_unit && e.tag_unit.length <= 10)
      ) {
        isDisabled = true;
        break;
      }

      if (
        !(e.tag_read_write_type && ["RD", "RW"].includes(e.tag_read_write_type))
      ) {
        isDisabled = true;
        console.log("tag_read_write_type");
        break;
      }

      if (
        !(e.tag_group && ["DI", "DO"].includes(e.signal_type))
      ) {
        isDisabled = false;
        console.log("tag_group");
        break;
      }
      if (
        (e.tag_group && ["DI", "DO"].includes(e.signal_type))
      ) {
        isDisabled = false;
        console.log("tag_group");
        break;
      }
      if (
        !(e.signal_type && ["AI", "AO", "DI", "DO"].includes(e.signal_type))
      ) {
        isDisabled = true;
        console.log("signal_type");
        break;
      }

      if (
        !(["AI", "AO"].includes(e.signal_type)
          ? e.low_range && Number(e.low_range) < Number(e.high_range)
          : e.low_range ? e.low_range.length <= 8 && !(/[,@!&)(}{#]/).test(e.low_range) ? true : false : true)
        // (e.high_range.length <= 8 && !(/[,.@!&)(}{#]/).test(e.high_range) ? "" : "bgred")
      ) {
        isDisabled = true;
        console.log("low_range", (["AI", "AO"].includes(e.signal_type)
          ? e.low_range && Number(e.low_range) < Number(e.high_range)
          : e.low_range ? e.low_range.length <= 8 ? true : false : true));
        break;
      }

      if (
        !(["AI", "AO"].includes(e.signal_type)
          ? e.high_range && Number(e.low_range) < Number(e.high_range)
          : e.high_range ? e.high_range.length <= 8 && !(/[,@!&)(}{#]/).test(e.high_range) ? true : false : true)
      ) {
        isDisabled = true;
        console.log("high_range");
        break;
      }

      if (!(e.alert && ["yes", "no"].includes(
        e.alert?.toLowerCase()
      ))) {
        isDisabled = true;
        console.log("alert");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["yes", "no"].includes(
            e.alarm_mail?.toLowerCase()
          )
          : true)
      ) {
        isDisabled = true;
        console.log("alarm_mail");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["high", "medium", "low"].includes(
            e.alert_priority?.toLowerCase()
          )
          : true)
      ) {
        isDisabled = true;
        console.log("alert_priority");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? e.alert_message_1 && e.alert_message_1.length <= 24
          : true)
      ) {
        isDisabled = true;
        console.log("alert_message_1");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? e.alert_message_0 && e.alert_message_0.length <= 24
          : true)
      ) {
        isDisabled = true;
        console.log("alert_message_0");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["DI", "DO"].includes(e.signal_type) != true
            ? e.signal_type == "AI" &&
            e.setpoint_hh &&
            Number(e.high_range) >= Number(e.setpoint_hh) &&
            Number(e.setpoint_hh) > Number(e.setpoint_hi) &&
            Number(e.setpoint_hi) > Number(e.setpoint_lo) &&
            Number(e.setpoint_lo) > Number(e.setpoint_ll) &&
            Number(e.setpoint_ll) >= Number(e.low_range)
            : true
          : true)
      ) {
        isDisabled = true;
        console.log("setpoint_hh");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["DI", "DO"].includes(e.signal_type) != true
            ? e.signal_type == "AI" &&
            e.setpoint_hi &&
            Number(e.high_range) >= Number(e.setpoint_hh) &&
            Number(e.setpoint_hh) > Number(e.setpoint_hi) &&
            Number(e.setpoint_hi) > Number(e.setpoint_lo) &&
            Number(e.setpoint_lo) > Number(e.setpoint_ll) &&
            Number(e.setpoint_ll) >= Number(e.low_range)
            : true
          : true)
      ) {
        isDisabled = true;
        console.log("setpoint_hi");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["DI", "DO"].includes(e.signal_type) != true
            ? e.signal_type == "AI" &&
            e.setpoint_lo &&
            Number(e.high_range) >= Number(e.setpoint_hh) &&
            Number(e.setpoint_hh) > Number(e.setpoint_hi) &&
            Number(e.setpoint_hi) > Number(e.setpoint_lo) &&
            Number(e.setpoint_lo) > Number(e.setpoint_ll) &&
            Number(e.setpoint_ll) >= Number(e.low_range)
            : true
          : true)
      ) {
        isDisabled = true;
        console.log("setpoint_lo");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["DI", "DO"].includes(e.signal_type) != true
            ? e.signal_type == "AI" &&
            e.setpoint_ll &&
            Number(e.high_range) >= Number(e.setpoint_hh) &&
            Number(e.setpoint_hh) > Number(e.setpoint_hi) &&
            Number(e.setpoint_hi) > Number(e.setpoint_lo) &&
            Number(e.setpoint_lo) > Number(e.setpoint_ll) &&
            Number(e.setpoint_ll) >= Number(e.low_range)
            : true
          : true)
      ) {
        isDisabled = true;
        console.log("setpoint_ll");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["DI", "DO"].includes(e.signal_type) != true
            ? e.signal_type == "AI" &&
            e.hys &&
            1 <= Number(e.hys) &&
            Number(e.hys) <= 10
            : true
          : true)
      ) {
        isDisabled = true;
        console.log("hys");
        break;
      }

      if (
        !(e.alert && e.alert?.toLowerCase() != "no"
          ? ["true", "false"].includes(
            e.almsup?.toLowerCase()
          )
          : true)

      ) {
        isDisabled = true;
        console.log("almsup");
        break;
      }
    }

    setDisable(isDisabled);
  };

  useEffect(() => {
    calculateDisableState();
  }, [tagConfData]);

  // handle tag select

  const handleTagSelect = (type, tag, isSoftTag, tagId) => {
    setSelectSoftTag(isSoftTag)
    setSelectTagId(tagId)
    setSelectTagName(tag)
    if (type === "add") {
      let combinedTagFormula = "";
  
      if (isSoftTag) {
        // Use tagId for soft tags
        combinedTagFormula = `${tagId}`;
      } else {
        // Use device_tag for non-soft tags
        combinedTagFormula = `${tag}`;
      }
  
      // Concatenate with the existing formula
      setSelectTagFormula((prevFormula) => prevFormula ? `${prevFormula}${combinedTagFormula}` : combinedTagFormula);
  
      // Proceed with the rest of the logic for adding tags
      if (tag) {
        setaddTagCount(false);
        setinputTag(tag);
        setselectedTags([...selectedTags, tag]);
      }
  
      console.log("Added combined tag formula....", combinedTagFormula);
      console.log("Full selectedTagFormula....", selectedTagFormula);
    }
  
    if (type === "remove") {
      console.log("selectedTags before removal.....", selectedTags);
  
      const filter_data = selectedTags.filter((tag_data) => tag_data !== tag);
      setselectedTags(filter_data);
  
      // Update the selectedTagFormula by removing the tag or tagId from the string
      setSelectTagFormula((prevFormula) => {
        const formulaArray = prevFormula.split(',');
        const updatedFormula = formulaArray.filter((f) => f !== tag);
        return updatedFormula.join(',');
      });
  
      setRemoveTag(tag);
      console.log("Removed....", tag);
    }
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementDevice = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Device Management"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access.");
  };

  return (
    <>
      <div className="containerWrap">
        {/*  */}
        {/* <div>Configure</div> */}
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap configure">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                    onClick={() => {
                      if (param.plantmanagement) {
                        navigate("/plantmanagement");
                        return true;
                      }
                      navigate("/deviceManagement")
                    }
                    }
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                onClick={() => {
                  if (param.plantmanagement) {
                    navigate("/plantmanagement");
                    return true;
                  }
                  navigate("/deviceManagement")
                }
                }
                gutterBottom
                style={{ margin: "0" }}
              >
                {deviceName}
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              {/* <button className='addDevice-button' onClick={handleOpenTagConfiguration}>Add Configuration</button> */}
              {/* {value == 2 && <button className='addDevice-button bridge' onClick={handleOpenAlarmConfiguration}>Add Alarm</button>} */}
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
              (!accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ? (
                <button
                  className={
                    isDisable
                      ? "addDevice-button-disable"
                      : "addDevice-button bridge"
                  }
                  onClick={() =>
                    deviceID
                      ? !isDisable
                        ? navigate(
                            `/deviceManagement/configure/device/machine/${param.id}/bridge/${deviceID}`
                          )
                        : null
                      : navigate(
                          `/deviceManagement/configure/device/bridge/${param.id}`
                        )
                  }
                >
                  Bridge
                  <img src={images.bridgeImg} alt="" />
                </button>
              ) : (
                <button
                  className={
                    isDisable
                      ? "addDevice-button-disable"
                      : "addDevice-button bridge"
                  }
                  onClick={handleTostMsg}
                >
                  Bridge
                  <img src={images.bridgeImg} alt="" />
                </button>
              )}
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
              (!accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ? (
                <>
                  <button
                    className="addDevice-button bridge"
                    onClick={handleExportCSVConfiguration}
                  >
                    Export CSV
                    <img src={images.csvImg} alt="" />
                  </button>
                  <button
                    className="addDevice-button bridge import"
                    onClick={handleImportCSV}
                  >
                    Import CSV
                    <img src={images.csvImg} alt="" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="addDevice-button bridge"
                    onClick={handleTostMsg}
                  >
                    Export CSV
                    <img src={images.csvImg} alt="" />
                  </button>
                  <button
                    className="addDevice-button bridge import"
                    onClick={handleTostMsg}
                  >
                    Import CSV
                    <img src={images.csvImg} alt="" />
                  </button>
                </>
              )}
              {/* <img src={images.excelLogo} alt="" className='cloudActiveImg' /> */}
            </div>
          </div>

          {/* =============== Import CSV Modal Start =============== */}
          {openImportCSVModal && (
            <Card className="importCSVModal">
              <Typography
                variant="h6"
                gutterBottom
                className="importCSVModal-text"
              >
                Import CSV
              </Typography>
              <Box className="ImportCSV Input">
                <MuiFileInput
                  value={file}
                  onChange={handleChangeFile}
                  placeholder="Insert a file"
                />
              </Box>
              {/* <input class="form-control" type="file" id="formFile" style={{marginBottom:'20px'}}/> */}
              <Box className="ImportCSV-BtnWrap">
                <button
                  className="ImportCSV-AddBtn"
                  onClick={handleImportCSVConfiguration}
                >
                  Add
                </button>
                <button
                  className="ImportCSV-CancelBtn"
                  onClick={handleImportCSV}
                >
                  Cancel
                </button>
              </Box>
            </Card>
          )}

          {softTagModal && (
            <>
              <Card className="addsoftTag-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Add SoftTag
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => setSoftTagModal(false)}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      label="TagName"
                      name="TagName"
                      variant="filled"
                      className="addplantInputfield"
                      // InputProps={{}}
                      value={TagName ? TagName : ""}
                      onChange={(e) => {
                        setTagName(e.target.value);
                      }}
                    />
                    <TextField
                      id="filled-basic"
                      label="Description"
                      name="Description"
                      variant="filled"
                      className="addplantInputfield"
                      // InputProps={{}}
                      onChange={(e) => {
                        setTag_Description(e.target.value);
                      }}
                      value={Tag_Description ? Tag_Description : ""}
                    />
                    {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn" onClick={handleOpenSoftTag}>
                      Add SoftTag
                    </button>
                  </Box>
                </Box>
              </Card>
            </>
          )}

          {addTagModal && (
            <>
              <div className="select-softTag-modal">
                {/* <div className="d-flex devices-text"> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6 style={{ color: "#1C407B" }} className="mt-2">
                    <b>{TagName}</b>
                  </h6>

                  {/* <button className="scientific-calc-btn" onClick={() => setOpenCalModel(prev => !prev)}>
                    Scientific Calculator
                  </button> */}
                </div>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={() => {
                    setTagModal(false);
                    handleReverseClosepopup();
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  className="pt-2 Softtag_model-container"
                >
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Box className="devicemodal-box">
                      <h6 style={{ fontWeight: 600 }}>List of All Tags</h6>
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        <Box>
                          {tagGroups.group_data.length > 0
                            ? tagGroups.group_data.map((groupKey, index) => {
                                const valuesArray = tagGroups.group_data.map(
                                  (group) => Object.keys(group)[0]
                                );
                                return (
                                  <div key={valuesArray[index]}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "13px 8px",
                                        width: "100%",
                                        background: "white",
                                        cursor: "pointer",
                                        marginTop: index > 0 ? "4px" : "0",
                                      }}
                                      onClick={(e) =>
                                        handleClick(e, valuesArray[index])
                                      }
                                    >
                                      {valuesArray[index]} <ArrowDropDownIcon />
                                    </div>

                                    <Popper
                                      open={
                                        openPopperKey === valuesArray[index]
                                      }
                                      anchorEl={anchorEl[valuesArray[index]]}
                                      placement="bottom"
                                      disablePortal={true}
                                      style={{
                                        width: "319px",
                                        zIndex: openCalModel ? 0 : 1000,
                                        position: "relative",
                                      }}
                                      className="popper-container"
                                    >
                                      <Paper
                                      // onClick={() => handleMachineClick(e.id, e)}
                                      >
                                        {Object.values(groupKey)[0].tags.map(
                                          (value, index) => (
                                            <div
                                              key={`${valuesArray[index]}-${index}`}
                                              style={{ padding: "10px" }}
                                              className="d-flex justify-content-between"
                                            >
                                              <h6 className="softTag-title">
                                                {value.tag_name}
                                              </h6>

                                              {selectedTags.includes(
                                                value.device_tag || value.tag_name
                                              ) ? (
                                                <h6
                                                  className="softTag-title"
                                                  name={value.id}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleTagSelect(
                                                      "remove",
                                                      value.device_tag || value.tag_name
                                                    )
                                                  }
                                                >
                                                  REMOVE
                                                </h6>
                                              ) : (
                                                <h6
                                                  className="softTag-title"
                                                  name={value.id}
                                                  style={{
                                                    cursor:
                                                      value.device_tag !== null || value.is_soft_tag
                                                        ? "pointer"
                                                        : "not-allowed",
                                                    color:
                                                      value.device_tag !== null || value.is_soft_tag
                                                        ? "inherit"
                                                        : "gray",
                                                  }}
                                                  onClick={
                                                    value.device_tag !== null || value.is_soft_tag
                                                      ? (e) => {
                                                          console.log(
                                                            "Adding tag:",
                                                            value.device_tag
                                                          );
                                                          handleTagSelect(
                                                            "add",
                                                            value.device_tag  || value.tag_name,  value.is_soft_tag,
                                                            value.id
                                                          );
                                                        }
                                                      : null
                                                  }
                                                >
                                                  ADD
                                                </h6>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </Paper>
                                    </Popper>
                                  </div>
                                );
                              })
                            : ""}

                          {tagGroups.tags_data.length > 0 ? (
                            <div className="nullgroup">
                              {Object.values(tagGroups.tags_data)[0].tags.map(
                                (value, index) => {
                                  const valuesArray = tagGroups.group_data.map(
                                    (group) => Object.keys(group)[0]
                                  );
                                  return (
                                    <div
                                      key={`${valuesArray[index]}-${index}`}
                                      style={{ padding: "10px" }}
                                      className="d-flex justify-content-between"
                                    >
                                      <h6 className="softTag-title">
                                        {value.tag_name}
                                      </h6>

                                      {selectedTags.includes(
                                        value.device_tag  || value.tag_name
                                      ) ? (
                                        <h6
                                          className="softTag-title"
                                          name={value.id}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleTagSelect(
                                              "remove",
                                              value.device_tag || value.tag_name
                                            )
                                          }
                                        >
                                          REMOVE
                                        </h6>
                                      ) : (
                                        <h6
                                          className="softTag-title"
                                          name={value.id}
                                          style={{
                                            cursor:
                                              value.device_tag !== null  || value.is_soft_tag
                                                ? "pointer"
                                                : "not-allowed",
                                            color:
                                              value.device_tag !== null  || value.is_soft_tag
                                                ? "inherit"
                                                : "gray",
                                          }}
                                          onClick={
                                            value.device_tag !== null || value.is_soft_tag
                                              ? (e) => {
                                                  console.log(
                                                    "Adding tag:",
                                                    value.device_tag
                                                  );
                                                  handleTagSelect(
                                                    "add",
                                                    value.device_tag  || value.tag_name,  value.is_soft_tag,
                                                    value.id
                                                  );
                                                }
                                              : null
                                          }
                                        >
                                          ADD
                                        </h6>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Box className="devicemodal-box">
                      <div className="d-flex justify-content-between">
                        <h6 style={{ fontWeight: 600 }}>Add Tags List</h6>
                      </div>

                      <div
                        className="pt-2 mb-2"
                        style={{
                          height: "378px",
                          overflow: "auto",
                        }}
                      >
                        {expression && (
                          <Box style={{ backgroundColor: "white" }}>
                            {expression}
                          </Box>
                        )}
                      
                        <ScientiFicCalculator
                          expression={expression}
                          setExpression={setExpression}
                          addtag={inputTag}
                          removetag={RemoveTag}
                          onRemoveTag={handleReverseRemoveTag}
                          onaddTag={handleReverseaddTag}
                          onClosePopup={handleReverseClosepopup}
                          Tag_Name_data={TagName}
                          Tag_Description_data={Tag_Description}
                          Device_ID={deviceID}
                          button_disable={addTagCount}
                          button_disable_reverse={handlebutton_disable_reverse}
                          handle_reverse_softagModal={
                            reverse_softTagModal_handle
                          }
                          SelectedTags={selectedTags}
                          handleTagSelect={handleTagSelect}
                          setselectedTags={setselectedTags}
                          selectedTagFormula={selectedTagFormula}
                          selectSoftTag={selectSoftTag}
                          selectTagId={selectTagId}
                          selectTagName={selectTagName}
                          setSelectTagId={setSelectTagId}
                          tagConfData={tagConfData}
                          setaddTagCount={setaddTagCount}
                          
                        />
                      </div>

                     
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {updateSofttag && (
            <>
              <div className="select-softTag-modal">
                {/* <div className="d-flex devices-text"> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6 style={{ color: "#1C407B" }} className="mt-2">
                    <b>{TagName}</b>
                  </h6>

                  {/* <button className="scientific-calc-btn" onClick={() => setOpenCalModel(prev => !prev)}>
                    Scientific Calculator
                  </button> */}
                </div>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={() => {
                    setupdateSofttag(false);
                    handleReverseClosepopup();
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  className="pt-2 Softtag_model-container"
                >
                  <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Box className="devicemodal-box">
                      <h6 style={{ fontWeight: 600 }}>List of All Tags</h6>
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        <Box>
                          {tagGroups.group_data.length > 0
                            ? tagGroups.group_data.map((groupKey, index) => {
                                const valuesArray = tagGroups.group_data.map(
                                  (group) => Object.keys(group)[0]
                                );

                                return (
                                  <div key={valuesArray[index]}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "13px 8px",
                                        width: "100%",
                                        background: "white",
                                        cursor: "pointer",
                                        marginTop: index > 0 ? "4px" : "0",
                                      }}
                                      onClick={(e) =>
                                        handleClick(e, valuesArray[index])
                                      }
                                    >
                                      {valuesArray[index]} <ArrowDropDownIcon />
                                    </div>

                                    <Popper
                                      open={
                                        openPopperKey === valuesArray[index]
                                      }
                                      anchorEl={anchorEl[valuesArray[index]]}
                                      placement="bottom"
                                      disablePortal={true}
                                      style={{
                                        width: "305px",
                                        zIndex: openCalModel ? 0 : 1000,
                                        position: "relative",
                                      }}
                                      className="popper-container"
                                    >
                                      <Paper

                                      // onClick={() => handleMachineClick(e.id, e)}
                                      >
                                        {Object.values(groupKey)[0].tags.map(
                                          (value, index) => (
                                            <div
                                              key={`${valuesArray[index]}-${index}`}
                                              style={{ padding: "10px" }}
                                              className="d-flex justify-content-between"
                                            >
                                              <h6 className="softTag-title">
                                                {value.tag_name}
                                              </h6>

                                              {selectedTags.includes(
                                                value.device_tag || value.tag_name
                                              ) ? (
                                                <h6
                                                  className="softTag-title"
                                                  name={value.id}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleTagSelect(
                                                      "remove",
                                                      value.device_tag || value.tag_name
                                                    )
                                                  }
                                                >
                                                  REMOVE
                                                </h6>
                                              ) : (
                                                <h6
                                                  className="softTag-title"
                                                  name={value.id}
                                                  style={{
                                                    cursor:
                                                      value.device_tag !== null || value.is_soft_tag
                                                        ? "pointer"
                                                        : "not-allowed",
                                                    color:
                                                      value.device_tag !== null || value.is_soft_tag
                                                        ? "inherit"
                                                        : "gray",
                                                  }}
                                                  onClick={
                                                    value.device_tag !== null || value.is_soft_tag
                                                      ? (e) => {
                                                          console.log(
                                                            "Adding tag:",
                                                            value.device_tag
                                                          );
                                                          handleTagSelect(
                                                            "add",
                                                            value.device_tag  || value.tag_name,  value.is_soft_tag,
                                                            value.id
                                                          );
                                                        }
                                                      : null
                                                  }
                                                >
                                                  ADD
                                                </h6>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </Paper>
                                    </Popper>
                                  </div>
                                );
                              })
                            : ""}

                          <div className="nullgroup">
                            {tagGroups.tags_data.length > 0
                              ? Object.values(tagGroups.tags_data)[0].tags.map(
                                  (value, index) => {
                                    const valuesArray =
                                      tagGroups.group_data.map(
                                        (group) => Object.keys(group)[0]
                                      );
                                    return (
                                      <div
                                        key={`${valuesArray[index]}-${index}`}
                                        style={{ padding: "10px" }}
                                        className="d-flex justify-content-between"
                                      >
                                        <h6 className="softTag-title">
                                          {value.tag_name}
                                        </h6>

                                        {selectedTags.includes(
                                          value.device_tag ||  value.tag_name
                                        ) ? (
                                          <h6
                                            className="softTag-title"
                                            name={value.id}
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleTagSelect(
                                                "remove",
                                                value.device_tag  || value.tag_name
                                              )
                                            }
                                          >
                                            REMOVE
                                          </h6>
                                        ) : (
                                          <h6
                                            className="softTag-title"
                                            name={value.id}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              handleTagSelect(
                                                "add",
                                                value.device_tag || value.tag_name,  value.is_soft_tag,
                                                value.id
                                              )
                                            }
                                          >
                                            ADD
                                          </h6>
                                        )}
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Box className="devicemodal-box">
                      <div className="d-flex justify-content-between">
                        <h6 style={{ fontWeight: 600 }}>Add Tags List</h6>
                      </div>

                      <div
                        className="pt-2 mb-2"
                        style={{
                          height: "378px",
                          overflow: "auto",
                        }}
                      >
                        {expression && (
                          <Box style={{ backgroundColor: "white" }}>
                            {expression}
                          </Box>
                        )}
                        {/* <Box className="addTag-box">HIKEMM1</Box> */}

                        <UpdateSoftTag
                          expression={expression}
                          setExpression={setExpression}
                          addtag={inputTag}
                          removetag={RemoveTag}
                          onRemoveTag={handleReverseRemoveTag}
                          onaddTag={handleReverseaddTag}
                          onClosePopup={handleReverseClosepopup}
                          Tag_Name_data={TagName}
                          Tag_Description_data={Tag_Description}
                          Device_ID={deviceID}
                          button_disable={addTagCount}
                          button_disable_reverse={handlebutton_disable_reverse}
                          SoftTagFormula={addConfiguration.soft_tag_formula}
                          Tag_ID={addConfiguration.soft_tag_id}
                          SelectedTags={selectedTags}
                          handleTagSelect={handleTagSelect}
                          setselectedTags={setselectedTags}
                          selectedTagFormula={selectedTagFormula}
                          selectSoftTag={selectSoftTag}
                          selectTagId={selectTagId}
                          selectTagName={selectTagName}
                          setSelectTagId={setSelectTagId}
                          addConfiguration={addConfiguration}
                          formulaData={formulaData}
                          soft_tag_real_formula={addConfiguration.soft_tag_real_formula}
                          tagConfData={tagConfData}
                          updateSoftTagId={updateSoftTagId}
                        />
                      </div>

                      {/* <button
                    className="submitbtn"
                    style={{float:"right"}}
                    // onClick={() => submitAssignDevice()}
                  >
                    Submit
                  </button> */}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {openCalModel && (
            <div className="select-cal-modal">
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={() => setOpenCalModel((prev) => !prev)}
              />
              {/* <ScientiFicCalculator expression={expression} setExpression={setExpression} /> */}
            </div>
          )}
          {/* =============== Import CSV Modal End =============== */}
          {/* =============== Tag configuration Modal Start =============== */}
          {/* =============== Alarm configuration Modal End =============== */}
          <TabContext value={value}>
            <div className="device-management tagWrap">
              <div className="device-management-tag">
                <select
                  className="device-dropdown"
                  value={deviceID}
                  onChange={(e) => {
                    setDeviceID(e.target.value);
                    // handleReverseClosepopup();
                  }}
                >
                  {deviceData.map((e) => {
                    return <option value={e.id}>{e.device_name}</option>;
                  })}
                </select>

                <Box>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      bgcolor: "#f2f2f2",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "inline-flex",
                      gap: "0px",
                      width: "fit-content",
                    }}
                  >
                    <Tab
                      label="Tag"
                      value="1"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#1C407B",
                          color: "#fff",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        },
                        "&:not(.Mui-selected)": {
                          backgroundColor: "#fff",
                          color: "#001323",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        },
                      }}
                    />
                    <Tab
                      label="Alarm"
                      value="2"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#1C407B",
                          color: "#fff",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        },
                        "&:not(.Mui-selected)": {
                          backgroundColor: "#fff",
                          color: "#001323",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        },
                      }}
                    />
                  </TabList>
                </Box>
              </div>

              <div className="device-managemnet-Btnwrap pt-3">
                {(accessManagementDevice?.is_viewer &&
                  accessManagementDevice?.is_editor) ||
                (!accessManagementDevice?.is_viewer &&
                  accessManagementDevice?.is_editor) ? (
                  <>
                    <div>
                      <button
                        className="softTag-btn"
                        onClick={handleSoftTagclick}
                      >
                        SoftTag+
                      </button>
                    </div>
                    <div
                      className="device-managemnegt btnwrap"
                      onClick={() => setLoackStatus(!lockStatus)}
                    >
                      <button
                        className={`device-managemnet ${
                          lockStatus ? "unlock-button" : "unlock-btn"
                        }`}
                      >
                        Unlock
                        {lockStatus ? (
                          <img
                            src={images.deviceManagementUnlockDark}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        ) : (
                          <img
                            src={images.deviceManagementUnlockWhite}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        )}
                      </button>
                    </div>
                    <div
                      className="device-managemnegt btnwrap"
                      onClick={() => setLoackStatus(!lockStatus)}
                    >
                      <button
                        className={`device-management ${
                          !lockStatus ? "lock-btn" : "lock-button"
                        }`}
                      >
                        Lock
                        {!lockStatus ? (
                          <img
                            src={images.deviceManagementLockDark}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        ) : (
                          <img
                            src={images.deviceManagementLockWhite}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        )}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <button className="softTag-btn" onClick={handleTostMsg}>
                        SoftTag+
                      </button>
                    </div>
                    <div
                      className="device-managemnegt btnwrap"
                      onClick={handleTostMsg}
                    >
                      <button
                        className={`device-managemnet ${
                          lockStatus ? "unlock-button" : "unlock-btn"
                        }`}
                      >
                        Unlock
                        {lockStatus ? (
                          <img
                            src={images.deviceManagementUnlockDark}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        ) : (
                          <img
                            src={images.deviceManagementUnlockWhite}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        )}
                      </button>
                    </div>
                    <div
                      className="device-managemnegt btnwrap"
                      onClick={handleTostMsg}
                    >
                      <button
                        className={`device-management ${
                          !lockStatus ? "lock-btn" : "lock-button"
                        }`}
                      >
                        Lock
                        {!lockStatus ? (
                          <img
                            src={images.deviceManagementLockDark}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        ) : (
                          <img
                            src={images.deviceManagementLockWhite}
                            alt=""
                            className="device-mangement unlockImg"
                          />
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* =============== TAG CONFIGURATION TABLE START =============== */}
            <TabPanel value="1" className="confi-tabs">
              <div className="tableResponsive d-flex confi-table-group">
                {/* =============== confi-table-40 Table start =============== */}
                <div className="confi-table-40">
                  <Table aria-label="" className="deviceManagementTable">
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell className="sticky-header">
                          <div className="">Edit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Name</div>
                        </TableCell>
                        <TableCell>
                          <div style={{ width: "300px" }}>Tag Comment</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-40 Table Body start =============== */}
                    <TableBody>
                      {!isShimmerConfigure ? (
                        tagConfData.length ? (
                          tagConfData.map((e, index) => {
                            return (
                              <TableRow className="deviceManagementTable-data">
                                <TableCell
                                  style={{ backgroundColor: "#fff" }}
                                  className="edit-column"
                                >
                                  {editIcone[e.id] ? (
                                    <CheckIcon
                                      className="checked-icon"
                                      style={{ width: "20px" }}
                                      onClick={() =>
                                        submitupdateTagConfiguration(e.id)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{ width: "20px" }}
                                      onClick={() => handleEditRow(e.id)}
                                    />
                                  )}
                                </TableCell>

                                <TableCell
                                  key={index}
                                  className={` Flex${
                                    hasDuplicates(tagConfData, e.tag_name)
                                      ? " bgred"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-tag_name configure-input-1 "
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_name ? e.tag_name : ""}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_name",
                                        event.target.value
                                      )
                                    }
                                  />
                                  {/* {index===0 ? (editIcone[e.id] ? (
                                  <CheckIcon
                                    className="checked-icon"
                                    style={{ width: "20px" }}
                                    onClick={() =>
                                      submitupdateTagConfiguration(e.id)
                                    }
                                  />
                                 
                                  
                                ) : ( */}
                                  {e.is_soft_tag ? (
                                    <button
                                      className="Fx-btn"
                                      onClick={() => {
                                        handleupdatesofttag(e);
                                      }}
                                      alt="Fx Button"
                                    >
                                      Fx
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {/* )):null} */}
                                </TableCell>

                                <TableCell
                                  className={
                                    e.tag_comment
                                      ? e.tag_comment.length <= 24
                                        ? ""
                                        : "bgred"
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    className="configure-tag_name configure-input-1"
                                    value={e.tag_comment}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_comment",
                                        event.target.value
                                      )
                                    }
                                    disabled={!editIcone[e.id]}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        )
                      ) : (
                        <>
                          <ConfigureShimmer itemCount={9} />
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-40 Table Body end =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-40 Table end =============== */}
                {/* =============== confi-table-60 Table start =============== */}
                <div className="confi-table-60 tab">
                  <Table
                    aria-label=""
                    className="deviceManagementTable deviceManagementTable1"
                  >
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell>
                          <div>Tag Unit</div>
                        </TableCell>
                        <TableCell>
                          <div>Aggregate</div>
                        </TableCell>
                        <TableCell>
                          <div style={{width:"200px"}}>Tag Group</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Read Write Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Signal Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Low Range</div>
                        </TableCell>
                        <TableCell>
                          <div>High Range</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert</div>
                        </TableCell>
                        <TableCell>
                          <div>Alarm Email</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert Priority</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage1</div>
                        </TableCell>
                        <TableCell>
                          <div style={{width:"300px"}}>Alertmessage0</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HH</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HI</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LO</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LL</div>
                        </TableCell>
                        <TableCell>
                          <div>HYS</div>
                        </TableCell>
                        <TableCell>
                          <div>ALMSUP</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-60 Table Body start =============== */}
                    <TableBody>
                      {!isShimmerConfigure ? (
                        tagConfData.length ? (
                          tagConfData.map((e) => {
                            return (
                              <>
                                <TableRow className="deviceManagementTable-data">
                                  <TableCell
                                    className={
                                      e.tag_unit
                                          ? e.tag_unit.length <= 10
                                            ? ""
                                            : "bgred"
                                          : "bgred"
                                    }
                                    // className={
                                    //   !["DI", "DO"].includes(e.signal_type)
                                    //     ? e.tag_unit
                                    //       ? e.tag_unit.length <= 10
                                    //         ? ""
                                    //         : "bgred"
                                    //       : "bgred"
                                    //     : ""
                                    // }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.tag_unit ? e.tag_unit : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_unit",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["true", "false"].includes(String(e.aggregation)) // Convert boolean to string
                                        ? ""
                                        : "bgred"
                                    }>
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.aggregation }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "aggregation",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      !["DI", "DO"].includes(e.signal_type)
                                        ? e.tag_group
                                          ? e.tag_group.length <= 10
                                            ? ""
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.tag_group ? e.tag_group : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_group",
                                          event.target.value
                                        )
                                      }
                                    />
                                    {e.tag_group ? "" : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["RD", "RW"].includes(
                                        e.tag_read_write_type
                                      )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.tag_read_write_type
                                          ? e.tag_read_write_type
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_read_write_type",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO", "DI", "DO"].includes(
                                        e.signal_type
                                      )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.signal_type ? e.signal_type : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "signal_type",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO"].includes(e.signal_type)
                                        ? e.low_range
                                          ? Number(e.low_range) <
                                            Number(e.high_range)
                                            ? ""
                                            : "bgred"
                                          : "bgred"
                                        : e.low_range ? (e.low_range.length <= 8 && !(/[,@!&)(}{#]/).test(e.low_range) ? "" : "bgred" ) : ""

                                    }
                                  >
                                    {/* <TableCell className={e.low_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred"}> */}
                                    <input
                                      type={["AI", "AO"].includes(e.signal_type) ? "number" : "text"}
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.low_range ? e.low_range : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "low_range",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO"].includes(e.signal_type)
                                        ? e.high_range
                                          ? Number(e.low_range) <
                                            Number(e.high_range)
                                            ? ""
                                            : "bgred"
                                          : "bgred"
                                        : e.high_range ? (e.high_range.length <= 8 && !(/[,@!&)(}{#]/).test(e.high_range) ? "" : "bgred") : ""
                                    }
                                  >
                                    <input
                                      type={["AI", "AO"].includes(e.signal_type) ? "number" : "text"}
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.high_range ? e.high_range : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "high_range",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert &&
                                      ["yes", "no"].includes(
                                        e.alert?.toLowerCase()
                                      )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.alert ? e.alert : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["yes", "no"].includes(
                                              e.alarm_mail?.toLowerCase()
                                            )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.alarm_mail ? e.alarm_mail : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alarm_mail",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>

                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["high", "medium", "low"].includes(
                                              e.alert_priority?.toLowerCase()
                                            )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_priority ? e.alert_priority : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_priority",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>

                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? e.alert_message_1
                                            ? e.alert_message_1.length <= 24
                                              ? ""
                                              : "bgred"
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input  configure-input-msg"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_message_1
                                          ? e.alert_message_1
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_message_1",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? e.alert_message_0
                                            ? e.alert_message_0.length <= 24
                                              ? ""
                                              : "bgred"
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_message_0
                                          ? e.alert_message_0
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_message_0",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  {/* <TableCell
                                    className={
                                      e.alert
                                        ? e.alert != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.setpoint_hh
                                                ? Number(e.high_range) >=
                                                    Number(e.setpoint_hh) &&
                                                  Number(e.setpoint_hh) >=
                                                    Number(e.setpoint_hi) &&
                                                  Number(e.setpoint_hi) >
                                                    Number(e.setpoint_lo) &&
                                                  Number(e.setpoint_lo) >=
                                                    Number(e.setpoint_ll) &&
                                                  Number(e.setpoint_ll) >=
                                                    Number(e.low_range)
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_hh ? e.setpoint_hh : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_hh",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell> */}
                                        <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.setpoint_hh
                                                ? Number(e.high_range) >=
                                                    Number(e.setpoint_hh) &&
                                                  Number(e.setpoint_hh) >=
                                                    Number(e.setpoint_hi) &&
                                                  Number(e.setpoint_hi) >
                                                    Number(e.setpoint_lo) &&
                                                  Number(e.setpoint_lo) >=
                                                    Number(e.setpoint_ll) &&
                                                  Number(e.setpoint_ll) >=
                                                    Number(e.low_range)
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_hh ? e.setpoint_hh : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_hh",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.setpoint_hi
                                                ? Number(e.high_range) >=
                                                    Number(e.setpoint_hh) &&
                                                  Number(e.setpoint_hh) >=
                                                    Number(e.setpoint_hi) &&
                                                  Number(e.setpoint_hi) >
                                                    Number(e.setpoint_lo) &&
                                                  Number(e.setpoint_lo) >=
                                                    Number(e.setpoint_ll) &&
                                                  Number(e.setpoint_ll) >=
                                                    Number(e.low_range)
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_hi ? e.setpoint_hi : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_hi",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.setpoint_lo
                                                ? Number(e.high_range) >=
                                                    Number(e.setpoint_hh) &&
                                                  Number(e.setpoint_hh) >=
                                                    Number(e.setpoint_hi) &&
                                                  Number(e.setpoint_hi) >
                                                    Number(e.setpoint_lo) &&
                                                  Number(e.setpoint_lo) >=
                                                    Number(e.setpoint_ll) &&
                                                  Number(e.setpoint_ll) >=
                                                    Number(e.low_range)
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_lo ? e.setpoint_lo : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_lo",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.setpoint_ll
                                                ? Number(e.high_range) >=
                                                    Number(e.setpoint_hh) &&
                                                  Number(e.setpoint_hh) >=
                                                    Number(e.setpoint_hi) &&
                                                  Number(e.setpoint_hi) >
                                                    Number(e.setpoint_lo) &&
                                                  Number(e.setpoint_lo) >=
                                                    Number(e.setpoint_ll) &&
                                                  Number(e.setpoint_ll) >=
                                                    Number(e.low_range)
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_ll ? e.setpoint_ll : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_ll",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                              e.signal_type
                                            )
                                            // ? e.signal_type == "AI"
                                              ? e.hys
                                                ? 1 <= Number(e.hys) &&
                                                  Number(e.hys) <= 10
                                                  ? ""
                                                  : "bgred"
                                                : "bgred"
                                              // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.hys ? e.hys : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "hys",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["true", "false"].includes(
                                              e.almsup?.toLowerCase()
                                            )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.almsup ? e.almsup : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "almsup",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>

                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        )
                      ) : (
                        <>
                          <ConfigureScrollerShimmer itemCount={9} />
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-60 Table Body start =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-60 Table end =============== */}
              </div>
            </TabPanel>
            {/* =============== TAG CONFIGURATION TABLE END =============== */}

            {/* =============== ALARM CONFIGURATION TABLE START =============== */}
            <TabPanel value="2" className="confi-tabs">
              <div
                className="tableResponsive d-flex confi-table-group"
                ref={containerRef}
              >
                {/* =============== confi-table-40 Table start =============== */}
                <div className="confi-table-40">
                  <Table
                    aria-label=""
                    className="deviceManagementTable deviceManagementTable1"
                  >
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell className="sticky-header">
                          <div className="">Edit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Name</div>
                        </TableCell>
                        <TableCell>
                          <div style={{ width: "300px" }}>Tag Comment</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-40 Table Body start =============== */}
                    <TableBody>
                      {!isShimmerConfigure ? (
                        tagConfData.length ? (
                          tagConfData.map((e, index) => {
                            return (
                              <TableRow className="deviceManagementTable-data">
                                <TableCell
                                  style={{ backgroundColor: "#fff" }}
                                  className="edit-column"
                                >
                                  {editIcone[e.id] ? (
                                    <CheckIcon
                                      className="checked-icon"
                                      style={{ width: "20px" }}
                                      onClick={() =>
                                        submitupdateTagConfiguration(e.id)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{ width: "20px" }}
                                      onClick={() => handleEditRow(e.id)}
                                    />
                                  )}
                                </TableCell>

                                <TableCell
                                  className={` Tag_Name_container Flex${
                                    e.tag_name && e.tag_name.length <= 18
                                      ? ""
                                      : "bgred"
                                  }`}
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-tag_name configure-input-1 "
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_name ? e.tag_name : ""}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_name",
                                        event.target.value
                                      )
                                    }
                                  />
                                  {/* {index===0 ? (editIcone[e.id] ? (
                                  <CheckIcon
                                    className="checked-icon"
                                    style={{ width: "20px" }}
                                    onClick={() =>
                                      submitupdateTagConfiguration(e.id)
                                    }
                                  />
                                 
                                  
                                ) : ( */}
                                  {e.is_soft_tag ? (
                                    <button
                                      className="Fx-btn"
                                      onClick={() => {
                                        handleupdatesofttag(e);
                                      }}
                                      alt="Fx Button"
                                    >
                                      Fx
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {/* )):null} */}
                                </TableCell>

                                <TableCell
                                  className={
                                    e.tag_comment
                                      ? e.tag_comment.length <= 24
                                        ? ""
                                        : "bgred"
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    className="configure-tag_name configure-input-1"
                                    value={e.tag_comment}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_comment",
                                        event.target.value
                                      )
                                    }
                                    disabled={!editIcone[e.id]}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        )
                      ) : (
                        <>
                          <ConfigureShimmer itemCount={9} />
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-40 Table Body end =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-40 Table end =============== */}
                {/* =============== confi-table-60 Table start =============== */}
                <div className="confi-table-60 tab">
                  <Table
                    aria-label=""
                    className="deviceManagementTable deviceManagementTable1"
                  >
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell>
                          <div>Tag Unit</div>
                        </TableCell>
                        <TableCell>
                          <div>Aggregate</div>
                        </TableCell>
                        <TableCell>
                          <div style={{width:"200px"}}>Tag Group</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Read Write Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Signal Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Low Range</div>
                        </TableCell>
                        <TableCell>
                          <div>High Range</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert</div>
                        </TableCell>
                        <TableCell>
                          <div>Alarm Email</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert Priority</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage1</div>
                        </TableCell>
                        <TableCell>
                          <div style={{width:"300px"}}>Alertmessage0</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HH</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HI</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LO</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LL</div>
                        </TableCell>
                        <TableCell>
                          <div>HYS</div>
                        </TableCell>
                        <TableCell>
                          <div>ALMSUP</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-60 Table Body start =============== */}
                    <TableBody>
                      {!isShimmerConfigure ? (
                        tagConfData.length ? (
                          tagConfData.map((e) => {
                            return (
                              <>
                                <TableRow className="deviceManagementTable-data">
                                  <TableCell
                                    className={
                                      e.tag_unit
                                        ? e.tag_unit.length <= 10
                                          ? ""
                                          : "bgred"
                                        : "bgred"
                                    }
                                  // className={
                                  //   !["DI", "DO"].includes(e.signal_type)
                                  //     ? e.tag_unit
                                  //       ? e.tag_unit.length <= 10
                                  //         ? ""
                                  //         : "bgred"
                                  //       : "bgred"
                                  //     : ""
                                  // }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.tag_unit ? e.tag_unit : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_unit",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["true", "false"].includes(String(e.aggregation)) // Convert boolean to string
                                        ? ""
                                        : "bgred"
                                    }>
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.aggregation }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "aggregation",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      !["DI", "DO"].includes(e.signal_type)
                                        ? e.tag_group
                                          ? e.tag_group.length <= 10
                                            ? ""
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg-unit"
                                      disabled={!editIcone[e.id]}
                                      value={e.tag_group ? e.tag_group : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_group",
                                          event.target.value
                                        )
                                      }
                                    />
                                    {e.tag_group ? "" : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["RD", "RW"].includes(
                                        e.tag_read_write_type
                                      )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.tag_read_write_type
                                          ? e.tag_read_write_type
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "tag_read_write_type",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO", "DI", "DO"].includes(
                                        e.signal_type
                                      )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.signal_type ? e.signal_type : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "signal_type",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO"].includes(e.signal_type)
                                        ? e.low_range
                                          ? Number(e.low_range) <
                                            Number(e.high_range)
                                            ? ""
                                            : "bgred"
                                          : "bgred"
                                        : e.low_range ? (e.low_range.length <= 8 && !(/[,.@!&)(}{#]/).test(e.low_range) ? "" : "bgred") : ""

                                    }
                                  >
                                    {/* <TableCell className={e.low_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred"}> */}
                                    <input
                                      type={["AI", "AO"].includes(e.signal_type) ? "number" : "text"}
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.low_range ? e.low_range : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "low_range",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      ["AI", "AO"].includes(e.signal_type)
                                        ? e.high_range
                                          ? Number(e.low_range) <
                                            Number(e.high_range)
                                            ? ""
                                            : "bgred"
                                          : "bgred"
                                        : e.high_range ? (e.high_range.length <= 8 && !(/[,.@!&)(}{#]/).test(e.high_range) ? "" : "bgred") : ""
                                    }
                                  >
                                    <input
                                      type={["AI", "AO"].includes(e.signal_type) ? "number" : "text"}
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.high_range ? e.high_range : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "high_range",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert &&
                                        ["yes", "no"].includes(
                                          e.alert?.toLowerCase()
                                        )
                                        ? ""
                                        : "bgred"
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.alert ? e.alert : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["yes", "no"].includes(
                                            e.alarm_mail?.toLowerCase()
                                          )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.alarm_mail ? e.alarm_mail : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alarm_mail",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>

                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["high", "medium", "low"].includes(
                                            e.alert_priority?.toLowerCase()
                                          )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_priority ? e.alert_priority : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_priority",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>

                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? e.alert_message_1
                                            ? e.alert_message_1.length <= 24
                                              ? ""
                                              : "bgred"
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input  configure-input-msg"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_message_1
                                          ? e.alert_message_1
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_message_1",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? e.alert_message_0
                                            ? e.alert_message_0.length <= 24
                                              ? ""
                                              : "bgred"
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input configure-input-msg"
                                      disabled={!editIcone[e.id]}
                                      value={
                                        e.alert_message_0
                                          ? e.alert_message_0
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "alert_message_0",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert != "no"
                                          ? !["DI", "DO"].includes(
                                            e.signal_type
                                          )
                                            // ? e.signal_type == "AI"
                                            ? e.setpoint_hh
                                              ? Number(e.high_range) >=
                                                Number(e.setpoint_hh) &&
                                                Number(e.setpoint_hh) >=
                                                Number(e.setpoint_hi) &&
                                                Number(e.setpoint_hi) >
                                                Number(e.setpoint_lo) &&
                                                Number(e.setpoint_lo) >=
                                                Number(e.setpoint_ll) &&
                                                Number(e.setpoint_ll) >=
                                                Number(e.low_range)
                                                ? ""
                                                : "bgred"
                                              : "bgred"
                                            // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_hh ? e.setpoint_hh : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_hh",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                            e.signal_type
                                          )
                                            // ? e.signal_type == "AI"
                                            ? e.setpoint_hi
                                              ? Number(e.high_range) >=
                                                Number(e.setpoint_hh) &&
                                                Number(e.setpoint_hh) >=
                                                Number(e.setpoint_hi) &&
                                                Number(e.setpoint_hi) >
                                                Number(e.setpoint_lo) &&
                                                Number(e.setpoint_lo) >=
                                                Number(e.setpoint_ll) &&
                                                Number(e.setpoint_ll) >=
                                                Number(e.low_range)
                                                ? ""
                                                : "bgred"
                                              : "bgred"
                                            // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_hi ? e.setpoint_hi : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_hi",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                            e.signal_type
                                          )
                                            // ? e.signal_type == "AI"
                                            ? e.setpoint_lo
                                              ? Number(e.high_range) >=
                                                Number(e.setpoint_hh) &&
                                                Number(e.setpoint_hh) >=
                                                Number(e.setpoint_hi) &&
                                                Number(e.setpoint_hi) >
                                                Number(e.setpoint_lo) &&
                                                Number(e.setpoint_lo) >=
                                                Number(e.setpoint_ll) &&
                                                Number(e.setpoint_ll) >=
                                                Number(e.low_range)
                                                ? ""
                                                : "bgred"
                                              : "bgred"
                                            // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_lo ? e.setpoint_lo : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_lo",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                            e.signal_type
                                          )
                                            // ? e.signal_type == "AI"
                                            ? e.setpoint_ll
                                              ? Number(e.high_range) >=
                                                Number(e.setpoint_hh) &&
                                                Number(e.setpoint_hh) >=
                                                Number(e.setpoint_hi) &&
                                                Number(e.setpoint_hi) >
                                                Number(e.setpoint_lo) &&
                                                Number(e.setpoint_lo) >=
                                                Number(e.setpoint_ll) &&
                                                Number(e.setpoint_ll) >=
                                                Number(e.low_range)
                                                ? ""
                                                : "bgred"
                                              : "bgred"
                                            // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.setpoint_ll ? e.setpoint_ll : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "setpoint_ll",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? !["DI", "DO"].includes(
                                            e.signal_type
                                          )
                                            // ? e.signal_type == "AI"
                                            ? e.hys
                                              ? 1 <= Number(e.hys) &&
                                                Number(e.hys) <= 10
                                                ? ""
                                                : "bgred"
                                              : "bgred"
                                            // : "bgred"
                                            : ""
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="number"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.hys ? e.hys : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "hys",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={
                                      e.alert
                                        ? e.alert?.toLowerCase() != "no"
                                          ? ["true", "false"].includes(
                                            e.almsup?.toLowerCase()
                                          )
                                            ? ""
                                            : "bgred"
                                          : ""
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      id="fname"
                                      className="configure-input"
                                      disabled={!editIcone[e.id]}
                                      value={e.almsup ? e.almsup : ""}
                                      onChange={(event) =>
                                        handleInputChange(
                                          e.id,
                                          "almsup",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>

                            <TableRow className="deviceManagementTable-data">
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        )
                      ) : (
                        <>
                          <ConfigureScrollerShimmer itemCount={9} />
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-60 Table Body start =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-60 Table end =============== */}
              </div>
            </TabPanel>
            {/* =============== ALARM CONFIGURATION TABLE END =============== */}
          </TabContext>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default Configure;
