import React from "react";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import TableListData from "./TableList/TableListData";
import MultiAxiosLineChart from "../../../../../../components/Graph/MultiAxios/MultiAxiosLineChart";
import { images } from "../../../../../../config";


const ReportsGraphComponent = ({
    tableData,
    selectedMachine,
    selectTagID1,
    selectTagID2,
    setSelectTag1,
    setSelectTag2,
    LineChartData1,
    LineChartData2,
    isShimmerLineChartReports,
    selectAggReport,
    selectIntervalReport,
    hasRequiredUnits,
    selectTag

}) => {

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={9}
            className="consumption-table-box"
        >
            <Box className="consumerData-box" style={{ padding: "0px" }}>
                <div style={{ minHeight: "400px" }}>
                    
                        <MultiAxiosLineChart
                            LineChartData1={LineChartData1}
                            LineChartData2={LineChartData2}
                            selectedMachine={selectedMachine}
                            shimmer ={isShimmerLineChartReports}
                            selectAggReport={selectAggReport} // aggreagate
                            selectIntervalReport={selectIntervalReport} // interval
                            hasRequiredUnits={hasRequiredUnits}
                            selectFirstUnit={selectTagID1}
                            selectSecondUnit={selectTagID2}
                            selectTag={selectTag}
                        />
                   
                </div>
                <div className="d-flex justify-content-between m-3">
                    <select
                        className="form-input-class"
                        style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                        }}
                        id="selectedClass"
                        value={selectTagID1}
                        onChange={(e) => setSelectTag1(e.target.value)}
                        required
                    >
                        <option value="none" selected disabled hidden>
                            Select tag
                        </option>
                        {selectedMachine ? selectedMachine.length ? selectedMachine.map((row) => <option value={row.tagId}>{row.tagName}</option>) : [] : []}
                    </select>

                    <select
                        className="form-input-class"
                        style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                        }}
                        id="selectedClass"
                        value={selectTagID2}
                        onChange={(e) => setSelectTag2(e.target.value)}
                        required
                    >
                        <option value="none" selected disabled hidden>
                            Select tag
                        </option>
                        {selectedMachine ? selectedMachine.length ? selectedMachine.map((row) => <option value={row.tagId}>{row.tagName}</option>) : [] : []}
                    </select>
                </div>
    
                <TableListData
                    tableData={tableData}
                />
                
            </Box>
        </Grid>
    )
}

export default ReportsGraphComponent;