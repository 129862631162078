import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const CombinedChart = ({ barChartData, lineChartData, colorCode }) => {

  // Merge barChartData and lineChartData by plant_id
  const combinedData = barChartData.map((barItem, index) => {
    const lineItem =
      lineChartData.find((item) => item.plant_id === barItem.plant_id) || {};
    return {
      plant_id: barItem.plant_id,
      plant_name: barItem.plant_name,
      plant_capacity: barItem.plant_capacity || 0, // Default to 0 if missing
      generation_value: lineItem.generation_value || 0, // Default to 0 if missing
      color: colorCode[index],
    };
  });


  // Handle missing line chart data if there are extra items in lineChartData
  lineChartData.forEach((lineItem) => {
    if (
      !barChartData.some((barItem) => barItem.plant_id === lineItem.plant_id)
    ) {
      combinedData.push({
        plant_id: lineItem.plant_id,
        plant_name: lineItem.plant_name,
        plant_capacity: 0, // Default to 0 for missing bar chart data
        generation_value: lineItem.generation_value,
      });
    }
  });

  // Calculate average values for the combined dataset
  const averageData = combinedData.map((item) => {
    return (item.plant_capacity + item.generation_value) / 2;
  });

  // Dynamically calculate the minimum values for each y-axis
  const minMW = Math.min(0, ...combinedData.map((item) => item.plant_capacity));
  const minBU = Math.min(
    0,
    ...combinedData.map((item) => item.generation_value)
  );

  const options = {
    chart: {
      type: "column",
      height: 300,
    },
    scrollbar: {
      enabled: true,
    },
    title: {
      text: null, // No title
    },
    xAxis: {
      categories: combinedData.map((item) => item.plant_name), // Use plant_name as categories
     
    },
    yAxis: [
      {
        title: { text: "MW" },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: false,
      },
      {
        title: { text: "MWh" },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const averageValue = averageData[this.points[0].point.index];
        return `
              <b>${this.x}</b><br/>
              <span style="color:${
                this.points[0].color
              }">\u25CF</span> MW: <b>${this.points[0].y}</b><br/>
              <span style="color:${
                this.points[1].color
              }">\u25CF</span> MW: <b>${this.points[1].y}</b><br/>
              <span style="color:#4CAF50">\u25CF</span> Average: <b>${averageValue.toFixed(
                2
              )}</b>`;
      },
    },
    credits: {
      enabled: false, // Remove the Highcharts logo
    },
    legend: {
      enabled: false, // Remove the legend
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 5,
        groupPadding: 0.1,
        pointPadding: 0.1,
      },
      line: {
        lineWidth: 1,
        marker: {
          enabled: true,
          radius: 4,
          symbol: "circle",
          fillColor: "#FFFFFF",
          lineWidth: 1,
          lineColor: "black",
        },
      },
    },
    series: [
      {
        name: "MW",
        type: "column",
        data: combinedData.map((item) => item.plant_capacity),
        colorByPoint: true,
        colors: combinedData.map((e) => e.color),
        yAxis: 0,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: "MW",
        type: "line",
        data: combinedData.map((item) => item.generation_value), // Use generation_value for MW data
        color: "#45748b",
        yAxis: 1,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: "Average",
        type: "line",
        data: averageData.map((value) => value), // Average of bar and line data
        color: "#4CAF50",
        dashStyle: "Dash",
        marker: {
          enabled: false,
        },
        enableMouseTracking: true,
        yAxis: 0, // Align with the MW axis
      },
    ],
  };

  return (
    <>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};

export default CombinedChart;
