import { request } from "../../../api/api";

export const getDeviceOverview = (params) => {
  return request.post(`/dashboard/GetDeviceForOverview/`, params);
};

export const getDeviceUnit = (params) => {
  return request.post(`/dashboard/GetTagForOverview/`, params);
};

export const createOverViewList = (params) => {
  return request.post(`/dashboard/createOverview/`, params);
};

export const getOverViewConfuguration = (params) => {
  return request.post(`/dashboard/GetOverviewConfiguration/`, params);
};

export const getOverViewAnalyticsData = (params) => {
  return request.post(`/dashboard/getOverviewAnalyticsData/`, params);
};

export const getOverViewAnalyticsDataReceipe = (params) => {
  return request.post(`/dashboard/getOverviewAnalyticsDataWithRecipe/`, params);
};

export const getOverviewDeviceData = (params) => {
  return request.post(`/dashboard/getOverviewDevice/`, params);
};
export const getOverViewPieChartData = (params) => {
  return request.post(`/dashboard/getOverviewAnalyticsPieChartData/`, params);
};

export const getOverviewAnalyticsEnergyChartData = (params) => {
  return request.post(
    `/dashboard/getOverviewAnalyticsEnergyChartData/`,
    params
  );
};

export const getOverviewReportData = (params) => {
  return request.post(`/dashboard/getOverviewReportData/`, params);
};

export const getOverviewReportDeviceData = (params) => {
  return request.post(`/dashboard/getOverviewReportDevice/`, params);
};

export const getOverviewReportGraphData = (params) => {
  return request.post(`/dashboard/getOverviewReportGraphData/`, params);
};

export const exportAnalyticsCOCExcel = (params) => {
  return request.post(`/dashboard/exportAnalyticsCOCExcel/`, params);
};

export const getExportAnalyticsReportExcel =(params)=>{
  return request.post("/dashboard/exportAnalyticsReportExcel/",params)
}
export const getExportPOPExcel =(params)=>{
 return request.post("/dashboard/exportAnalyticsPOPExcel/",params)
}

export const getUnitData =(params) =>{
  return request.post(`/dashboard/getHikEMMDeviceTagUnits/`,params)
}

export const getDeviceUnitData = (params)=>{
  return request.post(`/dashboard/getDeviceTagUnits/`,params)
}

export const getHikEMMReportDeviceTagUnits = (params) =>{
  return request.post(`/dashboard/getHikEMMReportDeviceTagUnits/`,params)
}


export const getDeviceReceipe = (params)=>{
  return request.post(`/dashboard/getDeviceRecipeName/`,params)
}
export const customeReportData = (params) =>{
  return request.post(`/dashboard/exportHikEMMCustomReport/`,params)
}