import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import ShimmerEffectLineChart from "../../ShimmerLineChart/ShimmerLinechart";

HC_more(Highcharts);
exporting(Highcharts);

const MultiAxiosLineChart = ({
  LineChartData1,
  LineChartData2,
  selectedMachine,
  shimmer,
  selectAggReport,
  selectIntervalReport,
  hasRequiredUnits,
  selectFirstUnit,
  selectSecondUnit,
  selectTag,
}) => {
  const [xAxisMin, setXAxisMin] = useState(0);
  const [xAxisMax, setXAxisMax] = useState(0);

  const chartRef = useRef(null);

  console.log("selectTag", selectTag);

  const matchTagUnit1 = selectTag.find(
    (f) => f.tagId === parseInt(selectFirstUnit)
  );
  const matchTagUnit2 = selectTag.find(
    (f) => f.tagId === parseInt(selectSecondUnit)
  );

  const tagUnit1 = matchTagUnit1 ? matchTagUnit1.tagUnit : null;
  const tagUnit2 = matchTagUnit2 ? matchTagUnit2.tagUnit : null;

  const getInterval = (unit, aggReport, intervalReport) => {
    if (unit && typeof unit === "string") {
      const lowerUnit = unit.toLowerCase();
      return lowerUnit.includes("agg") ||
        lowerUnit.includes("kwh") ||
        lowerUnit.includes("kvah") ||
        lowerUnit.includes("kvarh")
        ? aggReport
        : intervalReport;
    }

    return intervalReport;
  };

  const interval1 = getInterval(
    tagUnit1,
    selectAggReport,
    selectIntervalReport
  );
  const interval2 = getInterval(
    tagUnit2,
    selectAggReport,
    selectIntervalReport
  );

  const isFirstLineLonger =
    (LineChartData1[0]?.data?.length || 0) >=
    (LineChartData2[0]?.data?.length || 0);

  const categories = isFirstLineLonger
    ? LineChartData1[0]?.data.map((row) => row.MIN_KEY)
    : LineChartData2[0]?.data.map((row) => row.MIN_KEY);

  const calculateDataPoints = (
    data,
    interval,
    baseInterval,
    categoriesLength
  ) => {
    if (!data || data.length === 0) return [];
    const scalingFactor = interval / baseInterval;
    const scaledData = [];

    for (let i = 0; i < data.length; i++) {
      const scaledIndex = Math.round(i * scalingFactor);
      if (scaledIndex < categoriesLength) {
        scaledData.push({
          x: scaledIndex,
          y: parseFloat(data[i].tag_value),
        });
      }
    }

    return scaledData;
  };
  const baseInterval = Math.min(interval1, interval2);

  const firstSeriesData = calculateDataPoints(
    LineChartData1[0]?.data,
    interval1,
    baseInterval,
    categories?.length
  );
  const secondSeriesData = calculateDataPoints(
    LineChartData2[0]?.data,
    interval2,
    baseInterval,
    categories?.length
  );

  const findUnitColor1 = LineChartData1
    ? LineChartData1.length
      ? selectedMachine.find((f) => f.tagId == LineChartData1[0]["tag_id"])
      : ""
    : "";
  const findUnitColor2 = LineChartData2
    ? LineChartData2.length
      ? selectedMachine.find((f) => f.tagId == LineChartData2[0]["tag_id"])
      : ""
    : "";

  const options = {
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: categories?.length - 1,
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      scrollbar: {
        enabled: true,
      },
    },
    series: [
      {
        name: LineChartData1
          ? LineChartData1.length
            ? LineChartData1[0]["tag_name"]
            : ""
          : "",
        data:
          Number(interval1) === 1 || Number(interval2) === 1
            ? LineChartData1.length && LineChartData1[0]?.data
              ? LineChartData1[0].data.map((row) => parseFloat(row.tag_value))
              : []
            : firstSeriesData,
        color: findUnitColor1 ? findUnitColor1.color : "",
        yAxis: 0,
        pointStart: 0,
        ...(Number(interval1) === 1 || Number(interval2) === 1
          ? { pointInterval: Number(interval1) }
          : {}),
      },
      {
        name: LineChartData2
          ? LineChartData2.length
            ? LineChartData2[0]["tag_name"]
            : ""
          : "",
        data:
          Number(interval1) === 1 || Number(interval2) === 1
            ? LineChartData2.length && LineChartData2[0]?.data
              ? LineChartData2[0].data.map((row) => parseFloat(row.tag_value))
              : []
            : secondSeriesData,
        color: findUnitColor2 ? findUnitColor2.color : "",
        yAxis: 1,
        pointStart: 0,
        ...(Number(interval1) === 1 || Number(interval2) === 1
          ? { pointInterval: Number(interval2) }
          : {}),
      },
    ],

    yAxis: [
      {
        title: {
          text: LineChartData1
            ? LineChartData1.length
              ? LineChartData1[0]["tag_name"]
              : ""
            : "",
        },
      },
      {
        title: {
          text: LineChartData2
            ? LineChartData2.length
              ? LineChartData2[0]["tag_name"]
              : ""
            : "",
        },
        opposite: true, // Display the second y-axis on the opposite side
      },
    ],
    // colors: unitColorsReportRedux.length ? unitColorsReportRedux.map((e) => e.color) : [],
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = `${this.x}<br/>`;
        this.points.forEach((point) => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });
        return s;
      },
    },
  };
  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      const visibleCategories = categories.slice(-5);
      chart.xAxis[0].setExtremes(
        categories.length - visibleCategories.length,
        categories.length - 1
      );
      chart.redraw();
    }
  }, [categories]);

  return (
    <>
      {!shimmer ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <ShimmerEffectLineChart />
      )}
    </>
  );
};

export default MultiAxiosLineChart;
