import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { images } from '../../../config';
import { useParams } from 'react-router-dom';
import { ShimmerText } from "react-shimmer-effects";
import ShimmerEffectLineChart from '../../../components/ShimmerLineChart/ShimmerLinechart';


HC_more(Highcharts);
exporting(Highcharts);

export function MultiAxisLineChart({ fisrtLineChartReportData, secondeLineChartReportData, selectFirstUnit, selectSecondUnit, selectFirst, selectSecond, Shimmer, selectAggReport, selectIntervalReport,unitColorsReportRedux,unitReport }) {


    const getInterval = (unit, aggReport, intervalReport) => {
        if (unit && typeof unit === "string") {
            const selectedUnit = unitReport?.find((u) => u.tag_unit.toLowerCase() === unit.toLowerCase());
            return selectedUnit && selectedUnit.aggregation ? aggReport : intervalReport;
        }
        return intervalReport;
    };
    
      
      
    const interval1 = getInterval(selectFirstUnit, selectAggReport, selectIntervalReport);
    const interval2 = getInterval(selectSecondUnit, selectAggReport, selectIntervalReport);


    const isFirstLineLonger =
        (fisrtLineChartReportData[0]?.data?.length || 0) >= (secondeLineChartReportData[0]?.data?.length || 0);

    const categories = isFirstLineLonger
        ? fisrtLineChartReportData[0]?.data.map((row) => row.MIN_KEY)
        : secondeLineChartReportData[0]?.data.map((row) => row.MIN_KEY);

    const param = useParams();
   
    const calculateDataPoints = (data, interval, baseInterval, categoriesLength) => {
        if (!data || data.length === 0) return [];
        const scalingFactor = interval / baseInterval;
        const scaledData = [];

        for (let i = 0; i < data.length; i++) {
            const scaledIndex = Math.round(i * scalingFactor);
            if (scaledIndex < categoriesLength) {
                scaledData.push({
                    x: scaledIndex,
                    y: parseFloat(data[i].tag_value),
                });
            }
        }

        return scaledData;
    };
    const baseInterval = Math.min(interval1, interval2);

    const firstSeriesData = calculateDataPoints(fisrtLineChartReportData[0]?.data, interval1, baseInterval, categories?.length);
    const secondSeriesData = calculateDataPoints(secondeLineChartReportData[0]?.data, interval2, baseInterval, categories?.length);

    const findUnitColor1 = unitColorsReportRedux.find((f) => f.tagUnit == selectFirstUnit && f.plantId == param.id);
    const findUnitColor2 = unitColorsReportRedux.find((f) => f.tagUnit == selectSecondUnit && f.plantId == param.id);

    const options = {
        chart: {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories?.length - 1, // Set scroll position to the rightmost end
            },
        },
        title: {
            text: null
        },
        legend: {
            enabled: false // Disable legend
        },
        xAxis: {
            categories: categories,
            scrollbar: {
                enabled: true,
            },
        },
        series: [
            {
                name: selectFirst,
                data: Number(interval1) === 1 || Number(interval2) === 1
                    ? fisrtLineChartReportData.length
                        ? fisrtLineChartReportData[0]?.data.map((row) => parseFloat(row.tag_value))
                        : []
                    : firstSeriesData,
                // data: fisrtLineChartReportData.length
                //     ? fisrtLineChartReportData[0]?.data.map((row) => parseFloat(row.tag_value))
                //     : [],
                color: findUnitColor1 ? findUnitColor1.color : "",
                yAxis: 0, // Set the series to use the first y-axis
                pointStart: 0,
                ...(Number(interval1) === 1 || Number(interval2) === 1 ? { pointInterval: Number(interval1) } : {}), // Add pointInterval only if interval1 is 1
            },
            {
                name: selectSecond,
                data: Number(interval1) === 1 || Number(interval2) === 1
                    ? secondeLineChartReportData.length
                        ? secondeLineChartReportData[0]?.data.map((row) => parseFloat(row.tag_value))
                        : []
                    : secondSeriesData,
                // data: secondeLineChartReportData.length
                //     ? secondeLineChartReportData[0]?.data.map((row) => parseFloat(row.tag_value))
                //     : [],
                color: findUnitColor2 ? findUnitColor2.color : "",
                yAxis: 1, // Set the series to use the second y-axis,
                pointStart: 0,
                ...(Number(interval1) === 1 || Number(interval2) === 1 ? { pointInterval: Number(interval2) } : {}), // Add pointInterval only if interval2 is 1
            }
        ],
        yAxis: [
            {
                title: {
                    text: selectFirstUnit == "none" ? "" : selectFirstUnit // First y-axis title
                }
            },
            {
                title: {
                    text: selectSecondUnit == "none" ? "" : selectSecondUnit// Second y-axis title
                },
                opposite: true // Display the second y-axis on the opposite side
            }
        ],
        // colors: unitColorsReportRedux.length ? unitColorsReportRedux.map((e) => e.color) : [],
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let s = `${this.x}<br/>`;
                this.points.forEach(point => {
                    s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
                });
                return s;
            }
        },
    };

    return (
        <>

            {!Shimmer ? fisrtLineChartReportData.length || secondeLineChartReportData.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : <img src={images.noDataFoundImg} className="notFoundDataImg" alt='No DataFound Img' style={{ paddingTop: "100px" }} /> : <ShimmerEffectLineChart />}
        </>
    )
}