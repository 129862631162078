import React, { useEffect, useMemo, useState, useRef } from "react";
import PlantLocation from "../../../components/BPCL/PlantLocation/PlantLocation";
import { Typography } from "antd";
import {
  Box,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { images } from "../../../config";
import {
  getHikemmREPlantAnalyzeDashboardTags,
  getHikemmREPlantDashboardTags,
} from "../services";
import Mqqtdata from "../../ProcessDashboard/Mqqtdata";
import { BPCLPlant } from "../../CompanyDashboard/services";
import { useNavigate, useParams } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PlantLocationAnalyze from "./Plant-Location-Analyze";
import AnalyzeLineChart, {
  ChartComponent,
} from "../../../components/Graph/BPCL/Analyze-Line-Chart";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";

HC_more(Highcharts);
exporting(Highcharts);

// import { LineChart } from "../../../components/Graph/LineChart/LineChart";

const AnalyzeNext = () => {
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);
  const [chartData, setChartData] = useState([]); // Holds data for the selected column chart
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [previousChartData, setPreviousChartData] = useState([]);
  const [mqttTagDataAnalyze, setMqttTagDataAnalyze] = useState([]);
  const [topicsAnalyze, setTopicsAnalyze] = useState([]);

  const [plantData, setPlantData] = useState([]);
  const [active, setActive] = useState("");
  const [activeSwitches, setActiveSwitches] = useState([]);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [selectedValuesArray, setSelectedValuesArray] = useState([]);
  const [averageClick, setAverageClick] = useState(false);
  const [averageValuesArray, setAverageValuesArray] = useState([]);
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [hasExecutedOnce, setHasExecutedOnce] = useState(false);
  const params = useParams();
  const chartRef = useRef(null); // Reference to the chart instance
  const [previousMergedArray, setPreviousMergedArray] = useState([]);
  const [percentageChanges, setPercentageChanges] = useState([]); // To store percentage changes
  const [displayedValue, setDisplayedValue] = useState([]);
  const [summedArray, setSummedArray] = useState([]);
  const [tagUnitSums, setTagUnitSums] = useState({});
  const [previousTagUnitSums, setPreviousTagUnitSums] = useState({});
  const [changeDirections, setChangeDirections] = useState("");
  const [previousPercentageChange, setPreviousPercentageChange] = useState("");

  useEffect(() => {
    if (chartRef.current && chartData.length > 0) {
      chartRef.current.chart.redraw(); // Manually trigger redraw
    }
  }, [chartData]);

  const mergedArray = mqttTagDataAnalyze
    ? [].concat(...Object.values(mqttTagDataAnalyze))
    : [];

  const handleClick = (type) => {
    setActive((prevActive) => (prevActive === type ? null : type));
  };

  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (tagUnitData.length) {
      // console.log("tagUnitData", tagUnitData);
      const topicArray = tagUnitData.flatMap((plant) =>
        Object.values(plant.tags).map(
          (tag) => `Hikar/DeviceUID/${tag.device_uid}`
        )
      );
      setTopicsAnalyze(topicArray);
    }
  }, [tagUnitData]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.companyId,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };

  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds]);

  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "kWH_TOTAL",
      utilization_tag_unit: "%",
      cost_saving_tag_unit: "Amp_R",
      co2_green_tag_unit: "kWH_YIELD",
    };
    try {
      const resp = await getHikemmREPlantDashboardTags(params);
      if (resp.status == 200 || resp.status == 201) {
        setRealTimePlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlePlantAnalyzeDashboardTags();
  }, [activeSwitches]);

  const handlePlantAnalyzeDashboardTags = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      position_1_tag_unit: "MW",
      position_2_tag_unit: "MVAr",
      position_3_tag_unit: "CUF%",
      position_4_tag_unit: "PR%",
      position_5_tag_unit: "W/m2_GTI",
      position_6_tag_unit: "degC_Amb",
      position_7_tag_unit: "degC_Mod",
    };
    try {
      const resp = await getHikemmREPlantAnalyzeDashboardTags(params);
      if (resp.data.success == true) {
        setTagUnitData(resp.data.payload);
      }
    } catch (error) {
      setTagUnitData([]);
      console.log("handlesubmit", error);
    }
  };

  // const handleChartToggle = (index) => {
  //   setShowChart(true); // Toggle the chart visibility
  //   setSelectedColumnIndex(index)
  // };

  const prevSelectedValueRef = useRef({ value: "-", index: -1 });

  const navigate = useNavigate();
  const param = useParams();
  const [prevData, setPrevData] = useState({});

  const [selectUnit, setSelectUnit] = useState("");
  // Handle column selection for chart data update
  const handleChartToggle = (cellIndex, tagUnit) => {
    setSelectUnit(tagUnit);
    if (selectedColumn === cellIndex) {
      setSelectedColumn(null); // Hide the chart when clicked again
    } else {
      setSelectedColumn(cellIndex); // Show the chart for the selected column
    }
  };

  useEffect(() => {
    if (selectedColumn !== null) {
      const updatedChartData = activeSwitches.map((e) => {
        const plant = tagUnitData.find((plant) => plant.plant_id === e.id);
        const plantTags = plant ? plant.tags : [];

        // Get matched tags for the selected column
        const matchedTags = Object.keys(plantTags).map((positionKey) => {
          const tag = plantTags[positionKey];
        let tagValue = mergedArray.length
                    ? mergedArray.find(
                        (f) =>
                          f.device__uid == tag.device_uid &&
                          f.device_tag == tag.device_tag
                      )?.["tagValue"] || 0.0
                    : 0.0;
        
                  if (
                    tag.is_soft_tag &&
                    tag.soft_tag_id__formula &&
                    Object.keys(mqttDataReduce).length
                  ) {
                    if (
                      SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
                        ?.softTagNotGetTag == true
                    ) {
                      tagValue = SoftTagFormula(
                        tag.soft_tag_id__formula,
                        mqttDataReduce
                      )?.value;
                    } else {
                      tagValue = tagValue;
                    }
                  }
          return tagValue ? parseFloat(tagValue) : null; // Show null if no match
        });

        return {
          plant: e.name,
          data: matchedTags, // Store all data points for each plant
        };
      });

      setChartData((prevData) => {
        const isDataChanged = updatedChartData.some((newPlantData, index) => {
          return (
            !prevData[index] ||
            !newPlantData.data.every(
              (value, idx) => prevData[index].data[idx] === value
            )
          );
        });

        if (isDataChanged) {
          return updatedChartData;
        }
        return prevData;
      });
    } else {
      setChartData([]);
    }
  }, [selectedColumn, activeSwitches, tagUnitData, mergedArray]);

  useEffect(() => {
    updateSelectedColumnDataMatrix();
  }, [activeSwitches, selectedColumn, mqttTagDataAnalyze]);

  useEffect(() => {
    setSelectedValuesArray([]);
    setTimeCategories([]);
  }, [selectedColumn, activeSwitches]);

  const updateSelectedColumnDataMatrix = () => {
    if (selectedColumn !== undefined && selectedColumn >= 0) {
      activeSwitches.forEach((switchObj, index) => {
        const plant = tagUnitData.find(
          (plant) => plant.plant_id === switchObj.id
        );
        const plantTags = plant ? plant.tags : {};
        const matchedTags = Object.keys(plantTags).map((positionKey) => {
          const tag = plantTags[positionKey];
           let tagValue = mergedArray.length
                     ? mergedArray.find(
                         (f) =>
                           f.device__uid == tag.device_uid &&
                           f.device_tag == tag.device_tag
                       )?.["tagValue"] || 0.0
                     : 0.0;
         
                   if (
                     tag.is_soft_tag &&
                     tag.soft_tag_id__formula &&
                     Object.keys(mqttDataReduce).length
                   ) {
                     if (
                       SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
                         ?.softTagNotGetTag == true
                     ) {
                       tagValue = SoftTagFormula(
                         tag.soft_tag_id__formula,
                         mqttDataReduce
                       )?.value;
                     } else {
                       tagValue = tagValue;
                     }
                   }
          return tagValue ? tagValue : "-";
        });

        const selectedVal = matchedTags[selectedColumn] ?? "-";
        console.log(
          prevSelectedValueRef.current.value,
          selectedVal,
          "yyyyyyyyyy"
        );
        if (
          prevSelectedValueRef.current.value !== selectedVal ||
          prevSelectedValueRef.current.index !== index
        ) {
          setSelectedValue({ value: selectedVal, index: index });
          prevSelectedValueRef.current = { value: selectedVal, index: index };

          setSelectedValuesArray((prevArray) => {
            const updatedArray = Array.isArray(prevArray) ? [...prevArray] : [];
            while (updatedArray.length <= index) {
              updatedArray.push([]);
            }
            if (!updatedArray[index].includes(selectedVal.toString())) {
              updatedArray[index].push(selectedVal.toString());
            }
            if (updatedArray[index].length > 250) {
              updatedArray[index].shift();
            }
            return updatedArray;
          });
        }
      });
    }
  };
  const [timeCategories, setTimeCategories] = useState([]);

  const handleAverageClick = () => {
    if (selectedValuesArray.length > 0) {
      // Get the maximum length of arrays to handle varying lengths
      const maxLength = Math.max(
        ...selectedValuesArray.map((arr) => arr.length)
      );

      // Calculate the average for all arrays in selectedValuesArray
      const averageArray = Array.from({ length: maxLength }, (_, index) => {
        const validValues = selectedValuesArray
          .map((arr) => parseFloat(arr[index]))
          .filter((value) => !isNaN(value)); // Filter out invalid numbers

        if (validValues.length === 0) return 0; // Handle missing values

        const sum = validValues.reduce((acc, value) => acc + value, 0);
        return sum / validValues.length; // Return the average for this index
      });

      setAverageValuesArray(averageArray); // Update state with the computed average
    } else {
      console.log("No data available for averaging.");
    }
  };

  // Recalculate averages when new data arrives
  useEffect(() => {
    handleAverageClick();
  }, [selectedValuesArray, timeCategories]); // Trigger recalculation when dependencies change

  const updateTimeCategories = () => {
    const now = new Date();
    const newTimestamp = now.toLocaleTimeString("en-US", { hour12: false }); // Format as hh:mm:ss

    setTimeCategories((prevCategories) => {
      // Clone the previous categories to avoid direct mutation
      const newCategories = [...prevCategories];

      // Ensure the new timestamp is only added when appropriate
      if (
        selectedValuesArray.some((arr) => arr.length > newCategories.length) &&
        (newCategories.length === 0 ||
          newCategories[newCategories.length - 1] !== newTimestamp)
      ) {
        // Add the timestamp only if it's not already present
        if (!newCategories.includes(newTimestamp)) {
          newCategories.push(newTimestamp);
        }
      }

      // Align `timeCategories` length with the longest array in `selectedValuesArray`
      const maxLength = Math.max(
        ...selectedValuesArray.map((arr) => arr.length)
      );

      while (newCategories.length > maxLength) {
        newCategories.shift(); // Remove the oldest timestamp to maintain consistency
      }

      while (newCategories.length < maxLength) {
        // Add placeholder timestamps for consistency with prior device data
        const placeholderTimestamp = newCategories[0] || newTimestamp;
        newCategories.unshift(placeholderTimestamp);
      }

      return newCategories;
    });
  };

  // Update x-axis categories dynamically when new data is added
  useEffect(() => {
    if (selectedValuesArray.some((arr) => arr.length > timeCategories.length)) {
      updateTimeCategories();
    }
  }, [selectedValuesArray]);

  const averageSeries = averageClick
    ? [
        {
          name: "Average",
          data: averageValuesArray ? averageValuesArray : [],
          color: "green",
          dashStyle: "ShortDash", // You can use other dash styles as well
        },
      ]
    : [];

  const seriesData = selectedValuesArray
    ? selectedValuesArray.length
      ? selectedValuesArray.map((dataArray, index) => {
          return {
            name: activeSwitches[index]?.name || `Series ${index + 1}`,

            data: dataArray.map((value) => parseFloat(value)),
            color: activeSwitches[index]?.color,
          };
        })
      : []
    : [];

  const combineSeries = [...seriesData, ...averageSeries];
  console.log("activeSwitches", activeSwitches);

  const [keyValue, setkeyGraph] = useState(0);
  const [chartOption, setChartOption] = useState({
    chart: {
      type: "spline",

      scrollablePlotArea: {
        minWidth: 700, // Minimum width to trigger scrollbar
        scrollPositionX: 1,
      },
    },
    title: {
      text: null, // Remove the title
    },
    series: [],
    xAxis: {
      categories: [], // Ensure categories are populated with data
      scrollbar: {
        enabled: true, // Enable scrollbar explicitly on the x-axis
      },
      labels: {
        overflow: "justify", // Ensure labels are displayed properly if they overflow
      },
    },
    yAxis: {
      title: {
        text: selectUnit,
      },
    },
    legend: {
      itemStyle: {
        color: "black",
        fontSize: "14px",
      },
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = `${this.x}<br />`;
        this.points.forEach((point) => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });
        return s;
      },
    },
  });

  useEffect(() => {
    setChartOption({
      ...chartOption,
      xAxis: {
        ...chartOption.xAxis,

        categories: timeCategories,
      },
      yAxis: {
        ...chartOption.yAxis,
        title: {
          text: selectUnit,
        },
      },
      series: combineSeries,
    });

    // Update the key to force component re-render and chart redraw
    setkeyGraph(keyValue + 1);
  }, [selectedValuesArray, averageClick, timeCategories]);

  // const chartOptions = {
  //   title: { text: null },
  //   chart: {
  //     type: "spline",
  //     zoomType: "x",
  //   },
  //   xAxis: {
  //     categories: timeCategories,
  //     scrollbar: { enabled: true },
  //     min: timeCategories.length > 5 ? timeCategories.length - 5 : 0,  // Ensuring min is a valid number
  //     max: timeCategories.length - 1,
  //   },

  //   yAxis: {
  //     title: { text: "MW", align: "low", rotation: 0, x: 10, y: 30, style: { color: "black", fontSize: "14px" } },
  //   },
  //   series: selectedValuesArray.map((dataArray, index) => ({
  //     name: activeSwitches[index]?.name || `Series ${index + 1}`,
  //     type: "line",
  //     data: dataArray.map((value) => parseFloat(value)),
  //     color: activeSwitches[index]?.color,
  //     lineWidth: 2,
  //     marker: { enabled: true },
  //   })),
  //   scrollbar: { enabled: true },
  //   credits: { enabled: false },
  //   legend: { enabled: false },
  //   exporting: { enabled: true },
  // };

  // useEffect(() => {
  //   const chart = chartRef.current?.chart;
  //   if (chart && timeCategories.length > 5) {
  //     chart.xAxis[0].setExtremes(
  //       timeCategories.length - 5, // Show the last 5 points
  //       timeCategories.length - 1
  //     );
  //   }
  // }, [timeCategories, selectedValuesArray]);

  useEffect(() => {
    const chart = chartRef.current?.chart; // Get chart instance

    if (chart) {
      // Update series for selectedValuesArray
      selectedValuesArray.forEach((dataArray, index) => {
        const series = chart.series[index];

        if (series) {
          // Add new points to existing series
          const newDataPoints = dataArray.slice(series.data.length);
          newDataPoints.forEach((value) => {
            series.addPoint(parseFloat(value), true, false); // Add point dynamically
          });
        } else {
          // Add a new series if it doesn't exist
          chart.addSeries({
            name: activeSwitches[index]?.name || `Series ${index + 1}`,
            type: "line",
            data: dataArray.map((value) => parseFloat(value)),
            color: activeSwitches[index]?.color,
            lineWidth: 2,
            marker: {
              enabled: true,
            },
          });
        }
      });

      // Add the average line if averageClick is true and averageValuesArray has data
      if (averageClick && averageValuesArray.length > 0) {
        const averageSeries = chart.series.find(
          (series) => series.name === "Average"
        );

        if (averageSeries) {
          // Update the existing average series with only new points
          const currentLength = averageSeries.data.length; // Get the existing data length
          const newAveragePoints = averageValuesArray.slice(currentLength); // Get only new points
          newAveragePoints.forEach((value) => {
            averageSeries.addPoint(parseFloat(value), true, false); // Append new points
          });
        } else {
          // Add a new series for the average line if it doesn't exist
          chart.addSeries({
            name: "Average",
            type: "line",
            data: averageValuesArray.slice(), // Initial data for the average line
            color: "#00FF00", // Set the average line color to green
            lineWidth: 2,
            dashStyle: "Dash", // Optional: keep the line dashed
            marker: {
              enabled: true,
            },
          });
        }
      }
    }
  }, [selectedValuesArray, averageValuesArray, averageClick]);

  useEffect(() => {
    const uniqueTagUnits = [
      ...new Set(
        tagUnitData.flatMap((data) =>
          Object.values(data.tags).map((tag) => tag.tag_unit)
        )
      ),
    ];
    const keyValueArray = activeSwitches.map((switchObj) => {
      const plant = tagUnitData.find(
        (plant) => plant.plant_id === switchObj.id
      );
      const plantTags = plant ? plant.tags : {};
      // Map matched tags
      const matchedTags = Object.keys(plantTags).map((positionKey) => {
        const tag = plantTags[positionKey];
        let tagValue = mergedArray.length
        ? mergedArray.find(
            (f) =>
              f.device__uid == tag.device_uid &&
              f.device_tag == tag.device_tag
          )?.["tagValue"] || 0.0
        : 0.0;

      if (
        tag.is_soft_tag &&
        tag.soft_tag_id__formula &&
        Object.keys(mqttDataReduce).length
      ) {
        if (
          SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
            ?.softTagNotGetTag == true
        ) {
          tagValue = SoftTagFormula(
            tag.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
        } else {
          tagValue = tagValue;
        }
      }
return tagValue ? tagValue : "-";
      });

      // Build key-value object
      const keyValueObject = uniqueTagUnits.reduce((acc, key, index) => {
        acc[key] = matchedTags[index] || "-";
        return acc;
      }, {});
      setDisplayedValue(keyValueObject);
      return keyValueObject;
    });
    // Step 2: Calculate summedArray
    const calculateMetricsAndSet = () => {
      let MW_Sum = 0;
      let MVAR_Sum = 0;
      let CUF_Sum = 0;
      let PR_Pct_Sum = 0;
      let Wm2_GTI_Sum = 0;
      let degC_Amb = 0;
      let degC_Mod = 0;
      keyValueArray.forEach((plant) => {
        MW_Sum += parseFloat(plant.MW) || 0;
        MVAR_Sum += parseFloat(plant.MVAr) || 0;
        CUF_Sum += parseFloat(plant["CUF%"]) || 0;
        PR_Pct_Sum += parseFloat(plant["PR%"]) || 0;
        Wm2_GTI_Sum += parseFloat(plant["W/m2_GTI"]) || 0;
        degC_Amb += parseFloat(plant.degC_Amb) || 0;
        degC_Mod += parseFloat(plant.degC_Mod) || 0;
      });
      const setValues = {
        MW: Math.round(MW_Sum),
        MVAr: Math.round(MVAR_Sum),
        "CUF%": Math.round(CUF_Sum),
        "PR%": Math.round(PR_Pct_Sum),
        "W/m2_GTI": Math.round(Wm2_GTI_Sum),
        degC_Amb: Math.round(degC_Amb),
        degC_Mod: Math.round(degC_Mod),
      };
      setSummedArray(setValues); // Update displayed values
      return setValues;
    };

    // Call the function and log results
    const newTagUnitSums = calculateMetricsAndSet();

    // Calculate percentage changes
    const calculatePercentageChanges = (prev, current) => {
      const changes = {};
      const directions = {};
      Object.keys(current).forEach((key) => {
        if (prev[key] !== undefined) {
          const prevValue = prev[key] ?? 0;
          const currentValue = current[key];
          const difference = currentValue - prevValue;
          const percentageChange =
            prevValue !== 0 ? (difference / prevValue) * 100 : 0; // Avoid division by zero
          changes[key] = Math.round(percentageChange * 100) / 100; // Round to 2 decimal places
        } else {
          changes[key] = 0; // Default to 0% if no previous value exists
        }
      });
      return changes;
    };

    const changedPercentage = (prev, current) => {
      const directions = {}; // Object to hold the direction of change for each key
      Object.keys(current).forEach((key) => {
        const currentChange = current[key];
        const prevChange = prev[key] !== undefined ? prev[key] : null;
        if (prevChange !== null) {
          // Compare current and previous percentage changes
          if (currentChange > prevChange) {
            directions[key] = "increased";
          } else if (currentChange < prevChange) {
            directions[key] = "decreased";
          } else {
            directions[key] = "neutral"; // No change in percentage
          }
        } else {
          // If no previous change exists, mark it as "neutral" or "new"
          directions[key] = "neutral";
        }
      });
      return directions; // Return the directions for each key
    };
    const newPercentageChanges = calculatePercentageChanges(
      previousTagUnitSums,
      newTagUnitSums
    );
    const changeDirections = changedPercentage(
      previousPercentageChange,
      newPercentageChanges
    );
    setTagUnitSums(newTagUnitSums);
    setPercentageChanges(newPercentageChanges);
    setChangeDirections(changeDirections); // New state for direction tracking
    // setPreviousTagUnitSums(newTagUnitSums);
    // Delay updating `previousTagUnitSums` to the next render
    setTimeout(() => setPreviousTagUnitSums(newTagUnitSums), 5000);
    setTimeout(() => setPreviousPercentageChange(newPercentageChanges), 5000);
  }, [activeSwitches, mqttTagData]);

  useEffect(() => {
    // Automatically select the first column when data is loaded
    if (tagUnitData.length > 0 && selectedColumn === null) {
      setSelectedColumn(0); // Set the first column as default
      setSelectUnit("MW");
    }
  }, [tagUnitData, selectedColumn, selectUnit]);

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  return (
    <>
      <div className="Bpcl-page-wrapper">
        <>
          {realTimeData.length ? (
            <Mqqtdata
              setMqttTagData={setMqttTagData}
              topics={topics}
              overViewTagData={realTimeData}
            />
          ) : (
            ""
          )}
          {tagUnitData.length ? (
            <Mqqtdata
              setMqttTagData={setMqttTagDataAnalyze}
              topics={topicsAnalyze}
              overViewTagData={tagUnitData}
            />
          ) : (
            ""
          )}
          <Grid container>
            <Grid item xs={12} md={3}>
              <PlantLocationAnalyze
                mqttTagData={mqttTagData}
                realTimePlantDta={realTimePlantDta}
                plantData={plantData}
                active={active}
                handleClick={handleClick}
                activeSwitches={activeSwitches}
                setActiveSwitches={setActiveSwitches}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Box className="map-box">
                <div className="bpcl-line-chart">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img
                        src={images.preIco}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/bpcl-analyze/company/${param.companyId}`)
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <h6>
                        Average{" "}
                        {/* <Switch
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#d2dad2", // Thumb color when checked
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#00ff00", // Track color when checked
                              },
                          }}
                          onClick={() => setAverageClick(!averageClick)}
                        /> */}
                        <Switch
                          checked={averageClick}
                          onClick={() => setAverageClick(!averageClick)}
                          // Add your switch event handling logic here
                          sx={{
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#828282",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#D9D9E5 !important",
                            },
                            "& .Mui-checked": {
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#50C878",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#CEE6E1 !important",
                              },
                            },
                          }}
                        />
                      </h6>
                      <h6 className="mt-2">Interval</h6>

                      {/* <select
                        className="form-input-class"
                        style={{
                          padding: "1px 3px",
                          borderRadius: "4px",
                          height: "35px",
                          // marginRight: "20px",
                        }}
                        id="selectedClass"
                        value={selectTimeRange}
                        onChange={(event) =>
                          setSelectTimeRange(event.target.value)
                        }
                        required
                      >
                        <option value="live">Live</option>
                      </select> */}
                      <button className="live-genration">Live</button>
                    </div>
                  </div>
                  {selectedColumn !== null &&
                  selectedValuesArray?.length > 0 ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOption}
                      ref={chartRef}
                    />
                  ) : (
                    <div style={{ height: "400px" }}>
                      <img
                        src={images.noDataFoundImg}
                        className="notFoundDataImg-analyze"
                        alt="No Data Found Img"
                      />
                    </div>
                  )}
                  {/* {chartData.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={chartRef}
                      />
                    </div>
                  )} */}
                </div>
                <div
                  className="report-overview-table"
                  style={{ height: "324px" }}
                >
                  <Table className="source-table bpcl-report-tab">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          Plants
                        </TableCell>

                        {activeSwitches.length > 0 &&
                          Object.values(tagUnitData[0]?.tags || {}).map(
                            (value, cellIndex) => {
                              // Debugging output for validation
                              console.log(
                                "Processing tag:",
                                value?.tag_name,
                                value?.tag_unit
                              );

                              // Helper: Determine icon based on change direction
                              const isIncreaseOrNeutral =
                                changeDirections[value?.tag_unit] ===
                                  "increased" ||
                                changeDirections[value?.tag_unit] === "neutral";

                              // Helper: Calculate percentage change
                              const percentageChange =
                                percentageChanges[value?.tag_unit]?.toFixed(
                                  2
                                ) || "0";

                              return (
                                <TableCell key={`${cellIndex}`}>
                                  <div
                                    className="table-cell-div"
                                    style={{
                                      minWidth: "max-content",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={images.lineIco}
                                        style={{ cursor: "pointer" }}
                                        alt="Toggle Chart"
                                        onClick={() =>
                                          handleChartToggle(
                                            cellIndex,
                                            value?.tag_unit
                                          )
                                        }
                                        className="line-ico-img"
                                      />
                                    </div>

                                    <div
                                      className="d-flex gap-1 justify-content-center"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <p className="mb-0">
                                        {value?.tag_name} ({value?.tag_unit})
                                      </p>

                                      {isIncreaseOrNeutral ? (
                                        <ArrowUpwardIcon
                                          fontSize="small"
                                          color="error"
                                        />
                                      ) : (
                                        <ArrowDownwardIcon
                                          fontSize="small"
                                          color="error"
                                        />
                                      )}

                                      <p>
                                        <b>{percentageChange}%</b>
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                              );
                            }
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeSwitches.map((e, rowIndex) => {
                        // Find the corresponding plant data for the current active switch
                        const plant = tagUnitData.find(
                          (plant) => plant.plant_id === e.id
                        );

                        const plantTags = plant ? plant.tags : [];

                        // If tags are stored as an object instead of an array, you need to extract them appropriately
                        const matchedTags = Object.keys(plantTags).map(
                          (positionKey) => {
                            const tag = plantTags[positionKey]; // Get the tag using the positionKey

                            let tagValue = mergedArray.length
                              ? mergedArray.find(
                                  (f) =>
                                    f.device__uid == tag.device_uid &&
                                    f.device_tag == tag.device_tag
                                )?.["tagValue"] || 0.0
                              : 0.0;

                            if (
                              tag.is_soft_tag &&
                              tag.soft_tag_id__formula &&
                              Object.keys(mqttDataReduce).length
                            ) {
                              if (
                                SoftTagFormula(
                                  tag.soft_tag_id__formula,
                                  mqttDataReduce
                                )?.softTagNotGetTag == true
                              ) {
                                tagValue = SoftTagFormula(
                                  tag.soft_tag_id__formula,
                                  mqttDataReduce
                                )?.value;
                              } else {
                                tagValue = tagValue;
                              }
                            }
                            return tagValue ? tagValue : "-";
                          }
                        );

                        console.log("matchedTags",matchedTags)
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell
                              style={{ width: "200px", whiteSpace: "nowrap" }}
                            >
                              {e.name}
                            </TableCell>
                            {matchedTags.map((value, cellIndex) => (
                              <TableCell key={cellIndex}>
                             {typeof value === 'number' ? value.toFixed(2) : value}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Grid>
          </Grid>
        </>
      </div>
    </>
  );
};

export default AnalyzeNext;
