import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  createTheme,
} from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import {
  customeReportData,
  exportAnalyticsCOCExcel,
  getDeviceReceipe,
  getDeviceUnitData,
  getExportAnalyticsReportExcel,
  getExportPOPExcel,
  getHikEMMDeviceTagUnits,
  getOverViewAnalyticsData,
  getOverViewAnalyticsDataReceipe,
  getOverViewPieChartData,
  getOverviewDeviceData,
  getOverviewReportData,
  getOverviewReportDeviceData,
  getUnitData,
} from "../../ProcessDashboard/OverView/services";
import { Card, Stack, Typography } from "@mui/material";
import { OverViewAnalyticsReportApiData } from "../../ProcessDashboard/OverView/OverViewAnalyticsReportApiData";
import { useDispatch, useSelector } from "react-redux";
import { setOverviewData } from "../../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { ParetoChart } from "../../ProcessDashboard/OverView/ParetoChart";
import dayjs, { Dayjs } from "dayjs";
import { images } from "../../../config/images";
import { MultiAxisLineChart } from "../../ProcessDashboard/OverView/MultiAxisReportLineChart";
import { PopDonutChart } from "../../ProcessDashboard/OverView/PopPieChart";
import { PopgroupBarChart } from "../../ProcessDashboard/OverView/PopgroupBarChart";
import { ToastContainer, toast } from "react-toastify";
import { DonutChart } from "../../ProcessDashboard/OverView/PieChart";
import COCDatePicker from "../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { ConvertToISOFormat } from "../../../components/ConvertToISOFormat/ConvertToISOFormat";
import { CompactTagUnit } from "../../../components/CompactValue/CompactValue";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { setProcessDashboarDateSet } from "../../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import {
  storeSelectedData,
} from "../../VMS/VmsCompanyDashboard/VmsMachine/service";
import ZoomInChart from "../../ProcessDashboard/OverView/ZoomInEnergyBarChart";
import ZoomInPOPChart from "../../ProcessDashboard/OverView/ZoomInEnergyBarChartPOP";
import { getPlant } from "../../CompanyDashboard/services";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "8px 10px", // set the desired padding here
    },
  },
});

const BPCLReports = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [reportOverViewData, setReportOverViewData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceUnitData, setDeviceUnitData] = useState([]);
  const [deviceUnitPOPData, setDeviceUnitPOPData] = useState([]);
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [analyticsData, setAnalyticsData] = useState([]);
  const [analyticsPOPData, setAnalyticsPOPData] = useState([]);
  const [analyticsPOP1Data, setAnalyticsPOP1Data] = useState([]);
  const [analyticsPieChartData, setAnalyticsPieChartData] = useState([]);
  const [analyticsPopPieChart, setAnalyticsPopPieChart] = useState([]);
  const [analyticsPop1PieChart, setAnalyticsPop1PieChart] = useState([]);
  const [donutPOPChartSum, setDonutPOPChartSum] = useState([]);
  const [donutPOP1ChartSum, setDonut1POPChartSum] = useState([]);
  const [donutPOPUnit, setDonutPOPUnit] = useState([]);
  const [donutChartSum, setDonutChartSum] = useState([]);
  const [donutUnit, setDonutUnit] = useState([]);
  const [cocDeviceId, setCocDeviceId] = useState([]);
  const [selectedPreviousData, setSelectedPreviousData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  console.log("cocDeviceId", cocDeviceId);

  // Report Overview Analysis
  const [deviceReport, setDeviceReport] = useState([]);
  const [unitReport, setUnitReport] = useState([]);
  const [showTimeReport, setShowTimeReport] = useState(false);
  const [selectAggReport, setSelectAggReport] = useState("15");
  const [selectIntervalReport, setSelectIntervalReport] = useState("1");
  const [selectTagIDReport1, setSelectTagIDReport1] = useState("none");
  const [selectTagIDReport2, setSelectTagIDReport2] = useState("none");
  const [startDateRep, setStartDateRep] = useState(null);
  const [endDateRep, setEndDateRep] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoadingPop, setLoadingPop] = useState(false);
  const [isLoadingPop1, setLoadingPop1] = useState(false);
  const [isLoadingReport, setLoadingReport] = useState(false);
  const [isShimmerCOCParetoChart, setisShimmerCOCParetoChart] = useState(false);
  const [isShimmerCOCDonutChart, setisShimmerCOCDonutChart] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);
  const [receipeData, setReceipeData] = useState([]);
  const [customizedReportModal, setCustomizedReportModal] = useState(false);
  // const [receipePOPData, setReceipePOPData] = useState([]);

  const modalRef = useRef();

  const [isShimmerPopgroupBarChartPOP, setisShimmerPopgroupBarChartPOP] =
    useState(false);
  const [isShimmerPopDonutChartPOP, setisShimmerPopDonutChartPOP] =
    useState(false);

  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);

  const [isShimmerReportdevicelist, setisShimmerReportdevicelist] =
    useState(false);
  const [isShimmerReportUnitlist, setisShimmerReportUnitlist] = useState(false);

  // chartData Report
  const [fisrtLineChartReportData, setFisrtLineChartReportData] = useState([]);
  const [secondeLineChartReportData, setSecondLineChartReportData] = useState(
    []
  );

  const [
    isShimmerMultiAxisLineChartReports,
    setisShimmerMultiAxisLineChartReports,
  ] = useState(false);
  const { is_renewable_energy } = useSelector(
    (state) => state.userState
  );

  const {
    // deviceIdRedux,
    // deviceUnitRedux,
    // deviceColors,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    deviceColorsReportRedux,
    unitColorsReportRedux,
    // deviceIdPOPRedux,
    // deviceUnitPOPRedux,
    // devicePOPColors,
    // startDateRe,
    // endDateRe,
    // startDatePopRe,
    // endDatePopRe,
    // startDatePopRe1,
    // endDatePopRe1,
    activeClassConsumer,
    // showTime,
    // showTimePop,
    // selectTimeRange,
    // selectPOPTimeRange,
    reportStartDate,
    reportEndDate,
    reportSelectUnit1,
    reportSelectUnit2,
    reportAgg,
    reportInterval,
    reportShowTime,
    // overviewCOCswitch,
    // overviewPOPswitch,
  } = useSelector((state) => state.overViewState);

  const [overviewCOCswitch, setOverViewCOCswitch] = useState(false);
  const [overviewPOPswitch, setOverViewPOPswitch] = useState(false);

  const [startDateRe, setStartDateRe] = useState(
    overviewCOCswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDateRe, setEndDateRe] = useState(
    overviewCOCswitch ? dayjs() : null
  );
  const [startDatePopRe, setStartDatePopRe] = useState(
    overviewPOPswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDatePopRe, setEndDatePopRe] = useState(
    overviewPOPswitch ? dayjs() : null
  );
  const [startDatePopRe1, setStartDatePopRe1] = useState(
    overviewPOPswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDatePopRe1, setEndDatePopRe1] = useState(
    overviewPOPswitch ? dayjs() : null
  );
  const [deviceColors, setDeviceColors] = useState([]);
  const [deviceIdRedux, setDeviceIdRedux] = useState([]);
  const [deviceUnitRedux, setDeviceUnitRedux] = useState([]);
  const [deviceIdPOPRedux, setDeviceIdPOPRedux] = useState([]);
  const [devicePOPColors, setDevicePOPColors] = useState([]);
  const [deviceUnitPOPRedux, setDeviceUnitPOPRedux] = useState([]);
  const [selectTimeRange, setSelectTimeRange] = useState("1");
  const [selectPOPTimeRange, setSelectPOPTimeRange] = useState("1");
  const [showTime, setShowTime] = useState(false);
  const [showTimePop, setShowTimePop] = useState(false);
  const [showZoomChart, setShowZoomChart] = useState(false);
  const [showZoomPOPChart, setShowZoomPOPChart] = useState(false);
  const [showOverViewChart, setShowOverviewchart] = useState(true);
  console.log("reportSelectUnit1", reportSelectUnit1);

  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedPOPItems, setCheckedPOPItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allPOPChecked, setAllPOPChecked] = useState(false);
  const options = ["All", "Receipt - 1", "Receipt - 2", "Receipt - 3"];

  console.log("overviewCOCswitch", overviewCOCswitch);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setAllChecked(checkedItems.length === receipeData.length);
  }, [checkedItems, receipeData.length]);

  useEffect(() => {
    setAllPOPChecked(checkedPOPItems.length === receipeData.length);
  }, [checkedPOPItems, receipeData.length]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (option) => {
    setCheckedItems((prevChecked) =>
      prevChecked.includes(option)
        ? prevChecked.filter((item) => item !== option)
        : [...prevChecked, option]
    );
  };

  const handleAllToggle = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      setCheckedItems(receipeData);
    }
    setAllChecked(!allChecked);
  };

  const handleAllPOPToggle = () => {
    if (allPOPChecked) {
      setCheckedPOPItems([]);
    } else {
      setCheckedPOPItems(receipeData);
    }
    setAllPOPChecked(!allPOPChecked);
  };

  const handleTogglePOP = (option) => {
    setCheckedPOPItems((prevChecked) =>
      prevChecked.includes(option)
        ? prevChecked.filter((item) => item !== option)
        : [...prevChecked, option]
    );
  };
  const { user_access } = useSelector((state) => state.userState);

  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  const startDatePOPReFormat = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDatePOPReFormat = endDatePopRe ? dayjs(endDatePopRe) : null;

  const startDatePOP1ReFormat = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDatePOP1ReFormat = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const startDateReportFormat = reportStartDate ? dayjs(reportStartDate) : null;
  const endDateReportFormat = reportEndDate ? dayjs(reportEndDate) : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  const selectDateTime = selectedTime
    ? dayjs(selectedTime).format(timeFormat)
    : "";

  const startTimeFormat = startDateRe
    ? dayjs(startDateRe).format(timeFormat)
    : "";

  const endTimeFormat = endDateRe ? dayjs(endDateRe).format(timeFormat) : "";
  const startPOPTimeFormat = startDatePopRe
    ? dayjs(startDatePopRe).format(timeFormat)
    : "";
  const endPOPTimeFormat = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";
  const startPOP1TimeFormat = startDatePopRe1
    ? dayjs(startDatePopRe1).format(timeFormat)
    : "";
  const endPOP1TimeFormat = endDatePopRe1
    ? dayjs(endDatePopRe1).format(timeFormat)
    : "";
  const formatStartDate = startDateRe
    ? dayjs(startDateRe).format(dateFormat)
    : "";
  const formatEndDate = endDateRe ? dayjs(endDateRe).format(dateFormat) : "";

  const formatPOPStartDate = startDatePopRe
    ? dayjs(startDatePopRe).format(dateFormat)
    : "";
  const formatPOPEndDate = endDatePopRe
    ? dayjs(endDatePopRe).format(dateFormat)
    : "";
  const formatPOP1StartDate = startDatePopRe1
    ? dayjs(startDatePopRe1).format(dateFormat)
    : "";
  const formatPOP1EndDate = endDatePopRe1
    ? dayjs(endDatePopRe1).format(dateFormat)
    : "";

  const startTimeFormatRep = reportStartDate
    ? dayjs(reportStartDate).format(timeFormat)
    : "";
  const endTimeFormatRep = reportEndDate
    ? dayjs(reportEndDate).format(timeFormat)
    : "";

  const formatStartDateRep = reportStartDate
    ? dayjs(reportStartDate).format(dateFormat)
    : "";
  const formatEndDateRep = reportEndDate
    ? dayjs(reportEndDate).format(dateFormat)
    : "";

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleClickChart = () => {
    setShowZoomPOPChart(true);
    setShowOverviewchart(false);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const handleToggleTime = () => {
    setShowTime(!showTime);
    // dispatch(setOverviewData({ showTime: !showTime }));
  };
  const handleToggleTimePop = () => {
    setShowTimePop(!showTimePop);
    // dispatch(setOverviewData({ showTimePop: !showTimePop }));
  };

  useEffect(() => {
    if (overviewCOCswitch && activeClassConsumer == "Consumer") {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setStartDateRe(startDate);
      setEndDateRe(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setStartDateRe(null);
      setEndDateRe(null);
    }
  }, [overviewCOCswitch, activeClassConsumer]);

  useEffect(() => {
    if (overviewPOPswitch && activeClassConsumer == "Period") {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;
      setStartDatePopRe(startDate);
      setEndDatePopRe(endDate);
      setStartDatePopRe1(startDate);
      setEndDatePopRe1(endDate);
      // setVmsReportCOCStartDate(startDate);
      // setVmsReportCOCEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setStartDatePopRe(null);
      setEndDatePopRe(null);
      setStartDatePopRe1(null);
      setEndDatePopRe1(null);
    }
  }, [overviewPOPswitch, activeClassConsumer]);

  useEffect(() => {
    if (
      deviceColors &&
      deviceIdRedux &&
      deviceUnitRedux &&
      startDateRe &&
      endDateRe &&
      activeClassConsumer == "Consumer"
    ) {
      handleStoreSeletedData();
    } else {
      setAnalyticsData([]);
      setAnalyticsPieChartData([]);
    }
  }, [
    overviewCOCswitch,
    deviceColors,
    deviceIdRedux,
    deviceUnitRedux,
    startDateRe,
    endDateRe,
    selectTimeRange,
    activeClassConsumer,
    checkedItems,
  ]);
  useEffect(() => {
    getPlantByCompanyData();
  }, []);

  console.log("plantDta", plantData);

  const getPlantByCompanyData = async () => {
    try {
      const params = {
        dashboard: 72,
        company: 117,
      };
      const resp = await getPlant(params);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };

  const handleStoreSeletedData = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      dashboard: 1,
      module_display_name: activeClassConsumer,
      device: deviceIdRedux.length
        ? deviceIdRedux.filter((f) => f.plantId == param.id).length
          ? deviceIdRedux
              .filter((f) => f.plantId == param.id)
              .map((row) => ({
                device_id: row.deviceID, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],
      tag_unit: deviceUnitRedux
        .filter((f) => f.plantId == param.id)
        ?.map((row) => row.tagUnit),

      aggrigation: selectTimeRange,
      is_store: overviewCOCswitch,
      recipe_name: checkedItems,
    };
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (startDatePopRe && endDatePopRe && activeClassConsumer == "Period") {
      handleStoreSeletedDataPOP();
    } else {
      setAnalyticsPOPData([]);
      setAnalyticsPopPieChart([]);
    }
  }, [
    overviewPOPswitch,
    devicePOPColors,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    startDatePopRe,
    endDatePopRe,
    selectPOPTimeRange,
    activeClassConsumer,
    checkedPOPItems,
  ]);

  useEffect(() => {
    if (startDatePopRe1 && endDatePopRe1 && activeClassConsumer == "Period") {
      handleStoreSeletedDataPOP();
    } else {
      setAnalyticsPOP1Data([]);
      setAnalyticsPop1PieChart([]);
    }
  }, [
    overviewPOPswitch,
    devicePOPColors,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    startDatePopRe1,
    endDatePopRe1,
    selectPOPTimeRange,
    activeClassConsumer,
    checkedPOPItems,
  ]);
  const handleStoreSeletedDataPOP = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      dashboard: 1,
      module_display_name: activeClassConsumer,
      device: deviceIdPOPRedux.length
        ? deviceIdPOPRedux.filter((f) => f.plantId == param.id).length
          ? deviceIdPOPRedux
              .filter((f) => f.plantId == param.id)
              .map((row) => ({
                device_id: row.deviceID, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],
      tag_unit: deviceUnitPOPRedux
        .filter((f) => f.plantId == param.id)
        ?.map((row) => row.tagUnit),

      aggrigation: selectPOPTimeRange,
      recipe_name: checkedPOPItems,
    };
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (!overviewCOCswitch) {
      setDeviceColors([]);
      setDeviceIdRedux([]);
      setDeviceUnitRedux([]);
      setSelectTimeRange("1");
      setStartDateRe(null);
      setEndDateRe(null);
      setShowTime(false);
      setDeviceUnitData([]);
      setCheckedItems([]);
      // setLineCOCIntervalTime("")
    }

    if (!overviewPOPswitch) {
      setDeviceIdPOPRedux([]);
      setDeviceUnitPOPData([]);
      setDevicePOPColors([]);
      setDeviceUnitPOPRedux([]);
      setSelectPOPTimeRange("1");
      setStartDatePopRe(null);
      setEndDatePopRe(null);
      setStartDatePopRe1(null);
      setEndDatePopRe1(null);
      setShowTimePop(false);
      // setVmsReportPOPStartDate(null)
    }
  }, [overviewCOCswitch, overviewPOPswitch, activeClassConsumer]);





  const handleChangeData = () => {
    setShowOverviewchart(false);
    setShowZoomChart(true);
  };

  const handlePOPExcel = async () => {
    const findPlantDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        start_date_1: `${formatPOP1StartDate} 00:00:00`,
        end_date_1: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
      };
      if (checkedPOPItems) {
        params["recipe_names"] = checkedPOPItems;
      }

      if (showTime) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
        params[
          "start_date_1"
        ] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date_1"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getExportPOPExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Period Over Period Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  const handleExportExcelClick = async () => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      formatStartDateRep &&
      formatEndDateRep &&
      reportAgg &&
      reportInterval &&
      findPlantDevice.length &&
      findPlantUnit.length
    ) {
      const params = {
        company: param.companyId,
        plant_id: param.id,
        device_id: findPlantDevice[0]["deviceID"],
        tag_unit: findPlantUnit.map((unit) => unit.tagUnit),
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        aggregate: Number(reportAgg),
        intervals: Number(reportInterval),
      };
      if (reportShowTime) {
        params["start_date"] = `${formatStartDateRep} ${startTimeFormatRep}`;
        params["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      try {
        const resp = await getExportAnalyticsReportExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Overview Report Data Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    }
  };
  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const param = useParams();

  useEffect(() => {
    handlegetDevice();
  }, []);

  const handlegetDevice = async () => {
    setisShimmerdevicelist(true);
    setisShimmerUnitlist(true);
    const params = {
      plant_id: param.id,
      is_renewable_energy :is_renewable_energy
      // company_id: param.companyId,
    };
    try {
      const resp = await getOverviewDeviceData(params);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);
        setDeviceData(resp.data.payload.device);
        // setDeviceUnitData(resp.data.payload.tag);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      setisShimmerUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getUnitData1();
  }, [deviceIdRedux]);

  const getUnitData1 = async () => {
    const params = {
      device: deviceIdRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceUnitData(resp.data.payload);
      }
    } catch (error) {
      setDeviceUnitData([]);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getUnitPOPData();
  }, [deviceIdPOPRedux]);

  const getUnitPOPData = async () => {
    const params = {
      device: deviceIdPOPRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceUnitPOPData(resp.data.payload);
      }
    } catch (error) {
      setDeviceUnitPOPData([]);
      console.log("handlesubmit", error);
    }
  };

  // get device In Report
  const handlegetDeviceReport = async () => {
    setisShimmerReportdevicelist(true);
    setisShimmerReportUnitlist(true);
    const params = {
      plant_id: param.id,
      dashboard: 1,
      is_renewable_energy : is_renewable_energy
    };
    try {
      const resp = await getOverviewReportDeviceData(params);
      if (resp.data.success == true) {
        setDeviceReport(resp.data.payload.device);
        // setUnitReport(resp.data.payload.tag);
        setTimeout(() => {
          setisShimmerReportdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerReportUnitlist(false);
        }, 1000);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerReportdevicelist(false);
      setisShimmerReportUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlegetDeviceReport();
  }, []);

  useEffect(() => {
    getReportUnitData();
  }, [deviceIdReportRedux]);

  const getReportUnitData = async () => {
    const params = {
      device: deviceIdReportRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getDeviceUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitReport(resp.data.payload);
      } else {
        setUnitReport([]);
      }
    } catch (error) {
      setUnitReport([]);
      console.log("handlesubmit", error);
    }
  };

  const handleSelectDeviceId = (id, index, row, color) => {
    console.log("color", color);
    setCocDeviceId(id);
    const existingIndex = deviceIdRedux.findIndex(
      (f) => f.deviceID === id && f.plantId === param.id
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...deviceIdRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceIdRedux(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: updateMachineId,
      //   })
      // );

      const findSelectedTagUnit = deviceUnitRedux.length
        ? deviceUnitRedux.filter((f) => f.plantId != param.id)
        : [];

      setDeviceUnitRedux(findSelectedTagUnit);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        deviceID: id,
        dvName: row.device__device_name,
        color: color,
        plantId: param.id,
      };
      // Add the object to the state array if it doesn't exist
      setDeviceIdRedux((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: [
      //       ...VmsReportPOPSelectmachine,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };
  console.log("deviceIdPOPRedux", deviceIdPOPRedux);
  const handlePOPSelectDeviceId = (id, index, row, color) => {
    const existingIndex = deviceIdPOPRedux.findIndex(
      (f) => f.deviceID === id && f.plantId === param.id
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...deviceIdPOPRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceIdPOPRedux(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: updateMachineId,
      //   })
      // );

      const findSelectedTagUnit = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.filter((f) => f.plantId != param.id)
        : [];

      setDeviceUnitPOPRedux(findSelectedTagUnit);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        deviceID: id,
        dvName: row.device__device_name,
        color: color,
        plantId: param.id,
      };
      // Add the object to the state array if it doesn't exist
      setDeviceIdPOPRedux((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: [
      //       ...VmsReportPOPSelectmachine,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitRedux)) {
      setDeviceUnitRedux([]);
      // dispatch(setOverviewData({ deviceUnitRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      setDeviceUnitRedux(
        deviceUnitRedux.filter((unit) => unit.plantId !== param.id)
      );
      // dispatch(
      //   setOverviewData({
      //     deviceUnitRedux: deviceUnitRedux.filter(
      //       (unit) => unit.plantId !== param.id
      //     ),
      //   })
      // );
    } else {
      const selectTag = deviceUnitRedux.length
        ? deviceUnitRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        const newUnit = {
          plantId: param.id,
          tagUnit: id,
        };
        setDeviceUnitRedux((prevState) => [...prevState, newUnit]);
        // dispatch(
        //   setOverviewData({
        //     deviceUnitRedux: [
        //       ...deviceUnitRedux,
        //       { plantId: param.id, tagUnit: id },
        //     ],
        //   })
        // );
      }
    }
  };

  const handlePOPSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitPOPRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitPOPRedux)) {
      setDeviceUnitPOPRedux([]);
      // dispatch(setOverviewData({ deviceUnitPOPRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      setDeviceUnitPOPRedux(
        deviceUnitPOPRedux.filter((unit) => unit.plantId !== param.id)
      );
      // dispatch(
      //   setOverviewData({
      //     deviceUnitPOPRedux: deviceUnitPOPRedux.filter(
      //       (unit) => unit.plantId !== param.id
      //     ),
      //   })
      // );
    } else {
      const selectTag = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        const Unit = {
          plantId: param.id,
          tagUnit: id,
        };
        setDeviceUnitPOPRedux((prevState) => [...prevState, Unit]);

        // dispatch(
        //   setOverviewData({
        //     deviceUnitPOPRedux: [
        //       ...deviceUnitPOPRedux,
        //       { plantId: param.id, tagUnit: id },
        //     ],
        //   })
        // );
      }
    }
  };

  const handleReportInterval = (e) => {
    dispatch(setOverviewData({ reportInterval: e.target.value }));
  };

  const handleAggReport = (e) => {
    dispatch(setOverviewData({ reportAgg: e.target.value }));
  };

  const handleReportSelectUnit11 = (e) => {
    dispatch(setOverviewData({ reportSelectUnit1: e.target.value }));
  };
  const handleReportSelectUnit12 = (e) => {
    dispatch(setOverviewData({ reportSelectUnit2: e.target.value }));
  };

  const handleShowReportToggle = () => {
    dispatch(setOverviewData({ reportShowTime: !reportShowTime }));
  };
  const handleSelectDeviceIdReport = (id, index, event, row) => {
    const isDeviceTurnedOn = event.target.checked;

    if (isDeviceTurnedOn) {
      // Ensure only one device is checked (on) at a time
      // Reset all other devices before turning this one on
      dispatch(
        setOverviewData({
          deviceColorsReportRedux: [],
          deviceIdReportRedux: [],
          deviceUnitReportRedux: [], // Reset all units
          unitColorsReportRedux: [],
          reportSelectUnit1: "none",
          reportSelectUnit2: "none",
        })
      );

      // Device on thyu chhe
      const existingIndex = deviceColorsReportRedux.findIndex(
        (color) => color.deviceId === row.device && color.plantId == param.id
      );

      if (existingIndex !== -1) {
        const updatedColors = [...deviceColorsReportRedux];
        updatedColors.splice(existingIndex, 1);
        dispatch(
          setOverviewData({
            deviceColorsReportRedux: updatedColors,
          })
        );
      } else {
        dispatch(
          setOverviewData({
            deviceColorsReportRedux: [
              ...deviceColorsReportRedux,
              {
                deviceId: row.device,
                dvName: row.device__device_name,
                color: event.target.name,
                plantId: param.id,
              },
            ],
          })
        );
      }

      if (!Array.isArray(deviceIdReportRedux)) {
        dispatch(setOverviewData({ deviceIdReportRedux: [] }));
      }

      const matchingIndex = deviceIdReportRedux.findIndex(
        (f) => f.deviceID === id && f.plantId === param.id
      );

      if (matchingIndex === -1) {
        // Add device to deviceIdReportRedux
        dispatch(
          setOverviewData({
            deviceIdReportRedux: [{ deviceID: id, plantId: param.id }],
          })
        );
      }
    } else {
      // Device off thyu chhe
      const updatedColors = unitColorsReportRedux.filter(
        (color) => color.deviceId !== row.device || color.plantId !== param.id
      );
      dispatch(
        setOverviewData({
          unitColorsReportRedux: updatedColors,
        })
      );

      const updatedDevices = deviceIdReportRedux.filter(
        (f) => f.deviceID !== id || f.plantId !== param.id
      );
      dispatch(
        setOverviewData({
          deviceIdReportRedux: updatedDevices,
        })
      );

      // All units switch off karo
      dispatch(
        setOverviewData({
          deviceUnitReportRedux: [], // Reset all unit switches
        })
      );
    }
  };

  const handleSelectTagUnitReport = (id, event, row) => {
    const existingIndex = unitColorsReportRedux.findIndex(
      (color) => color.tagUnit === row.tag_unit && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...unitColorsReportRedux];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          unitColorsReportRedux: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          unitColorsReportRedux: [
            ...unitColorsReportRedux,
            {
              tagUnit: row.tag_unit,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceUnitReportRedux)) {
      setDeviceId([]);
      dispatch(setOverviewData({ deviceUnitReportRedux: [] }));
    }
    const matchingIndex = deviceUnitReportRedux.findIndex(
      (f) => f.tagUnit === id && f.plantId === param.id
    );

    if (matchingIndex !== -1) {
      // Matching element found, remove it
      console.log("Match found:", deviceUnitReportRedux[matchingIndex]);

      const updatedArray = [...deviceUnitReportRedux];
      updatedArray.splice(matchingIndex, 1); // Remove the matching element
      dispatch(
        setOverviewData({
          deviceUnitReportRedux: updatedArray,
        })
      );
    } else {
      // No match found, add the new object
      console.log("No match found");

      dispatch(
        setOverviewData({
          deviceUnitReportRedux: [
            ...deviceUnitReportRedux,
            { tagUnit: id, plantId: param.id, color: event.target.name },
          ],
        })
      );
    }
    dispatch(setOverviewData({ reportSelectUnit1: "none" }));
    dispatch(setOverviewData({ reportSelectUnit2: "none" }));
  };

  console.log("reportSelectUnit1", reportSelectUnit1);

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsData();
    } else {
      setAnalyticsData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    selectTimeRange,
    startTimeFormat,
    endTimeFormat,
    showTime,
    selectUnit,
    checkedItems,
  ]);

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsPieChartData();
    } else {
      setAnalyticsPieChartData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    showTime,
    startTimeFormat,
    endTimeFormat,
    selectUnit,
  ]);

  const handleAnalyticsData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      setisShimmerCOCParetoChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        // start_time: startTimeFormat !== "00:00:00" ? startTimeFormat : "",
        // end_time: endTimeFormat !== "00:00:00" ? endTimeFormat : "",
        aggregate: Number(selectTimeRange),
        unit: selectUnit,
        recipe_names: checkedItems,
      };

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        setLoading(true);

        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setLoading(false);
          setTimeout(() => {
            setisShimmerCOCParetoChart(false);
          }, 2000);

          setAnalyticsData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerCOCParetoChart(false);
        setLoading(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart();
      console.log("firstAPICall");
    } else {
      setAnalyticsPOPData([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    startPOPTimeFormat,
    endPOPTimeFormat,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
    checkedPOPItems,
  ]);

  const handleAnalyticsPOPBarChart = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantByUnit &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
        recipe_names: checkedPOPItems,
      };

      if (showTimePop) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
      }
      try {
        setLoadingPop(true);
        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setTimeout(() => setisShimmerPopgroupBarChartPOP(false), 2000);

          setLoadingPop(false);
          setAnalyticsPOPData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        setLoadingPop(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart2();
    } else {
      setAnalyticsPOP1Data([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
    checkedPOPItems,
  ]);

  const handleAnalyticsPOPBarChart2 = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantByDevice &&
      deviceUnitPOPRedux &&
      formatPOP1StartDate &&
      formatPOP1EndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOP1StartDate} 00:00:00`,
        end_date: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
        recipe_names: checkedPOPItems,
      };

      console.log("params..", params);
      if (showTimePop) {
        params["start_date"] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setTimeout(() => {
            setisShimmerPopgroupBarChartPOP(false);
          }, 2000);
          setAnalyticsPOP1Data(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  const handleAnalyticsPieChartData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate
    ) {
      setisShimmerCOCDonutChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatStartDate,
        end_date: formatEndDate,
        unit: selectUnit,
      };
      try {
        setLoading1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(() => {
            setisShimmerCOCDonutChart(false);
          }, 2000);

          setLoading1(false);
          setAnalyticsPieChartData(resp.data.payload);
          setDonutChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setTimeout(() => {
          setisShimmerCOCDonutChart(false);
        }, 2000);
        setLoading1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantUnit.length) {
      handleAnalyticsPOPpieChartData();
    } else {
      setAnalyticsPopPieChart([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOPpieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      deviceUnitPOPRedux[0] &&
      formatPOPStartDate &&
      formatPOPEndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOPStartDate,
        end_date: formatPOPEndDate,
        unit: selectUnit,
      };
      try {
        setLoadingPop1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setLoadingPop1(false);
          setAnalyticsPopPieChart(resp.data.payload);
          setDonutPOPChartSum(resp.data.tag_value_sum);
          setDonutPOPUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        setLoadingPop1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOP1pieChartData();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOP1pieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantUnit[0] &&
      formatPOP1StartDate &&
      formatPOP1EndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOP1StartDate,
        end_date: formatPOP1EndDate,
        unit: selectUnit,
      };
      try {
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setAnalyticsPop1PieChart(resp.data.payload);
          setDonut1POPChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };
  useEffect(() => {
    getOverviewReportApi();
  }, [
    formatStartDateRep,
    formatEndDateRep,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    selectUnit,
    startTimeFormatRep,
    endTimeFormatRep,
  ]);

  // report Table data
  const getOverviewReportApi = async () => {
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatStartDateRep &&
      formatEndDateRep
    ) {
      const paramRequset = {
        device_id: findPlantDevice[0]["deviceID"],
        tag_units: findPlantUnit.map((unit) => unit.tagUnit),
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        unit: selectUnit,
      };
      if (reportShowTime) {
        paramRequset[
          "start_date"
        ] = `${formatStartDateRep} ${startTimeFormatRep}`;
        paramRequset["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      try {
        const resp = await getOverviewReportData(paramRequset);

        if (resp.data.success == true) {
          setReportOverViewData(resp.data.payload);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleExportEnergyData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        aggregate: Number(selectTimeRange),
      };
      if (checkedItems) {
        params["recipe_names"] = checkedItems;
      }

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        const resp = await exportAnalyticsCOCExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Consumer Over Consumer Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (!findPlantDevice.length || !findPlantUnit.length) {
      setReportOverViewData([]);
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);
    }
  }, [deviceIdReportRedux, deviceUnitReportRedux]);

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDateRe(null);
      // dispatch(setOverviewData({ startDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      setStartDateRe(newValue);
      // dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (!endDateReFormat) {
      setStartDateRe(newValue);
      // dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() > endDateReFormat.hour() ||
          (newValue.hour() === endDateReFormat.hour() &&
            newValue.minute() >= endDateReFormat.minute()))
      ) {
        setStartDateRe(null);
        // dispatch(setOverviewData({ startDateRe: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDateRe(newValue);
        // dispatch(setOverviewData({ startDateRe: newValue }));
      }
    } else {
      setStartDateRe(null);
      // dispatch(setOverviewData({ startDateRe: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDateRe(null);
      // dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      setEndDateRe(newValue);
      // dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (!startDateReFormat) {
      setEndDateRe(newValue);
      // dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() < startDateReFormat.hour() ||
          (newValue.hour() === startDateReFormat.hour() &&
            newValue.minute() <= startDateReFormat.minute()))
      ) {
        setEndDateRe(null);
        // dispatch(setOverviewData({ endDateRe: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDateRe(newValue);
        // dispatch(setOverviewData({ endDateRe: newValue }));
      }
    } else {
      setEndDateRe(null);
      // dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPReFormat
      ? endDatePOPReFormat.diff(newValue, "days")
        ? endDatePOPReFormat.diff(newValue, "days") > 0
          ? endDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDatePopRe(null);
      // dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPReFormat && newValue.isBefore(endDatePOPReFormat)) {
      setStartDatePopRe(newValue);
      // dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (!endDatePOPReFormat) {
      setStartDatePopRe(newValue);
      // dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (
      endDatePOPReFormat &&
      newValue.isSame(endDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOPReFormat.hour() ||
          (newValue.hour() === endDatePOPReFormat.hour() &&
            newValue.minute() >= endDatePOPReFormat.minute()))
      ) {
        setStartDatePopRe(null);
        // dispatch(setOverviewData({ startDatePopRe: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDatePopRe(newValue);
        // dispatch(setOverviewData({ startDatePopRe: newValue }));
      }
    } else {
      setStartDatePopRe(null);
      // dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setOverviewData({ startDatePopRe: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPReFormat
      ? startDatePOPReFormat.diff(newValue, "days")
        ? startDatePOPReFormat.diff(newValue, "days") > 0
          ? startDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDatePopRe(null);
      // dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPReFormat && newValue.isAfter(startDatePOPReFormat)) {
      setEndDatePopRe(newValue);
      // dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (!startDatePOPReFormat) {
      setEndDatePopRe(newValue);
      // dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (
      startDatePOPReFormat &&
      newValue.isSame(startDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOPReFormat.hour() ||
          (newValue.hour() === startDatePOPReFormat.hour() &&
            newValue.minute() <= startDatePOPReFormat.minute()))
      ) {
        setEndDatePopRe(null);
        // dispatch(setOverviewData({ endDatePopRe: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDatePopRe(newValue);
        // dispatch(setOverviewData({ endDatePopRe: newValue }));
      }
    } else {
      setEndDatePopRe(null);
      // dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1ReFormat
      ? endDatePOP1ReFormat.diff(newValue, "days")
        ? endDatePOP1ReFormat.diff(newValue, "days") > 0
          ? endDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDatePopRe1(null);
      // dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1ReFormat && newValue.isBefore(endDatePOP1ReFormat)) {
      setStartDatePopRe1(newValue);
      // dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (!endDatePOP1ReFormat) {
      setStartDatePopRe1(newValue);
      // dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (
      endDatePOP1ReFormat &&
      newValue.isSame(endDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOP1ReFormat.hour() ||
          (newValue.hour() === endDatePOP1ReFormat.hour() &&
            newValue.minute() >= endDatePOP1ReFormat.minute()))
      ) {
        setStartDatePopRe1(null);
        // dispatch(setOverviewData({ startDatePopRe1: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDatePopRe1(newValue);
        // dispatch(setOverviewData({ startDatePopRe1: newValue }));
      }
    } else {
      setStartDatePopRe1(null);
      // dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1ReFormat
      ? startDatePOP1ReFormat.diff(newValue, "days")
        ? startDatePOP1ReFormat.diff(newValue, "days") > 0
          ? startDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDatePopRe1(null);
      // dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1ReFormat && newValue.isAfter(startDatePOP1ReFormat)) {
      setEndDatePopRe1(newValue);
      // dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (!startDatePOP1ReFormat) {
      setEndDatePopRe1(newValue);
      // dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (
      startDatePOP1ReFormat &&
      newValue.isSame(startDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOP1ReFormat.hour() ||
          (newValue.hour() === startDatePOP1ReFormat.hour() &&
            newValue.minute() <= startDatePOP1ReFormat.minute()))
      ) {
        setEndDatePopRe1(null);
        // dispatch(setOverviewData({ endDatePopRe1: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDatePopRe1(newValue);
        // dispatch(setOverviewData({ endDatePopRe1: newValue }));
      }
    } else {
      setEndDatePopRe1(null);
      // dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handleReportsReportStartDate = (newValue) => {
    const durationInDays = endDateReportFormat
      ? endDateReportFormat.diff(newValue, "days")
        ? endDateReportFormat.diff(newValue, "days") > 0
          ? endDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReportFormat && newValue.isBefore(endDateReportFormat)) {
      dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (!endDateReportFormat) {
      dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (
      endDateReportFormat &&
      newValue.isSame(endDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() > endDateReportFormat.hour() ||
          (newValue.hour() === endDateReportFormat.hour() &&
            newValue.minute() >= endDateReportFormat.minute()))
      ) {
        dispatch(setOverviewData({ reportStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ reportStartDate: newValue }));
      }
    } else {
      dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleReportsReportEndDate = (newValue) => {
    const durationInDays = startDateReportFormat
      ? startDateReportFormat.diff(newValue, "days")
        ? startDateReportFormat.diff(newValue, "days") > 0
          ? startDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDateReportFormat: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReportFormat && newValue.isAfter(startDateReportFormat)) {
      dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (!startDateReportFormat) {
      dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (
      startDateReportFormat &&
      newValue.isSame(startDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() < startDateReportFormat.hour() ||
          (newValue.hour() === startDateReportFormat.hour() &&
            newValue.minute() <= startDateReportFormat.minute()))
      ) {
        dispatch(setOverviewData({ reportEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ reportEndDate: newValue }));
      }
    } else {
      dispatch(setOverviewData({ reportEndDate: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest(".filter-img-class")
    ) {
      setOpenEnergyModal(false);
    }
  };

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
  };



  console.log("receipe", receipeData);

  const handleCustomizedModal = () => {
    setCustomizedReportModal(!customizedReportModal);
  };

  const customeReportAPI = async () => {
    const params = {
      report_type: selectedPlant,
      time: selectDateTime,
    };
    try {
      const resp = await customeReportData(params);
      if (resp.status == 200 || resp.status == 201) {
        setCustomData(resp.data.payload);
        notify(resp.data.message);
        setCustomizedReportModal(false);
        setSelectedTime(null);
        setSelectedPlant(null);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setCustomizedReportModal(false);
      setSelectedTime(null);
      setSelectedPlant(null);
      console.log("handlesubmit", error);
    }
  };

  const handleCheckboxChange = (plantId) => {
    setSelectedPlant(plantId === selectedPlant ? null : plantId);
  };
  return (
    <>
      <OverViewAnalyticsReportApiData
        showTimeReport={reportShowTime}
        setFisrtLineChartReportData={setFisrtLineChartReportData}
        setSecondLineChartReportData={setSecondLineChartReportData}
        selectFirstUnit={reportSelectUnit1}
        selectAggReport={reportAgg}
        selectIntervalReport={reportInterval}
        deviceIdReportRedux={deviceIdReportRedux}
        selectSecondUnit={reportSelectUnit2}
        startTimeFormatRep={startTimeFormatRep}
        endTimeFormatRep={endTimeFormatRep}
        formatStartDateRep={formatStartDateRep}
        formatEndDateRep={formatEndDateRep}
        setLoadingReport={setLoadingReport}
        setisShimmerMultiAxisLineChartReports={
          setisShimmerMultiAxisLineChartReports
        }
      />
      {showOverViewChart ? (
        <>
          <Container maxWidth="xxl" className="pt-4">
            {/* <div className="page-wraper"> */}
            <div className="page-header page-header-analytics">
              {activeClass == "Analytics" ? (
                <div
                  onClick={() =>
                    navigate(`/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`)
                  }
                  className="page-back-btn"
                >
                  <ArrowBackIcon />
                  <span>Reports</span>
                </div>
              ) : (
                <div
                  onClick={() =>
                    navigate(`/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`)
                  }
                  className="page-back-btn"
                >
                  <ArrowBackIcon />
                  <span>Report</span>
                </div>
              )}
             
            </div>
          
              <>
                <div className="d-flex justify-content-end pt-3">
                  <div className="analyticsAgg-datePicker-overview">
                    <div
                      class="overview-report-wrap"
                      style={{ flexWrap: "wrap" }}
                    >
                      <div>
                        <select
                          className="compact-btn mb-0"
                          value={selectUnit}
                          onChange={(e) =>
                            dispatch(
                              setProcessDashboarDateSet({
                                selectUnit: e.target.value,
                              })
                            )
                          }
                        >
                          <option value="k">Kilo</option>
                          <option value="m">Mega</option>
                          <option value="g">Giga</option>
                        </select>
                      </div>
                      <div className="overview-report-time">
                        <h6 className="AGG-dropdown">Interval</h6>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <>
                            <Box style={{ float: "right" }}>
                              <select
                                className="form-input-class"
                                style={{
                                  padding: "8px",
                                  fontSize: "14px",
                                  borderRadius: "4px",
                                }}
                                id="selectedClass"
                                value={reportInterval}
                                onChange={handleReportInterval}
                                required
                              >
                                <option value="1" selected disabled hidden>
                                  1 min
                                </option>
                                <option value="1">1 Min</option>
                                <option value="15">15 Min</option>
                                <option value="30">30 Min</option>
                                <option value="60">Hourly</option>
                                <option value="24">Day</option>
                                <option value="7">Weekly</option>
                              </select>
                            </Box>
                          </>
                        ) : (
                          <Box style={{ float: "right" }}>
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              // value={selectIntervalReport}
                              onChange={handleTostMsg}
                              required
                            >
                              <option value="1" selected disabled hidden>
                                1 min
                              </option>
                              <option value="1">1 Min</option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="24">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        )}
                      </div>
                      <div className="overview-report-agg">
                        <h6 className="AGG-dropdown">AGG.</h6>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ float: "right" }}
                          >
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              value={reportAgg}
                              onChange={handleAggReport}
                              // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                              required
                            >
                              <option value="15" selected disabled hidden>
                                15 Min
                              </option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="1">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        ) : (
                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ float: "right" }}
                          >
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              // value={selectAggReport}
                              onChange={handleTostMsg}
                              // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                              required
                            >
                              <option value="15" selected disabled hidden>
                                15 Min
                              </option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="1">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        )}
                      </div>
                    </div>

                    <div className="overview-time-wrap">
                      <div>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="top"
                              control={
                                <Switch
                                  color="primary"
                                  checked={reportShowTime}
                                />
                              }
                              label="Time"
                              labelPlacement="top"
                              onChange={handleShowReportToggle}
                              className="overview-switch"
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="top"
                              control={<Switch color="primary" />}
                              label="Time"
                              labelPlacement="top"
                              onChange={handleTostMsg}
                              className="overview-switch"
                            />
                          </FormGroup>
                        )}
                      </div>
                      <div className="overview-date-picker-wrap">
                        {/* <div> */}
                        {reportShowTime ? (
                          <>
                            {(ReportAccess?.is_viewer &&
                              ReportAccess?.is_editor) ||
                            (!ReportAccess?.is_viewer &&
                              ReportAccess?.is_editor) ||
                            (ReportAccess?.is_viewer &&
                              !ReportAccess?.is_editor) ? (
                              <>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      label="Start Date"
                                      value={startDateReportFormat}
                                      onChange={(newValue) =>
                                        handleReportsReportStartDate(newValue)
                                      }
                                      disableFuture
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      className="custom-datepicker"
                                    />
                                  </LocalizationProvider>
                                </Box>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      label="End Date"
                                      value={endDateReportFormat}
                                      disableFuture
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      onChange={(newValue) =>
                                        handleReportsReportEndDate(newValue)
                                      }
                                      className="custom-datepicker"
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      label="Start Date"
                                      // value={startDateRep}
                                      onChange={handleTostMsg}
                                      disableFuture
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      className="custom-datepicker"
                                    />
                                  </LocalizationProvider>
                                </Box>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      label="End Date"
                                      // value={endDateRep}
                                      disableFuture
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      onChange={handleTostMsg}
                                      className="custom-datepicker"
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {(ReportAccess?.is_viewer &&
                              ReportAccess?.is_editor) ||
                            (!ReportAccess?.is_viewer &&
                              ReportAccess?.is_editor) ||
                            (ReportAccess?.is_viewer &&
                              !ReportAccess?.is_editor) ? (
                              <>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="custom-datepicker"
                                      label="Start Date"
                                      value={startDateReportFormat}
                                      inputFormat="DD/MM/YYYY"
                                      onChange={(newValue) => {
                                        handleReportsReportStartDate(newValue);
                                      }}
                                      disableFuture
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ background: "#ffff" }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                                <Box
                                  className=""
                                  // style={{ width: "30%" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="custom-datepicker"
                                      label="End Date"
                                      value={endDateReportFormat}
                                      inputFormat="DD/MM/YYYY"
                                      onChange={(newValue) => {
                                        handleReportsReportEndDate(newValue);
                                      }}
                                      disableFuture
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ background: "#ffff" }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box className="">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="custom-datepicker"
                                      label="Start Date"
                                      // value={startDateRep}
                                      inputFormat="DD/MM/YYYY"
                                      onChange={handleTostMsg}
                                      disableFuture
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ background: "#ffff" }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                                <Box
                                  className=""
                                  // style={{ width: "30%" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="custom-datepicker"
                                      label="End Date"
                                      // value={endDateRep}
                                      inputFormat="DD/MM/YYYY"
                                      onChange={handleTostMsg}
                                      disableFuture
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ background: "#ffff" }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                        {/* </div> */}
                      </div>
                      <div onClick={handleCustomizedModal}>
                        <img
                          src={images.excelLogo}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the event from propagating to the document
                          handleOpenEnergyModal();
                        }}
                        className="filter-img-class"
                      >
                        <img
                          src={images.filterImg}
                          alt=""
                          style={{ height: "40px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-2 consumption-grid-card">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        className="consumerData-box consumer-box"
                        style={{ minHeight: "761px" }}
                      >
                        <Box className="consumer-text-disc">
                          <h6 className="Title">Title</h6>
                          <h6>Energy Meter Charts</h6>
                        </Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Device here"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />
                        <div className="furnace-grid">
                          <Box className="Grid-text mt-2">
                            {!isShimmerReportdevicelist ? (
                              deviceReport.length ? (
                                deviceReport
                                  .filter((e) =>
                                    e.device__device_name
                                      ?.toLowerCase()
                                      ?.includes(searchQuery.toLowerCase())
                                  )
                                  .map((e, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      key={e.device__device_name}
                                    >
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6
                                            className="mt-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {e.device__device_name}
                                          </h6>
                                        </Grid>

                                        {(ReportAccess?.is_viewer &&
                                          ReportAccess?.is_editor) ||
                                        (!ReportAccess?.is_viewer &&
                                          ReportAccess?.is_editor) ||
                                        (ReportAccess?.is_viewer &&
                                          !ReportAccess?.is_editor) ? (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={(event) =>
                                                handleSelectDeviceIdReport(
                                                  e.device,
                                                  index,
                                                  event,
                                                  e
                                                )
                                              }
                                              name={switchColors[index]}
                                              // checked={deviceIdReportRedux.includes(
                                              //   e.device
                                              // )}
                                              checked={
                                                deviceIdReportRedux.length
                                                  ? deviceIdReportRedux.find(
                                                      (f) =>
                                                        f.deviceID ==
                                                          e.device &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={handleTostMsg}
                                              name={switchColors[index]}
                                              // checked={deviceIdReportRedux.includes(
                                              //   e.device
                                              // )}
                                              checked={
                                                deviceIdReportRedux.length
                                                  ? deviceIdReportRedux.find(
                                                      (f) =>
                                                        f.deviceID ==
                                                          e.device &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))
                              ) : (
                                []
                              )
                            ) : (
                              <DeviceListShimmer itemCount={3} />
                            )}
                          </Box>
                        </div>
                        <Box className="consumer-text-disc mt-2">
                          {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                        </Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Unit here"
                          value={unitSearchQuery}
                          onChange={handleUnitSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div style={{ height: "272px", overflow: "auto" }}>
                          {(ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (!ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (ReportAccess?.is_viewer &&
                            !ReportAccess?.is_editor) ? (
                            <Box className="Grid-text1 mt-2">
                              {!isShimmerReportUnitlist ? (
                                unitReport
                                  .filter(
                                    (e) =>
                                      e.tag_unit &&
                                      e.tag_unit
                                        .toLowerCase()
                                        .includes(unitSearchQuery.toLowerCase())
                                  )
                                  .map((e, index) => (
                                    <Grid item xs={12} key={e.tag_unit}>
                                      {e.tag_unit && (
                                        <Grid
                                          container
                                          alignItems="center"
                                          style={{
                                            background: "#efeffd",
                                            width: "100%",
                                            marginBottom: "7px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            className="mt-2"
                                            style={{ padding: "7px 12px" }}
                                          >
                                            <h6>{e.tag_unit}</h6>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitReportRedux.length
                                                  ? deviceUnitReportRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              name={switchColors[index]}
                                              style={{ float: "right" }}
                                              onClick={(event) =>
                                                handleSelectTagUnitReport(
                                                  e.tag_unit,
                                                  event,
                                                  e
                                                )
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))
                              ) : (
                                <DeviceListShimmer itemCount={3} />
                              )}
                            </Box>
                          ) : (
                            <Box className="Grid-text1 mt-2">
                              {!isShimmerReportUnitlist ? (
                                unitReport
                                  .filter(
                                    (e) =>
                                      e.tag_unit &&
                                      e.tag_unit
                                        .toLowerCase()
                                        .includes(unitSearchQuery.toLowerCase())
                                  )
                                  .map((e, index) => (
                                    <Grid item xs={12} key={e.tag_unit}>
                                      {e.tag_unit && (
                                        <Grid
                                          container
                                          alignItems="center"
                                          style={{
                                            background: "#efeffd",
                                            width: "100%",
                                            marginBottom: "7px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            className="mt-2"
                                            style={{ padding: "7px 12px" }}
                                          >
                                            <h6>{e.tag_unit}</h6>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitReportRedux.length
                                                  ? deviceUnitReportRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              name={switchColors[index]}
                                              style={{ float: "right" }}
                                              onClick={handleTostMsg}
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))
                              ) : (
                                <DeviceListShimmer itemCount={3} />
                              )}
                            </Box>
                          )}
                        </div>

                        <div className="mt-3">
                          <button
                            className="export-btn"
                            onClick={() => handleExportExcelClick()}
                          >
                            EXPORT ENERGY DATA
                          </button>
                        </div>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      className="consumption-table-box"
                    >
                      <Box
                        className="consumerData-box"
                        style={{ padding: "0px" }}
                      >
                        <div style={{ minHeight: "400px" }}>
                          <MultiAxisLineChart
                            fisrtLineChartReportData={fisrtLineChartReportData}
                            secondeLineChartReportData={
                              secondeLineChartReportData
                            }
                            selectFirstUnit={reportSelectUnit1}
                            selectSecondUnit={reportSelectUnit2}
                            Shimmer={isShimmerMultiAxisLineChartReports}
                            selectFirst={CompactTagUnit(
                              selectUnit,
                              reportSelectUnit1
                            )}
                            selectSecond={CompactTagUnit(
                              selectUnit,
                              reportSelectUnit2
                            )}
                            selectAggReport={reportAgg}
                            selectIntervalReport={reportInterval}
                            unitColorsReportRedux={unitColorsReportRedux}
                          />
                        </div>
                        <div className="d-flex justify-content-between m-3">
                          <select
                            className="form-input-class"
                            style={{
                              padding: "0px 14px",
                              borderRadius: "4px",
                              height: "40px",
                            }}
                            id="selectedClass"
                            value={reportSelectUnit1}
                            onChange={handleReportSelectUnit11}
                            required
                          >
                            <option value="none" selected hidden>
                              Select Unit
                            </option>
                            {deviceUnitReportRedux
                              ? deviceUnitReportRedux
                                  .filter((f) => f.plantId == param.id)
                                  .map((e) => (
                                    <option value={e.tagUnit}>
                                      {e.tagUnit}
                                    </option>
                                  ))
                              : []}
                          </select>

                          <select
                            className="form-input-class"
                            style={{
                              padding: "0px 14px",
                              borderRadius: "4px",
                              height: "40px",
                            }}
                            id="selectedClass"
                            value={reportSelectUnit2}
                            onChange={handleReportSelectUnit12}
                            required
                          >
                            <option value="none" selected hidden>
                              Select Unit
                            </option>
                            {deviceUnitReportRedux
                              ? deviceUnitReportRedux
                                  .filter((f) => f.plantId == param.id)
                                  .map((e) => (
                                    <option value={e.tagUnit}>
                                      {e.tagUnit}
                                    </option>
                                  ))
                              : []}
                          </select>
                        </div>
                        <div className="report-overview-table">
                          <Table className="source-table">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>

                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue = row.tag_unit;
                                      return (
                                        <TableCell>{formattedValue}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Min</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue1 = row.min_value
                                        ? parseFloat(row.min_value).toFixed(2)
                                        : 0.0;
                                      return (
                                        <TableCell>{formattedValue1}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                              <TableRow>
                                <TableCell>Max</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue2 = row.max_value
                                        ? parseFloat(row.max_value).toFixed(2)
                                        : 0.0;
                                      return (
                                        <TableCell>{formattedValue2}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                              <TableRow>
                                <TableCell>Mean</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue3 = row.mean_value
                                        ? parseFloat(row.mean_value).toFixed(2)
                                        : 0.0;
                                      return (
                                        <TableCell>{formattedValue3}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                              <TableRow>
                                <TableCell>Std. Dev</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue4 = row.std_dev_value
                                        ? parseFloat(row.std_dev_value).toFixed(
                                            2
                                          )
                                        : 0.0;
                                      return (
                                        <TableCell>{formattedValue4}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                              <TableRow>
                                <TableCell>All Time Min</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue5 =
                                        row.all_time_min_value
                                          ? parseFloat(
                                              row.all_time_min_value
                                            ).toFixed(2)
                                          : 0.0;
                                      return (
                                        <TableCell>{formattedValue5}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                              <TableRow>
                                <TableCell>All Time Max</TableCell>
                                {reportOverViewData.length
                                  ? reportOverViewData.map((row) => {
                                      const formattedValue6 =
                                        row.all_time_max_value
                                          ? parseFloat(
                                              row.all_time_max_value
                                            ).toFixed(2)
                                          : 0.0;
                                      return (
                                        <TableCell>{formattedValue6}</TableCell>
                                      );
                                    })
                                  : []}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>{" "}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
        
            {openEnergyModal &&
              (activeClassConsumer == "Consumer" ? (
                <>
                  <div className="consumerCard" ref={modalRef}>
                    <Box className="consumerData-box consumer-card-box">
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Title</h6>
                        <h6>Energy Meter Charts</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div className="furnace-grid">
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    ?.toLowerCase()
                                    ?.includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6
                                          className="mt-2"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectDeviceId(
                                                e.device,
                                                index,
                                                e,
                                                e.target.name
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              deviceIdRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            checked={
                                              deviceIdRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>
                      <Box className="consumer-text-disc mt-2"></Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Unit here"
                        value={unitSearchQuery}
                        onChange={handleUnitSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />

                      <div className="device-unit">
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            checked={
                                              deviceUnitRedux.length
                                                ? deviceUnitRedux.find(
                                                    (f) =>
                                                      f.tagUnit == e.tag_unit &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              handleSelectTagUnit(e.tag_unit)
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#50C878",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#CEE6E1 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            checked={
                                              deviceUnitRedux.length
                                                ? deviceUnitRedux.find(
                                                    (f) =>
                                                      f.tagUnit == e.tag_unit &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            style={{ float: "right" }}
                                            onClick={() => handleTostMsg}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#50C878",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#CEE6E1 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                          onClick={handleExportEnergyData}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </div>
                </>
              ) : (
                <div className="consumerCard" ref={modalRef}>
                  <Box className="consumerData-box consumer-card-box">
                    <Box className="consumer-text-disc">
                      <h6 className="Title">Title</h6>
                      <h6>Energy Meter Charts</h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e.device__device_name
                                  ?.toLowerCase()
                                  ?.includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6
                                        className="mt-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        {e.device__device_name}
                                      </h6>
                                    </Grid>
                                    {(analyticsAccess?.is_viewer &&
                                      analyticsAccess?.is_editor) ||
                                    (!analyticsAccess?.is_viewer &&
                                      analyticsAccess?.is_editor) ||
                                    (analyticsAccess?.is_viewer &&
                                      !analyticsAccess?.is_editor) ? (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handlePOPSelectDeviceId(
                                              e.device,
                                              index,
                                              e,
                                              event.target.name
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={
                                            deviceIdPOPRedux.length
                                              ? deviceIdPOPRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          checked={
                                            deviceIdPOPRedux.length
                                              ? deviceIdPOPRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>
                    <Box className="consumer-text-disc mt-2"></Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div className="device-unit">
                      {(analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (analyticsAccess?.is_viewer &&
                        !analyticsAccess?.is_editor) ? (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitPOPRedux.length
                                              ? deviceUnitPOPRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          style={{ float: "right" }}
                                          onClick={() =>
                                            handlePOPSelectTagUnit(e.tag_unit)
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#50C878",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#CEE6E1 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitPOPRedux.length
                                              ? deviceUnitPOPRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#50C878",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#CEE6E1 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>

                    <div className="mt-3">
                      <button
                        className="export-btn"
                        style={{ marginTop: "46px" }}
                        onClick={handlePOPExcel}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </div>
              ))}

            {openEnergyModal && activeClass == "Reports" && (
              <div className="consumerCard" ref={modalRef}>
                <Box
                  className="consumerData-box consumer-card-box"
                  style={{ minHeight: "761px" }}
                >
                  <Box className="consumer-text-disc">
                    <h6 className="Title">Title</h6>
                    <h6>Energy Meter Charts</h6>
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Device here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    <Box className="Grid-text mt-2">
                      {!isShimmerReportdevicelist ? (
                        deviceReport.length ? (
                          deviceReport
                            .filter((e) =>
                              e.device__device_name
                                ?.toLowerCase()
                                ?.includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        switchColors[
                                          index % switchColors.length
                                        ]
                                      }`,
                                    }}
                                  >
                                    <h6
                                      className="mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>

                                  {(ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (!ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (ReportAccess?.is_viewer &&
                                    !ReportAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectDeviceIdReport(
                                            e.device,
                                            index,
                                            event,
                                            e
                                          )
                                        }
                                        name={switchColors[index]}
                                        // checked={deviceIdReportRedux.includes(
                                        //   e.device
                                        // )}
                                        checked={
                                          deviceIdReportRedux.length
                                            ? deviceIdReportRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        name={switchColors[index]}
                                        // checked={deviceIdReportRedux.includes(
                                        //   e.device
                                        // )}
                                        checked={
                                          deviceIdReportRedux.length
                                            ? deviceIdReportRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            ))
                        ) : (
                          []
                        )
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>
                  <Box className="consumer-text-disc mt-2">
                    {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Unit here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit">
                    {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerReportUnitlist ? (
                          unitReport
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitReportRedux.length
                                            ? deviceUnitReportRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        name={switchColors[index]}
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectTagUnitReport(
                                            e.tag_unit,
                                            event,
                                            e
                                          )
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    ) : (
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerReportUnitlist ? (
                          unitReport
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitReportRedux.length
                                            ? deviceUnitReportRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        name={switchColors[index]}
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    )}
                  </div>

                  <div className="mt-3">
                    <button
                      className="export-btn"
                      style={{ marginTop: "117px" }}
                      onClick={() => handleExportExcelClick()}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </div>
            )}
          </Container>
        </>
      ) : (
        <>
          {showZoomChart ? (
            <ZoomInChart
              setShowOverviewchart={setShowOverviewchart}
              setShowZoomChart={setShowZoomChart}
              startDate={formatStartDate}
              endDate={formatEndDate}
              deviceIdRedux={deviceIdRedux}
              deviceUnitRedux={deviceUnitRedux}
              showTime={showTime}
              startTime={startTimeFormat}
              endTime={endTimeFormat}
              deviceColors={deviceColors}
              startDateReFormat={startDateReFormat}
              endDateReFormat={endDateReFormat}
              is_renewable_energy={is_renewable_energy}
              
            />
          ) : null}

          {showZoomPOPChart ? (
            <ZoomInPOPChart
              setShowOverviewchart={setShowOverviewchart}
              setShowZoomPOPChart={setShowZoomPOPChart}
              startDate={formatPOPStartDate}
              endDate={formatPOPEndDate}
              deviceIdPOPRedux={deviceIdPOPRedux}
              deviceUnitPOPRedux={deviceUnitPOPRedux}
              showTimePop={showTimePop}
              startTime={startPOPTimeFormat}
              endTime={endPOPTimeFormat}
              devicePOPColors={devicePOPColors}
              startDatePOPReFormat={startDatePOPReFormat}
              endDatePOPReFormat={endDatePOPReFormat}
              startDate1={formatPOP1StartDate}
              endDate1={formatPOP1EndDate}
              startDatePOP1ReFormat={startDatePOP1ReFormat}
              endDatePOP1ReFormat={endDatePOP1ReFormat}
              startTime1={startPOP1TimeFormat}
              endTime1={endPOP1TimeFormat}
              is_renewable_energy={is_renewable_energy}
            />
          ) : null}
        </>
      )}
      {/* </div> */}
      {customizedReportModal && (
        <Card className="customized-report-modal">
          <Box className="addDeviceModal-titleWrap">
            <Typography
              variant=""
              gutterBottom
              className="customizedModal-title"
            >
              On Demand Customized Report
            </Typography>
            <img
              src={images.closeIcon}
              alt=""
              className="customized-report-closeImg"
              onClick={() => handleCustomizedModal()}
            />
          </Box>
          <div className="customizedModal-data">
            <div className="customizedModal-border">
              <div className="customizedModal-text">Customized Report</div>
              <div>
                <FormGroup>
                  {plantData.length
                    ? plantData.map((e) => {
                        return (
                          <>
                            <FormControlLabel
                              key={e.id}
                              control={
                                <Checkbox
                                  checked={selectedPlant === e.id}
                                  onChange={() => handleCheckboxChange(e.id)}
                                  disabled={
                                    selectedPlant && selectedPlant !== e.id
                                  }
                                />
                              }
                              label={e.plant_name}
                            />
                          </>
                        );
                      })
                    : []}
                </FormGroup>
              </div>
            </div>
            <div
              className="customizedModal-timepicker"
              style={{ marginLeft: "20px" }}
            >
              <div className="customizedModal-text">Time</div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="With Time Clock"
                      value={selectedTime}
                      className="custom-datepicker"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      onChange={(newTime) => setSelectedTime(newTime)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <button className="customizedModal-btn" onClick={customeReportAPI}>
            Submit
          </button>
        </Card>
      )}
    </>
  );
};
export default BPCLReports;
