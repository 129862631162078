import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const GuageChart = ({ chartData, percentage, colorCode, chart, selectedPlantId }) => {

    // Utility function to apply grey-out effect
    const adjustColorOpacity = (color, isSelected) => {
        if (isSelected) return color;
        // Reduce opacity for unselected colors
        const opacity = 0.3; // Adjust opacity to "grey out"
        return Highcharts.color(color).setOpacity(opacity).get();
    };
    // Map through chartData to create the data for the chart
    const data = chartData.map((item, index) => {
        const isSelected = selectedPlantId.includes(item.plant_id); // Check if plant_id is in selectedPlantId
        const color = adjustColorOpacity(
            colorCode[index % Object.keys(colorCode).length],
            isSelected
        );

        return {
            label: item.plant_name,
            value: chart === "utilization"
                ? Math.round((item.utilization_value || item.utilization_value) * 100) / 100 // Use utilization_value for utilization chart
                : Math.round((item.co2_green_value || item.co2_green_value) * 100) / 100, // Use co2_green_value for other charts
            color: color,
        };
    });

    const centerValue = `${percentage} ${chart === "utilization" ? 'CUF%' : 'PR%'}`; // Dynamically set center value from props

  
    const innerRadius = 60; // Fixed inner radius
    const thickness = 15; // Fixed thickness for each ring

    const series = data.map((ring, index) => {
        const currentOuterSize = innerRadius + (index + 1) * thickness; // Outer size for the current ring
        const currentInnerSize = currentOuterSize - thickness; // Inner size for the current ring

        return {
            name: `${ring.label} ${ring.value}%`,
            data: [
                { name: ring.label, y: ring.value, color: ring.color },
                { name: 'Remaining', y: 100 - ring.value, color: '#d3d3d3' }, 
            ],
            size: `${currentOuterSize}%`,
            innerSize: `${currentInnerSize}%`,
        };
    });

    const options = {
        chart: {
            type: 'pie',
            height: 250,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        credits: {
            enabled: false, 
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '12px',
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false, // Disable data labels
                },
                startAngle: 180, // Start at the bottom of the circle
                endAngle: 540, // Finish at the bottom of the circle
            },
        },
        series: series,
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '230px' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div
                style={{
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#000',
                    textAlign: 'center',
                }}
            >
                {centerValue}
            </div>
        </div>
    );
};

export default GuageChart;
