import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts);
exporting(Highcharts);

const AreaChart = ({ chartData }) => {

    // Extract and transform the data for Highcharts
    const transformedData = chartData
        .filter((plant) => plant.tags && plant.tags.length > 0) // Include only plants with tags
        .map((plant) => {
            const tag = plant.tags[0]; // Assume one tag per plant for simplicity
            return {
                name: plant.plant_name,
                data: tag.data.map((d) => ({
                    x: d.MIN_KEY.split(' ')[0], // Extract only the date portion (YYYY-MM-DD) from MIN_KEY
                    y: d.tag_value, // Use the tag_value as the y-axis value
                })),
                tag_unit: tag.tag_unit, // Capture the tag_unit for y-axis title
            };
        });

    const options = {
        chart: {
            type: 'area', // Set chart type as 'area'
            height: 250, // Reduced height
        },
        title: {
            text: null, // No title for the chart
        },
        xAxis: {
            categories: transformedData[0]?.data.map((d) => d.x), 
            title: {
                text: null,
            },
            startOnTick: true, // Start the X-axis on the first tick
            tickmarkPlacement: 'on', // Place ticks aligned with data points
            min: 0, // Ensure the first point starts at the X-axis origin
            scrollbar: {
                enabled: true, // Enable scrollbar on x-axis
            },
        },
        yAxis: {
            title: {
                text: transformedData[0]?.tag_unit || '', // Dynamic Y-axis title
            },
            gridLineDashStyle: 'Dash', // Dashed gridlines
            gridLineColor: '#e0e0e0', // Light gray color for gridlines
        },
        tooltip: {
            split: true,
            valueSuffix: ` ${transformedData[0]?.tag_unit || ''}`,
        },
        credits: {
            enabled: false, // Remove Highcharts logo
        },
        legend: {
            enabled: false, // Hide legend for a clean look
        },
        plotOptions: {
            area: {
                stacking: 'normal', // Stacked area chart
                lineColor: '#ffffff', // Line color between data points
                lineWidth: 1, // Line width
                marker: {
                    enabled: false, // Disable markers for a cleaner look
                },
            },
        },
        series: transformedData.map((t) => ({
            name: t.name, // Plant name
            data: t.data.map((d) => d.y), // Extract only the y-values
            color: '#fdd835', // Set the yellowish color for the area
        })),
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AreaChart;
