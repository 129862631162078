import { Box, Grid, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { images } from "../../../config";
import SearchBar from "material-ui-search-bar";
import { BPCLPlant } from "../../../container/CompanyDashboard/services";
import { useParams } from "react-router-dom";
import {
  CompactTagValue,
  CompactTagValueDynamic,
  CompactTagUnitDynamic,
} from "../../CompactValue/CompactValue";
import { useSelector } from "react-redux";
import { plantDashboardCount } from "../../../container/SuperAdminDashboard/service";
import { SoftTagFormula } from "../../../container/SmartTag/softTag/SoftTagFormula";

const PlantLocation = (props) => {
  const [plantDashboardCountData, setPlantDashboardCountData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState("");
  const [efficiencyCount, setEfficiencyCount] = useState([]);
  const [hikemmRECounts, setHikemmRECounts] = useState([]);
  const [alert, setAlert] = useState();
  const [initialized, setInitialized] = useState(false);
  const params = useParams();
  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  // const handleSwitchChange = (plantId, color,name) => {
  //   props.setActiveSwitches((prevActiveSwitches) => {
  //     // Check if the plantId already exists in the array
  //     const existingSwitch = prevActiveSwitches.find((switchObj) => switchObj.id === plantId);

  //     if (existingSwitch) {
  //       // If it exists, remove it from the active switches
  //       return prevActiveSwitches.filter((switchObj) => switchObj.id !== plantId);
  //     } else if (prevActiveSwitches.length < 10) {
  //       // If it doesn't exist and we have less than 10 switches, add it
  //       return [...prevActiveSwitches, { id: plantId, color, name }];
  //     } else {
  //       // If we have 10 switches already, do not add more
  //       return prevActiveSwitches;
  //     }
  //   });
  // };
  // const handleSwitchChange = (plantId, color,name) => {
  //   props.setActiveSwitches((prevActiveSwitches) => {
  //     // Check if the plantId already exists in the array
  //     const existingSwitch = prevActiveSwitches.find((switchObj) => switchObj.id === plantId);

  //     if (existingSwitch) {
  //       // If it exists, remove it from the active switches
  //       return prevActiveSwitches.filter((switchObj) => switchObj.id !== plantId);
  //     } else if (prevActiveSwitches.length < 1) {
  //       // If it doesn't exist and we have less than 1 (change to 10) switches, add it
  //       return [...prevActiveSwitches, { id: plantId, color, name }];
  //     } else {
  //       setAlert("Can't add more than 10 Plants")
  //       // If switches are more than 1 (change to 10) then dont add it
  //       return prevActiveSwitches;
  //     }
  //   });
  // };

  const handleSwitchChange = (plantId, color, name) => {
    props.setActiveSwitches((prevActiveSwitches) => {
      // Check if the plantId already exists in the array
      const existingSwitch = prevActiveSwitches.find(
        (switchObj) => switchObj.id === plantId
      );

      if (existingSwitch) {
        // If it exists, remove it from the active switches
        const updatedSwitches = prevActiveSwitches.filter(
          (switchObj) => switchObj.id !== plantId
        );

        // Remove the alert if the updated count is less than or equal to 10
        if (updatedSwitches.length <= 10) {
          setAlert(""); // Clear the alert
        }

        return updatedSwitches;
      } else if (prevActiveSwitches.length < 10) {
        // If it doesn't exist and we have less than 10 switches, add it
        return [...prevActiveSwitches, { id: plantId, color, name }];
      } else {
        // If switches are more than 10, show alert and don't add it
        setAlert("Can't add more than 10 Plants");
        return prevActiveSwitches;
      }
    });
  };

  useEffect(() => {
    handlePlantDashboardCount();
  }, []);

  const handlePlantDashboardCount = async () => {
    const param = {
      company_name: params.companyId,
      dashboard: 1,
    };

    try {
      const resp = await plantDashboardCount(param);
      if (resp.data.success == true) {
        setPlantDashboardCountData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmitError", error);
    }
  };

  const getBackgroundColor = (type) => {
    return props.active === type ? "#1C407B" : "white";
  };
  const getColor = (type) => {
    return props.active === type ? "white" : "black";
  };
  const handleSearchChange = (event) => {
    console.log(event); // Check the event structure
    if (event && event.target) {
      setSearchTerm(event.target.value); // Ensure target exists
    }
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const filteredPlants = props.plantData
    .filter((e) => {
      const matchingEfficiencyPlant = efficiencyCount.find(
        (plant) => plant.plant_id === e.id
      );

      if (filter === "name") {
        return true; // No filter applied, show all plants
      } else if (filter === "color80") {
        return matchingEfficiencyPlant?.plant_color === "#68F195";
      } else if (filter === "color50-80") {
        return matchingEfficiencyPlant?.plant_color === "#FE884B";
      } else if (filter === "color50") {
        return matchingEfficiencyPlant?.plant_color === "#F55A48";
      }

      return true; // Default to show all plants
    })

    .filter((e) => {
      // Apply the search term filter
      return e.plant_name.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  useEffect(() => {
    if (!initialized && filteredPlants.length > 0) {
      const initialSwitches = filteredPlants
        .slice(0, 10)
        .map((plant, index) => ({
          id: plant.id,
          color: switchColors[index % switchColors.length],
          name: plant.plant_name,
        }));
      props.setActiveSwitches(initialSwitches);
      setInitialized(true); // Mark initialization as complete
    }
  }, [filteredPlants, switchColors, props, initialized]);

  useEffect(() => {
    props.setBoxCount(filteredPlants.length);
  }, [filteredPlants]);

  const mergedArray = props.mqttTagData
  ? [].concat(...Object.values(props.mqttTagData))
  : [];


  const mqttDataReduce = mergedArray.reduce(
    (map, item) => {
      map[item.device_tag] = Number(item.tagValue);
      return map;
    },
    {}
  );


  return (
    <>
      <Box className="location-box">
        <Box>
          <div className="d-flex">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Tooltip title={`Total Capacity`} arrow>
                  <div
                    className="solar-div"
                    onClick={() => props.handleClick("solar")}
                    style={{
                      backgroundColor: getBackgroundColor("solar"),
                      color: getColor("solar"),
                    }}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <div>
                        <img
                          src={
                            props.active == "solar"
                              ? images.whiteSolarIcon
                              : images.solarImg
                          }
                          alt=""
                          style={{ height: "22px", marginTop: "7px" }}
                        />
                      </div>
                      <div className="solar-div-data">
                        <h6>
                        {plantDashboardCountData.solar_plant_count} Plant
                        </h6>
                        <p>
                          ({parseFloat(plantDashboardCountData.solar_plant_capacity).toFixed(2)} MWp)
                        </p>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip title={`Total Capacity`} arrow>
                  <div
                    className="solar-div"
                    // onClick={() => props.handleClick("wind")}
                    onClick={
                      plantDashboardCountData.wind_plant_count !== 0
                        ? () => props.handleClick("wind")
                        : null
                    }
                    style={{
                      backgroundColor: getBackgroundColor("wind"),
                      color: getColor("wind"),
                    }}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <div>
                        <img
                          src={
                            props.active == "wind"
                              ? images.whiteWindIcon
                              : images.windImg
                          }
                          alt=""
                          style={{ height: "22px", marginTop: "7px" }}
                        />
                      </div>
                      <div className="solar-div-data">
                        <h6>
                          {plantDashboardCountData.wind_plant_count} Plant
                        </h6>
                        <p>
                          ({parseFloat(plantDashboardCountData.wind_plant_capacity).toFixed(2)} MW)
                        </p>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Box>
        <div className="mt-3">
          <div className="d-flex gap-2">
            <div style={{ width: "376px" }}>
              <SearchBar
                value={searchTerm}
                onChange={(newValue) => setSearchTerm(newValue)} // Handle user typing
                onRequestSearch={() => handleSearchChange()}
                onCancelSearch={() => setSearchTerm("")}
                placeholder="Locations"
              />
            </div>
            <img
              src={images.filterIcon}
              onClick={() => setIsFilter(true)}
              alt=""
              style={{ height: "47px", width: "47px", cursor: "pointer" }}
            />
            {isFilter && (
              <Box
                className="filter-box mt-2"
                style={{ zIndex: "1", height: "fit-content" }}
              >
                <img
                  src={images.closeIcon}
                  onClick={() => setIsFilter(false)}
                  alt=""
                  className="close-img"
                />
                <Typography
                  onClick={() => handleFilterChange("name")}
                  style={{ cursor: "pointer" }}
                >
                  Sort By Name
                </Typography>
                {/* <Typography className="mt-2">Sort By Color</Typography>
                <Typography
                  className="mt-2 color-data"
                  onClick={() => handleFilterChange("color80")}
                >
                  Overall Efficiency (&gt;80%){" "}
                  <div className="green-square"></div>
                </Typography>
                <Typography
                  className="mt-2 color-data"
                  onClick={() => handleFilterChange("color50-80")}
                >
                  Overall Efficiency (50% -80%){" "}
                  <div className="orange-square"></div>
                </Typography>
                <Typography
                  className="mt-2 color-data"
                  onClick={() => handleFilterChange("color50")}
                >
                  Overall Efficiency (&lt;50%){" "}
                  <div className="red-square"></div>
                </Typography> */}
              </Box>
            )}
          </div>
        </div>
        <div className="mt-2 location-div">
          <div style={{ color: "red" }}>{alert}</div>
          {filteredPlants.map((e, index) => {
            const matchingEfficiencyPlant = efficiencyCount.find(
              (plant) => plant.plant_id === e.id
            );
            const borderColor = matchingEfficiencyPlant
              ? matchingEfficiencyPlant.plant_color
              : "#d3d3d3";

            const matchingData = hikemmRECounts.find(
              (plant) => plant.plant_id === e.id
            );

            const plantData12 = props.realTimePlantDta.find(
              (plantData1) => plantData1.plant_id == e.id
            );

            console.log("plantData12", plantData12);

            return (
              <Box
                className="location-plant mt-2"
                style={{ border: `1px solid ${borderColor}` }}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img
                        src={
                          e.is_solar_energy ? images.solarImg : images.windImg
                        }
                        alt=""
                        style={{ height: "22px" }}
                      />
                      <h6 className="mb-0 mt-2">
                        <b>{e.plant_name}</b>
                      </h6>
                    </div>
                    <div>
                      <Switch
                        checked={props.activeSwitches.some(
                          (switchObj) => switchObj.id === e.id
                        )}
                        onChange={() =>
                          handleSwitchChange(
                            e.id,
                            switchColors[index],
                            e.plant_name
                          )
                        }
                        disabled={
                          props.activeSwitches.length >= 10 &&
                          !props.activeSwitches.some(
                            (switchObj) => switchObj.id === e.id
                          )
                        }
                        sx={{
                          "& .MuiSwitch-thumb": { bgcolor: "#828282" },
                          "& .MuiSwitch-track": {
                            bgcolor: "#D9D9E5 !important",
                          },
                          "& .Mui-checked": {
                            "& .MuiSwitch-thumb": {
                              bgcolor:
                                switchColors[index % switchColors.length],
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#E2D9F2 !important",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="pt-3">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap", // Allow wrapping
                        justifyContent: "flex-start", // Align items to the left
                        width: "100%",
                      }}
                    >
                      {plantData12?.tags
                        ?.slice(0, 4) // Process only the first 4 tags
                        .map((tag, index) => {
                          console.log("tag", tag);
                          const dividerClass =
                            index === 0
                              ? "gen-dividerLine"
                              : index === 1
                              ? "uti-dividerLine"
                              : index === 2
                              ? "cost-dividerLine"
                              : index === 3
                              ? "co2-dividerLine"
                              : "default-dividerLine";

                      
                         
                          let tagValue = mergedArray.length
                            ? mergedArray.find(
                                (f) =>
                                  f.device__uid == tag.device_uid &&
                                  f.device_tag == tag.device_tag
                              )?.["tagValue"] || 0.0
                            : 0.0;

                          if (
                            tag?.is_soft_tag &&
                            tag?.soft_tag_id__formula &&
                            Object.keys(mqttDataReduce).length
                          ) {
                            if (
                              SoftTagFormula(
                                tag.soft_tag_id__formula,
                                mqttDataReduce
                              )?.softTagNotGetTag == true
                            ) {
                              tagValue = SoftTagFormula(
                                tag.soft_tag_id__formula,
                                mqttDataReduce
                              )?.value;
                            } else {
                              tagValue = tagValue;
                            }
                          }

                          const displayValue =
                            tagValue === undefined ? 0.0 : tagValue;

                          const backgroundColor =
                            index === 0
                              ? "#ffaa32" // Gold for index 0
                              : index === 1
                              ? "#0c6591" // GreenYellow for index 1
                              : index === 2
                              ? "#9933ff" // SkyBlue for index 2
                              : index === 3
                              ? "#50c878" // LightPink for index 3
                              : "#F0F0F0"; // Default LightGray for others

                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                backgroundColor: backgroundColor,
                                boxSizing: "border-box",
                                marginRight: "8px",
                                padding: "3px",
                                marginBottom: "5px",
                                borderRadius: "2px",
                              }}
                              className="solar-grid-box"
                              key={index}
                            >
                              {/* <div className={dividerClass}></div> */}
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                fontSize="14px"
                                style={{ color: "white" }}
                              >
                                {tag?.tag_unit === "MW" ||
                                tag?.tag_unit === "MVAr"
                                  ? CompactTagValueDynamic(
                                      selectBPCLUnits,
                                      displayValue
                                    )
                                  : displayValue}

                                {/* {CompactTagValue(
                                        selectBPCLUnits,
                                        displayValue
                                      )} */}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "5px",
                                  marginLeft: "7px",
                                  color: "white",
                                }}
                              >
                                {tag?.tag_unit === "MW" ||
                                tag?.tag_unit === "MVAr"
                                  ? `${CompactTagUnitDynamic(
                                      selectBPCLUnits,
                                      "M"
                                    )}${tag?.tag_unit.substring(1)}`
                                  : tag?.tag_unit}
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  </div>
                </div>
              </Box>
            );
          })}
        </div>
      </Box>
    </>
  );
};

export default PlantLocation;
