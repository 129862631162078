import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Bpcl-PlantDashboard.css";
import {
  Box,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { images } from "../../../config/images";
import { useNavigate, useParams } from "react-router-dom";
import { BPCLPlant, getPlant } from "../../CompanyDashboard/services";
import { plantDashboardCount } from "../../SuperAdminDashboard/service";
import MapComponent from "../../../components/BPCL/PlantLocation/Map";
import zIndex from "@mui/material/styles/zIndex";
import InfoIcon from "@mui/icons-material/Info";
import {
  getHikemmREPlantDashboardTags,
  hikemmREPlantDashboardCount,
  plantDashboardEfficiencyCount,
} from "../services";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import SearchBar from "material-ui-search-bar";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Mqqtdata from "../../ProcessDashboard/Mqqtdata";
import { CompactTagValue } from "../../../components/CompactValue/CompactValue";
import OverViewMachineMqttData from "../../SmartTag/MachineDashboard/mqttDataMachineDashboard/BpclmqttData";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";

const BpclPlantDashboard = () => {
  const [active, setActive] = useState("");
  const [plantData, setPlantData] = useState([]);
  const [plantDashboardCountData, setPlantDashboardCountData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [allPlant, setAllPlant] = useState([]);
  const [allPlantName, setAllPlantName] = useState([]);
  const [mapStyle, setMapStyle] = useState(1);
  const [efficiencyCount, setEfficiencyCount] = useState([]);
  const [colorCount, setColorCount] = useState({});
  const [hikemmRECounts, setHikemmRECounts] = useState([]);
  const [allHikemmRECounts, setAllHikemmRECounts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortByName, setSortByName] = useState(false);
  const [plantId, setPlantId] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [allPlantId, setAllPlantId] = useState([]);
  const [realTimeData, setRealTimeData] = useState([]);
  const [boxCount, setBoxCount] = useState(0);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [displayValues, setDisplayedValues] = useState([]);
  const [tagUnitSums, setTagUnitSums] = useState({});
  const [percentageChanges, setPercentageChanges] = useState({}); // State to store percentage changes
  const [previousTagUnitSums, setPreviousTagUnitSums] = useState({});

  const prevTagUnitSums = useRef(null); // To store previous values
  const [percentages, setPercentages] = useState({
    MW: 0,
    MVAr: 0,
    PR: 0,
    CUF: 0,
  });



  const calculatePercentage1 = (curValue, prevValue) => {
    if (!isFinite(curValue) || !isFinite(prevValue) || prevValue === 0) {
      return 0; // Handle invalid or zero `prevValue`
    }
    return ((curValue - prevValue) / prevValue) * 100;
  };
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);

  useEffect(() => {
    if (tagUnitSums && prevTagUnitSums.current) {
      // Check if any value has changed
      const hasChanged = Object.keys(tagUnitSums).some(
        (key) => tagUnitSums[key] !== prevTagUnitSums.current[key]
      );

      if (hasChanged) {
        // Calculate percentages only for changed values
        const MWPercentage = calculatePercentage1(
          tagUnitSums.MW,
          prevTagUnitSums.current.MW
        );
        const MVArPercentage = calculatePercentage1(
          tagUnitSums.MVAr,
          prevTagUnitSums.current.MVAr
        );
        const PRPercentage = calculatePercentage1(
          tagUnitSums["PR%"],
          prevTagUnitSums.current["PR%"]
        );
        const CUFPercentage = calculatePercentage1(
          tagUnitSums["CUF%"],
          prevTagUnitSums.current["CUF%"]
        );

        setPercentages({
          MW: MWPercentage,
          MVAr: MVArPercentage,
          PR: PRPercentage,
          CUF: CUFPercentage,
        });

        // Update previous values
        prevTagUnitSums.current = { ...tagUnitSums };
      }
    } else {
      // Initialize previous values on first render
      if (tagUnitSums) {
        prevTagUnitSums.current = { ...tagUnitSums };
      }
    }
  }, [tagUnitSums]);

  console.log("MWPercentage", percentages);

  // console.log("test",efficiencyCount);

  console.log("percentageChanges", percentageChanges);
  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const tagValues = realTimeData
    .map((realData) => {
      // Find the matching entry in mergedArray
      const match = mergedArray.find(
        (mergedData) =>
          mergedData.device__uid === realData.device_uid &&
          mergedData.device_tag === realData.device_tag
      );

      // Return the tagValue if a match is found, otherwise return null
      return match ? match.tagValue : null;
    })
    .filter((value) => value !== null); // Filter out null values

  console.log("mergedArray", mergedArray);
  // console.log("realTimePlantDta", realTimePlantDta);

  const navigate = useNavigate();

  const handleClickImg = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  // console.log("selectBPCLUnits",selectBPCLUnits);
  const handleAnalyze = () => {
    navigate(`/bpcl-analyze/company/${params.id}`);
  };
  const handleClick = (type) => {
    setActive((prevActive) => (prevActive === type ? null : type));
  };
  const handleBoxClick = (location, name, img, id) => {
    setSelectedLocation(location);
    setSelectedPlant(name);
    setSelectedImage(img);
    setPlantId(id);
  };
  useEffect(() => {
    handlePlantDashboardCount();
  }, []);

  const handlePlantDashboardCount = async () => {
    const param = {
      company_name: params.id,
      dashboard: 1,
    };

    try {
      const resp = await plantDashboardCount(param);
      if (resp.data.success == true) {
        setPlantDashboardCountData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmitError", error);
    }
  };
  const params = useParams();

  const getBackgroundColor = (type) => {
    return active === type ? "#1C407B" : "white";
  };
  const getColor = (type) => {
    return active === type ? "white" : "black";
  };

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.id,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
        setAllPlant(resp.data.payload.map((e) => e.plant_location));
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };
  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);
  useEffect(() => {
    handleEfficiencyCount();
    handleHikemmREPlantDashboardCount();
  }, [mapStyle, plantIds, selectBPCLUnits]);

  const handleEfficiencyCount = async () => {
    // setLoading(true)
    const params = {
      plant_ids: plantData.map((e) => e.id),
      information_tag_unit: "%RH",
      time_period: Number(mapStyle),
    };
    try {
      const resp = await plantDashboardEfficiencyCount(params);
      if (resp.data.success == true) {
        setLoading(false);
        setEfficiencyCount(resp.data.data);
        setColorCount(resp.data.color_summary);
        // console.log("resp", resp);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const handleHikemmREPlantDashboardCount = async () => {
    // setLoading(true)
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MW",
      co2_green_tag_unit: "Mt",
      utilization_tag_unit: "Uti_Cuf%",
      cost_saving_tag_unit: "Cost_INR",
      time_period: Number(mapStyle),
    };
    try {
      const resp = await hikemmREPlantDashboardCount(params);
      if (resp.data.success == true) {
        setHikemmRECounts(resp.data.data);
        setAllHikemmRECounts(resp.data);
        console.log("qq", resp.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const borderColorCount = {
    "#68F195": 0, // Green
    "#FE884B": 0, // Orange
    "#F55A48": 0, // Red
  };
  const filteredPlants = plantData

    .filter((e) => {
      // Find the relevant plant data for calculating displayValue
      const plantData12 = realTimePlantDta.find(
        (plantData1) => plantData1.plant_id == e.id
      );

      const mergedArray = mqttTagData
        ? [].concat(...Object.values(mqttTagData))
        : [];
      const tagValue = mergedArray.find(
        (item) =>
          item.device__uid === plantData12?.tags[2]?.device_uid &&
          item.device_tag === plantData12?.tags[2]?.device_tag
      )?.tagValue;

      const displayValue = tagValue === undefined ? 0.0 : parseFloat(tagValue);

      if (filter === "name") {
        return true;
      } else if (filter === "color80") {
        return displayValue > 80;
      } else if (filter === "color50-80") {
        return displayValue >= 50 && displayValue < 80;
      } else if (filter === "color50") {
        return displayValue < 50;
      }

      return true;
    })
    .filter((e) => {
      return e.plant_name.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .map((e) => {
      const matchingEfficiencyPlant = efficiencyCount.find(
        (plant) => plant.plant_id === e.id
      );
      const plantData12 = realTimePlantDta.find(
        (plantData1) => plantData1.plant_id == e.id
      );

      const mergedArray = mqttTagData
        ? [].concat(...Object.values(mqttTagData))
        : [];
      const tagValue = mergedArray.find(
        (item) =>
          item.device__uid === plantData12.tags[2].device_uid &&
          item.device_tag === plantData12.tags[2].device_tag
      )?.tagValue;

      const displayValue = tagValue === undefined ? 0.0 : parseFloat(tagValue);

      let borderColor;

      if (displayValue > 80) {
        borderColor = "#68F195";
      } else if (displayValue >= 50 && displayValue <= 80) {
        borderColor = "#FE884B";
      } else if (displayValue < 50) {
        borderColor = "#F55A48";
      }
      if (!borderColorCount[borderColor]) {
        borderColorCount[borderColor] = 0;
      }
      borderColorCount[borderColor] += 1;

      return {
        ...e,
        borderColor,
      };
    });

  console.log("filteredPlants", filteredPlants);

  useEffect(() => {
    setBoxCount(filteredPlants.length);
  }, [filteredPlants]);

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  const calculatePercentage = (curValue, prevValue) => {
    if (!isFinite(curValue) || !isFinite(prevValue) || prevValue === 0) {
      return 0; // Avoid division by zero or invalid numbers
    }
    return ((curValue - prevValue) / prevValue) * 100;
  };
  const displayedTagValues = filteredPlants.reduce((acc, plant) => {
    const plantData12 = realTimePlantDta.find(
      (plantData1) => plantData1.plant_id === plant.id
    );

    plantData12?.tags?.forEach((tag) => {
      const mergedArray = mqttTagData
        ? [].concat(...Object.values(mqttTagData))
        : [];
        let tagValue = mergedArray.length
        ? mergedArray.find(
            (f) =>
              f.device__uid == tag.device_uid &&
              f.device_tag == tag.device_tag
          )?.["tagValue"] || 0.0
        : 0.0;

      if (
        tag.is_soft_tag &&
        tag.soft_tag_id__formula &&
        Object.keys(mqttDataReduce).length
      ) {
        if (
          SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
            ?.softTagNotGetTag == true
        ) {
          tagValue = SoftTagFormula(
            tag.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
        } else {
          tagValue = tagValue;
        }
      }
      const value = tagValue === undefined ? 0.0 : parseFloat(tagValue);
      acc[tag.tag_unit] = (acc[tag.tag_unit] || 0) + value;
    });

    return acc;
  }, {});

  const boxCount1 = filteredPlants.length;

  const tagUnitSums1 = {
    MW: displayedTagValues["MW"] || 0,
    MVAr: displayedTagValues["MVAr"] || 0,
    "PR%": displayedTagValues["PR%"] || 0,
    "CUF%": displayedTagValues["CUF%"] || 0,
  };

  // const percentages = {
  //   MW: (tagUnitSums1.MW / boxCount1) * 100,
  //   MVAr: (tagUnitSums1.MVAr / boxCount1) * 100,
  //   PR: tagUnitSums1["PR%"] / boxCount1,
  //   CUF: tagUnitSums1["CUF%"] / boxCount1,
  // };

  useEffect(() => {
    // Initialize updatedValues array
    const updatedValues = filteredPlants.map((plant) => {
      // Find the matching plant data
      const plantData12 = realTimePlantDta.find(
        (plantData1) => plantData1.plant_id === plant.id
      );

      // If plantData12 is found, proceed
      if (plantData12) {
        const matchingData = plantData12.tags
          ?.slice(0, 4)
          .reduce((acc, tag) => {
            let tagValue = mergedArray.length
              ? mergedArray.find(
                  (f) =>
                    f.device__uid == tag.device_uid &&
                    f.device_tag == tag.device_tag
                )?.["tagValue"] || 0.0
              : 0.0;

            if (
              tag.is_soft_tag &&
              tag.soft_tag_id__formula &&
              Object.keys(mqttDataReduce).length
            ) {
              if (
                SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
                  ?.softTagNotGetTag == true
              ) {
                tagValue = SoftTagFormula(
                  tag.soft_tag_id__formula,
                  mqttDataReduce
                )?.value;
              } else {
                tagValue = tagValue;
              }
            }
            console.log("is_soft_tag", tagValue);

            // Set the tag value and unit in the accumulator
            const tagValue1 = tagValue;
            const tagName = tag?.tag_unit;
            if (tagName) {
              acc[tagName] = tagValue1;
            }

            return acc;
          }, {});

        // Return updated plant data with matching tag values
        return {
          plant_id: plant.plant_id,
          ...matchingData,
        };
      }

      // If no matching plant data is found, return a default object
      return {
        plant_id: plant.plant_id,
      };
    });

    // Log the updated values
    console.log("Updated Values:", updatedValues);

    // Set the displayed values
    setDisplayedValues(updatedValues);

    // Calculate sums for each tag_unit
    const calculateTagUnitSums = () => {
      const sums = {};
      updatedValues.forEach((plant) => {
        Object.keys(plant).forEach((key) => {
          if (key !== "plant_id") {
            // Exclude plant_id
            const value = parseFloat(plant[key]) || 0;
            sums[key] = Math.round((sums[key] || 0) + value);
          }
        });
      });
      return sums;
    };

    const newTagUnitSums = calculateTagUnitSums();

    const calculatePercentageChanges = (prev, current) => {
      const changes = {};
      Object.keys(current).forEach((key) => {
        if (prev[key] !== undefined) {
          const prevValue = prev[key] ?? 0;
          const currentValue = current[key];
          const difference = currentValue - prevValue;
          const percentageChange =
            prevValue !== 0 ? (difference / prevValue) * 100 : 0;
          changes[key] = Math.round(percentageChange * 100) / 100;
        } else {
          changes[key] = 0;
        }
      });
      return changes;
    };

    // Calculate percentage changes between previous and current sums
    const newPercentageChanges = calculatePercentageChanges(
      previousTagUnitSums,
      newTagUnitSums
    );

    // Update the state with the new values
    setTagUnitSums(newTagUnitSums);
    setPercentageChanges(newPercentageChanges);

    // Update previousTagUnitSums for future calculations
    setTimeout(() => setPreviousTagUnitSums(newTagUnitSums), 5000);
  }, [realTimePlantDta, previousTagUnitSums]);

  const handleSearchChange = (event) => {
    // console.log(event); // Check the event structure
    if (event && event.target) {
      setSearchTerm(event.target.value); // Ensure target exists
    }
  };
  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds]);

  // useEffect(() => {
  //   if (filteredPlants.length) {
  //     const Data = filteredPlants.map((e) => e.id);
  //     setAllPlantId(Data);
  //   }
  // }, [filteredPlants]);

  // console.log("filteredPlants", filteredPlants);
  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MW",
      utilization_tag_unit: "MVAr",
      cost_saving_tag_unit: "PR%",
      co2_green_tag_unit: "CUF%",
    };
    try {
      const resp = await getHikemmREPlantDashboardTags(params);
      console.log("API Response for getHikemmREPlantDashboardTags:", resp.data);
      if (resp.status == 200 || resp.status == 201) {
        const tagsData = resp.data.payload;
        setRealTimePlantData(tagsData);

        const tagDataList = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => ({
            dvName: tag.device_tag || "-",
            tagName: tag.tag_name,
            machineUID: tag.device_uid,
            is_soft_tag: tag.is_soft_tag || false,
            soft_tag_id__formula: tag.soft_tag_id__formula || null,
          }))
        );

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });
            console.log("evaluatedFormula", evaluatedFormula);
            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => {
            let curValue = 0;
            let prevValue = 0;

            if (tag.is_soft_tag && tag.soft_tag_id__formula) {
              // Evaluate the formula for the soft tag using the provided method
              curValue = evaluateSoftTagValue(
                tag.soft_tag_id__formula,
                mqttTagPreviousvalue
              );
              prevValue = curValue; // Assuming prevValue is the same as curValue initially
            }

            return {
              is_soft_tag: tag.is_soft_tag,
              dvName: tag.device_tag || "-",
              prevValue,
              curValue,
              dateAndTime: getTagDateAndTime(),
              soft_tag_id__formula: tag.soft_tag_id__formula,
            };
          })
        );

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };



  return (
    <>
      <div className="Bpcl-page-wrapper">
        {/* {realTimeData.length ? (
          <Mqqtdata
            setMqttTagData={setMqttTagData}
            topics={topics}
            overViewTagData={realTimeData}
          />
        ) : (
          ""
        )} */}
        {realTimeData.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
            mqttTagData={mqttTagData}
            setMqttTagData={setMqttTagData}
          />
        ) : null}
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box className="location-box">
              <Box>
                <div className="d-flex">
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Tooltip title={`Total Capacity`} arrow>
                        <div
                          className="solar-div"
                          onClick={() => handleClick("solar")}
                          style={{
                            backgroundColor: getBackgroundColor("solar"),
                            color: getColor("solar"),
                          }}
                        >
                          <div className="d-flex gap-2 justify-content-center">
                            <div>
                              <img
                                src={
                                  active === "solar"
                                    ? images.whiteSolarIcon
                                    : images.solarImg
                                }
                                alt=""
                                style={{ height: "22px", marginTop: "7px" }}
                              />
                            </div>
                            <div className="solar-div-data">
                              <h6>
                                {plantDashboardCountData.solar_plant_count}{" "}
                                Plant
                              </h6>
                              <p>
                                ({parseFloat(plantDashboardCountData.solar_plant_capacity).toFixed(2)}{" "}
                                MWp)
                              </p>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip title={`Total Capacity`} arrow>
                        <div
                          className="solar-div"
                          onClick={
                            plantDashboardCountData.wind_plant_count !== 0
                              ? () => handleClick("wind")
                              : null
                          }
                          style={{
                            backgroundColor: getBackgroundColor("wind"),
                            color: getColor("wind"),
                          }}
                        >
                          <div className="d-flex gap-2 justify-content-center">
                            <div>
                              <img
                                src={
                                  active === "wind"
                                    ? images.whiteWindIcon
                                    : images.windImg
                                }
                                alt=""
                                style={{ height: "22px", marginTop: "7px" }}
                              />
                            </div>
                            <div className="solar-div-data">
                              <h6>
                                {plantDashboardCountData.wind_plant_count} Plant
                              </h6>
                              <p>
                                ({parseFloat(plantDashboardCountData.wind_plant_capacity).toFixed(2)}{" "}
                                MWp)
                              </p>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </div>
              </Box>
              <div className="mt-3">
                <div className="d-flex gap-2">
                  <div style={{ width: "376px" }}>
                    <SearchBar
                      value={searchTerm}
                      onChange={(newValue) => setSearchTerm(newValue)} // Handle user typing
                      onRequestSearch={() => handleSearchChange()}
                      onCancelSearch={() => setSearchTerm("")}
                      placeholder="Locations"
                    />
                  </div>
                  <img
                    src={images.filterIcon}
                    onClick={() => setIsFilter(true)}
                    alt=""
                    style={{ height: "47px", width: "47px", cursor: "pointer" }}
                  />
                  {isFilter && (
                    <Box className="filter-box mt-2">
                      <img
                        src={images.closeIcon}
                        onClick={() => setIsFilter(false)}
                        alt=""
                        className="close-img"
                      />
                      <Typography
                        onClick={() => handleFilterChange("name")}
                        style={{ cursor: "pointer" }}
                      >
                        Sort By Name
                      </Typography>
                      <Typography className="mt-2">Sort By Color</Typography>
                      <Typography
                        className="mt-2 color-data"
                        onClick={() => handleFilterChange("color80")}
                      >
                        Overall Efficiency (&gt;80%){" "}
                        <div className="green-square"></div>
                      </Typography>
                      <Typography
                        className="mt-2 color-data"
                        onClick={() => handleFilterChange("color50-80")}
                      >
                        Overall Efficiency (50% -80%){" "}
                        <div className="orange-square"></div>
                      </Typography>
                      <Typography
                        className="mt-2 color-data"
                        onClick={() => handleFilterChange("color50")}
                      >
                        Overall Efficiency (&lt;50%){" "}
                        <div className="red-square"></div>
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
              <div className="mt-2 location-div">
                {filteredPlants.map((e) => {
                  const matchingEfficiencyPlant = efficiencyCount.find(
                    (plant) => plant.plant_id === e.id
                  );

                  const informationValue = matchingEfficiencyPlant
                    ? matchingEfficiencyPlant.information_value
                    : 0;
                  const plantData12 = realTimePlantDta.find(
                    (plantData1) => plantData1.plant_id == e.id
                  );

                  return (
                    <Box
                      className="location-plant mt-2"
                      style={{ border: `1px solid ${e.borderColor}` }}
                      onClick={() =>
                        handleBoxClick(
                          e.plant_location,
                          e.plant_name,
                          e.is_solar_energy,
                          e.id
                        )
                      }
                    >
                      <div>
                        <div className="d-flex gap-2">
                          <img
                            src={
                              e.is_solar_energy
                                ? images.solarImg
                                : images.windImg
                            }
                            alt=""
                            style={{ height: "22px" }}
                          />
                          <h6 className="mb-0 mt-2">
                            <b>{e.plant_name}</b>
                          </h6>
                        </div>

                        <div className="pt-3" id="abc">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {plantData12?.tags
                              ?.slice(0, 4)
                              .map((tag, index) => {
                                const dividerClass =
                                  index === 0
                                    ? "gen-dividerLine"
                                    : index === 1
                                    ? "uti-dividerLine"
                                    : index === 2
                                    ? "cost-dividerLine"
                                    : index === 3
                                    ? "co2-dividerLine"
                                    : "default-dividerLine";

                                const mergedArray = mqttTagData
                                  ? [].concat(...Object.values(mqttTagData))
                                  : [];

                                let tagValue = mergedArray.length
                                  ? mergedArray.find(
                                      (f) =>
                                        f.device__uid == tag.device_uid &&
                                        f.device_tag == tag.device_tag
                                    )?.["tagValue"] || 0.0
                                  : 0.0;

                                if (
                                  tag.is_soft_tag &&
                                  tag.soft_tag_id__formula &&
                                  Object.keys(mqttDataReduce).length
                                ) {
                                  if (
                                    SoftTagFormula(
                                      tag.soft_tag_id__formula,
                                      mqttDataReduce
                                    )?.softTagNotGetTag == true
                                  ) {
                                    tagValue = SoftTagFormula(
                                      tag.soft_tag_id__formula,
                                      mqttDataReduce
                                    )?.value;
                                  } else {
                                    tagValue = tagValue;
                                  }
                                }

                                const displayValue =
                                  tagValue === undefined ? 0.0 : tagValue;
                                const backgroundColor =
                                  index === 0
                                    ? "#ffaa32" // Gold for index 0
                                    : index === 1
                                    ? "#0c6591" // GreenYellow for index 1
                                    : index === 2
                                    ? "#9933ff" // SkyBlue for index 2
                                    : index === 3
                                    ? "#50c878" // LightPink for index 3
                                    : "#F0F0F0"; // Default LightGray for others

                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      backgroundColor: backgroundColor,
                                      boxSizing: "border-box",
                                      marginRight: "8px",
                                      padding: "3px",
                                      marginBottom: "5px",
                                      borderRadius: "2px",
                                    }}
                                    className="solar-grid-box"
                                    key={index}
                                  >
                                    {/* <div className={dividerClass}></div> */}
                                    <Typography
                                      variant="body2"
                                      fontWeight="500"
                                      fontSize="14px"
                                      style={{ color: "white" }}
                                    >
                                      {tag?.tag_unit
                                        ?.toLowerCase()
                                        ?.startsWith("k")
                                        ? Number(
                                            CompactTagValue(
                                              selectBPCLUnits,
                                              displayValue
                                            ) || 0
                                          ).toFixed(2)
                                        : Number(displayValue || 0).toFixed(2)}
                                    </Typography>
                                    <div className="react-test"></div>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontSize: "10px",
                                        marginTop: "5px",
                                        marginLeft: "6px",
                                        color: "white",
                                      }}
                                    >
                                      {tag?.tag_unit}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                        </div>
                      </div>
                    </Box>
                  );
                })}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box className="map-box" style={{ position: "relative" }}>
              <Box className="map-div" style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "-2px",
                    padding: "8px",
                    borderRadius: "5px",
                    zIndex: 1,
                  }}
                >
                  <img
                    src={images.infoIcon}
                    alt=""
                    onClick={handleClickImg}
                    style={{ cursor: "pointer" }}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box
                        sx={{ minWidth: "200px" }}
                        className="bpcl-legend-popup"
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            textAlign: "center",
                            paddingTop: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Legends
                        </Typography>
                        <hr className="mt-0" />
                        <div className="p-2">
                          <Box>
                            <div className="d-flex gap-2">
                              <img
                                src={images.highEfficient}
                                alt=""
                                style={{ height: "32px", marginTop: "7px" }}
                              />
                              <div>
                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontWeight: 900,
                                    fontSize: "20px",
                                  }}
                                >
                                  {borderColorCount["#68F195"]}
                                </Typography>
                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    letterSpacing: "0.4px",
                                  }}
                                >
                                  Overall Efficiency(&gt;80%)
                                </Typography>
                              </div>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              marginTop: "10px",
                            }}
                          >
                            <div className="d-flex gap-2">
                              <img
                                src={images.mediumEfficient}
                                alt=""
                                style={{ height: "32px", marginTop: "7px" }}
                              />
                              <div>
                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontWeight: 900,
                                    fontSize: "20px",
                                  }}
                                >
                                  {borderColorCount["#FE884B"]}
                                </Typography>
                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    letterSpacing: "0.4px",
                                  }}
                                >
                                  Overall Efficiency(50%-80%)
                                </Typography>
                              </div>
                            </div>
                          </Box>

                          <Box
                            sx={{
                              marginTop: "10px",
                            }}
                          >
                            <div className="d-flex gap-2">
                              <img
                                src={images.lowEfficient}
                                alt=""
                                style={{ height: "32px", marginTop: "7px" }}
                              />
                              <div>
                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontWeight: 900,
                                    fontSize: "20px",
                                  }}
                                >
                                  {borderColorCount["#F55A48"]}
                                </Typography>

                                <Typography
                                  sx={{
                                    marginLeft: "8px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    letterSpacing: "0.4px",
                                  }}
                                >
                                  Overall Efficiency(&lt;50%)
                                </Typography>
                              </div>
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </ClickAwayListener>
                  </Popover>
                </div>

                <MapComponent
                  location={selectedLocation}
                  plantName={selectedPlant}
                  selectedImage={selectedImage}
                  allPlant={allPlant}
                  plantId={plantId}
                  companyId={params.id}
                  plantData={filteredPlants}
                  plantAllData={plantData}
                />
              </Box>
              <Box>
                <div className="analylize-sec p-2">
                  <p>Analyze</p>
                  <img
                    src={images.analyze}
                    alt=""
                    className="analyze-img"
                    // style={{ pointerEvents: "none" }}
                    onClick={handleAnalyze}
                  />
                </div>
              </Box>
              <Box className="analyze-div d-flex justify-content-between">
                <div className="gen-div">
                  <div className="d-flex gap-4 justify-content-center">
                    <h1 class="1">
                      {isFinite(tagUnitSums1?.MW)
                        ? tagUnitSums1?.MW.toFixed(2)
                        : 0}
                    </h1>

                    {percentages?.MW >= 0 ? (
                      <img src={images.upArrow} alt="" className="upArrowImg" />
                    ) : (
                      <img
                        src={images.downarrow}
                        alt=""
                        className="downArrowImg"
                      />
                    )}
                  </div>

                  <p>MW</p>
                  <div className="generation-div">Generation</div>
                  <p>
                    {percentages?.MW >= 0 ? "Increased by" : "Reduced by"}{" "}
                    {percentages?.MW.toFixed(4)}%
                  </p>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="d-flex gap-4 justify-content-center">
                    <h1 class="3">
                      {isFinite(tagUnitSums1?.MVAr)
                        ? tagUnitSums1?.MVAr.toFixed(2)
                        : 0}

                      {/* {allHikemmRECounts?.is_compact
                       ? allHikemmRECounts?.total_cost_saving_value_in_billion
                       : allHikemmRECounts?.total_utilization_value} */}
                    </h1>

                    {percentages?.MVAr >= 0 ? (
                      <img src={images.upArrow} alt="" className="upArrowImg" />
                    ) : (
                      <img
                        src={images.downarrow}
                        alt=""
                        className="downArrowImg"
                      />
                    )}
                  </div>

                  <p>MVAr</p>
                  <div className="utilization-div">Reactive Power</div>
                  <p>
                    {percentages?.MVAr >= 0 ? "Increased by" : "Reduced by"}{" "}
                    {percentages?.MVAr.toFixed(4)}%
                  </p>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="d-flex gap-4 justify-content-center">
                    <h1 class="2">
                      {Number.isNaN(tagUnitSums1?.["PR%"] / boxCount) ||
                      tagUnitSums1?.["PR%"] / boxCount === Infinity ||
                      tagUnitSums1?.["PR%"] / boxCount === -Infinity
                        ? 0
                        : (tagUnitSums1?.["PR%"] / boxCount).toFixed(2)}
                    </h1>
                    {percentages?.PR >= 0 ? (
                      <img src={images.upArrow} alt="" className="upArrowImg" />
                    ) : (
                      <img
                        src={images.downarrow}
                        alt=""
                        className="downArrowImg"
                      />
                    )}
                  </div>

                  <p>PR%</p>
                  <div className="cost-div">Performance</div>
                  <p>
                    {/* {allHikemmRECounts?.cost_saving_percentage_change >= 0
                     ? " Increased by"
                     : "Reduce By"}
                   {allHikemmRECounts?.cost_saving_percentage_change} */}
                    {percentages?.PR >= 0 ? "Increased by" : "Reduce By"}{" "}
                    {percentages?.PR.toFixed(4)}%
                  </p>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="d-flex gap-4 justify-content-center">
                    <h1 class="4">
                      {Number.isNaN(tagUnitSums1?.["CUF%"] / boxCount) ||
                      tagUnitSums1?.["CUF%"] / boxCount === Infinity ||
                      tagUnitSums1?.["CUF%"] / boxCount === -Infinity
                        ? 0
                        : (tagUnitSums1?.["CUF%"] / boxCount).toFixed(2)}

                      {/* {allHikemmRECounts?.total_co2_green_value} */}
                    </h1>
                    {/* {selectBPCLUnits == "k" ? (
                     allHikemmRECounts?.co2_green_percentage_change_in_kilo >=
                     0 ? (
                       <img
                         src={images.upArrow}
                         alt=""
                         className="upArrowImg"
                       />
                     ) : (
                       <img
                         src={images.downarrow}
                         alt=""
                         className="upArrowImg"
                       />
                     )
                   ) : selectBPCLUnits == "m" ? (
                     allHikemmRECounts?.co2_green_percentage_change >= 0 ? (
                       <img
                         src={images.upArrow}
                         alt=""
                         className="upArrowImg"
                       />
                     ) : (
                       <img
                         src={images.downarrow}
                         alt=""
                         className="upArrowImg"
                       />
                     )
                   ) : allHikemmRECounts?.co2_green_percentage_change_in_giga >=
                     0 ? (
                     <img src={images.upArrow} alt="" className="upArrowImg" />
                   ) : (
                     <img
                       src={images.downarrow}
                       alt=""
                       className="upArrowImg"
                     />
                   )} */}
                    {percentages?.CUF >= 0 ? (
                      <img src={images.upArrow} alt="" className="upArrowImg" />
                    ) : (
                      <img
                        src={images.downarrow}
                        alt=""
                        className="downArrowImg"
                      />
                    )}
                  </div>

                  <p>CUF%</p>
                  <div className="BPCL-green">Utilization</div>
                  <p>
                    {/* {selectBPCLUnits == "k"
                     ? allHikemmRECounts?.co2_green_percentage_change_in_kilo >=
                       0
                       ? "Co2 Increased by"
                       : "Co2 Reduce By"
                     : selectBPCLUnits == "m"
                     ? allHikemmRECounts?.co2_green_percentage_change >= 0
                       ? "Co2 Increased by"
                       : "Co2 Reduce By"
                     : allHikemmRECounts?.co2_green_percentage_change_in_giga >=
                       0
                     ? "Co2 Increased by"
                     : "Co2 Reduce By"}{" "}
                   {selectBPCLUnits == "k"
                     ? allHikemmRECounts?.co2_green_percentage_change_in_kilo
                     : selectBPCLUnits == "m"
                     ? allHikemmRECounts?.co2_green_percentage_change
                     : allHikemmRECounts?.co2_green_percentage_change_in_giga} */}
                    {percentages?.CUF >= 0 ? "Increased by" : "Reduce By"}{" "}
                    {percentages?.CUF.toFixed(4)}%
                  </p>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      {isLoading && <Loader />}
    </>
  );
};
export default BpclPlantDashboard;
