import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import SmartTagOverviewReport from "../../Smartagcomponent/OverviewReport";
import SmartTagList from "../../Smartagcomponent/SmarttagtagList";
import "../../../../container/VMS/VmsCompanyDashboard/VmsMachine/Reports/Report.css";

import {
  getDeviceByDashboard,
  getSmartTagMachine,
  getTagList,
  tagInteractionData,
} from "../../services";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setSmartTagReportData } from "../Slice";
import { ToastContainer, toast } from "react-toastify";
import { getReportLineChartData } from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/getReportLineChartData";
import ReportsGraphComponent from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/ReportsGraphComponent/ReportsGraphComponent";
import {
  getReportTableData,
  getStorePreviousSelectedData,
  storeSelectedData,
} from "../../../VMS/VmsCompanyDashboard/VmsMachine/service";
import SmartTagReportList from "../../Smartagcomponent/smartTagReportTagList";
import { id } from "date-fns/locale";
import { getDeviceUnits } from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/service";

const MachineReports = () => {
  const Location = useLocation();
  const [Machines, setMachines] = useState([]);
  const [unit, setUnit] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [tagID, setTagID] = useState("");
  const [selectTagIDReport1, setSelectTagIDReport1] = useState("none");
  const [selectTagIDReport2, setSelectTagIDReport2] = useState("none");
  const [reportsTableData, setReportsTableData] = useState([]);
  const [reportLineChartData1, setReportLineChartData1] = useState([]);
  const [reportLineChartData2, setReportLineChartData2] = useState([]);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);
  const [isShimmerMachineslist, setisShimmerMachineslist] = useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [selectTimeReportRange, setSelectTimeReportRange] = useState(15);
  const [smarTagSwitch, setSmarTagSwitch] = useState(false);
  const [smartTagReportStartDate, setSmartTagReportStartDate] = useState(
    smarTagSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [smartTagReportEndDate, setsmartTagReportEndDate] = useState(
    smarTagSwitch ? dayjs() : null
  );
  const [showTime, setShowTime] = useState(false);

  console.log("tagListData", tagListData);
  // shortcut data

  const [selectDeviceData, setSelectDeviceData] = useState([]);
  const [selectUnitData, setSelectUnitData] = useState([]);
  const [selectTagData, setSelectTagData] = useState([]);
  const [isShimmer, setShimmer] = useState(false);

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const params = useParams();

  const [smartTagReportSelectMachine, setSmartTagReportSelectMachine] =
    useState([]);
  const [smartTagReportSelectUnit, setSmartTagReportSelectUnit] = useState([]);
  const [smartTagReportSelectTag, setSmartTagReportSelectTag] = useState([]);
  const [smarTagReportInterval, setSmarTagReportInterval] = useState("1");

  const {
    // smartTagReportSelectMachine,
    // smartTagReportSelectUnit,
    // smartTagReportSelectTag,
    tagClickebleData,
    // smarTagReportInterval,
  } = useSelector((state) => state.smartTagReportSlice);

  const navigate = useNavigate();

  //shortcut data

  useEffect(() => {
    if (params.deviceId) {
      setSelectDeviceData([
        {
          machineId: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectUnitData([
        {
          tagUnit: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectTagData([
        {
          tagId: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagId"
              ]
            : "",
          tagName: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagName"
              ]
            : "",
          tagUnit: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    GetSmartTagMachine();
  }, []);

  const GetSmartTagMachine = async () => {
    setisShimmerUnitlist(true);
    setisShimmerMachineslist(true);
    try {
      const param = {
        dashboard: 3,
        plant: params.plantid,
      };
      const resp = await getDeviceByDashboard(param);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerMachineslist(false);
        }, 1000);

        setMachines(resp.data.payload.device);
        setUnit(resp.data.payload.tag_unit);
      }
    } catch (error) {
      setisShimmerUnitlist(false);
      setisShimmerMachineslist(false);
      console.log("Error from GetSmartTagMachine", error);
    }
  };

  const handleToggleTime = () => {
    setShowTime(!showTime);
    // dispatch(setSmartTagReportData({ showTime: !showTime }));
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleStoreReportSeletedData = async () => {
    const param = {
      company: params.companyid,
      plant: params.plantid,
      dashboard: 2,
      module_display_name: "Reports",
      report_device: smartTagReportSelectMachine.length
        ? smartTagReportSelectMachine
            .filter((f) => f.plantId == params.plantid)
            .map((row) => row.machineId)
        : [],

      tag_unit: smartTagReportSelectUnit
        .filter((f) => f.plantId == params.plantid)
        ?.map((row) => row.tagUnit),
      tag: smartTagReportSelectTag.length
        ? smartTagReportSelectTag.filter((f) => f.plantId == params.plantid)
            .length
          ? smartTagReportSelectTag
              .filter((f) => f.plantId == params.plantid)
              .map((row) => ({
                tag_id: row.tagId,
                color: row.color,
              }))
          : []
        : [],

      interval: smarTagReportInterval,
      is_store: smarTagSwitch,
      left_tag_unit: selectTagIDReport1,
      right_tag_unit: selectTagIDReport2,
      recipe_name: [],
    };
    if (hasRequiredUnits) {
      param["aggrigation"] = Number(selectTimeReportRange);
    }
    try {
      const resp = await storeSelectedData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  useEffect(() => {
    if (smarTagSwitch) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setSmartTagReportStartDate(startDate);
      setsmartTagReportEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setSmartTagReportStartDate(null);
      setsmartTagReportEndDate(null);
    }
  }, [smarTagSwitch]);

  useEffect(() => {
    const findSelectedTag = smartTagReportSelectTag.length
      ? smartTagReportSelectTag.filter((f) => f.plantId == params.plantid)
          .length
        ? smartTagReportSelectTag
            .filter((f) => f.plantId == params.plantid)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      findSelectedTag.length &&
      smartTagReportStartDate &&
      smartTagReportEndDate
    ) {
      handleStoreReportSeletedData();
    } else {
      setReportLineChartData1([]);
      setReportLineChartData2([]);
    }
  }, [
    smarTagSwitch,
    smartTagReportSelectTag,
    smartTagReportSelectMachine,
    smartTagReportSelectUnit,
    smartTagReportStartDate,
    smartTagReportEndDate,
    selectTimeReportRange,
    showTime,
    selectTagIDReport1,
    selectTagIDReport2,
  ]);

  useEffect(() => {
    handleGetStorePreviousReportSelectedData();
  }, []);
  useEffect(() => {
    if (!smarTagSwitch) {
      setTagListData([]);
      setSmartTagReportSelectMachine([]);
      setSmartTagReportSelectTag([]);
      setSmartTagReportSelectUnit([]);
      setSmartTagReportStartDate(null);
      setsmartTagReportEndDate(null);
      setShowTime(false);
      setSmarTagReportInterval("1");
      setSelectTagIDReport1("none");
      setSelectTagIDReport2("none");
      setSelectTimeReportRange(15);
    }
  }, [smarTagSwitch]);

  const handleGetStorePreviousReportSelectedData = async () => {
    const param = {
      company: params.companyid,
      plant: params.plantid,
      dashboard: 2,
      module_display_name: "Reports",
      report_device: [],
      report_tag_unit: [],
      tag: [],
      recipe_name: [],
      tag_unit: [],
    };
    try {
      const resp = await getStorePreviousSelectedData(param);
      if (resp.status == 200 || resp.status == 201) {
        const cocDeviceData = resp.data.payload?.device?.map((row) => {
          return {
            machineId: row.id,

            plantId: params.plantid,
          };
        });
        setSmartTagReportSelectMachine(cocDeviceData);

        // Tag Unit Data
        const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
          return {
            tagUnit: row,
            machineId: "",

            plantId: params.plantid,
          };
        });
        setSmartTagReportSelectUnit(cocTagUnitData);

        // Tag Data
        const cocTagDataData = resp.data.payload?.tag?.map((row) => {
          return {
            tagId: row.id,
            tagUnit: row.tag_unit,
            tagName: row.tag_name,
            machineId: row.device_id,

            plantId: params.plantid,

            color: row.color,
            agg: row.aggregation,
          };
        });
        setSmartTagReportSelectTag(cocTagDataData);

        const IntervalData = resp.data.payload.interval;
        setSmarTagReportInterval(IntervalData);

        const switchData = resp.data.payload?.is_store;
        setSmarTagSwitch(switchData);

        const agg = resp.data.payload?.aggrigation;
        setSelectTimeReportRange(agg);

        setSelectTagIDReport1(resp.data.payload.left_tag_unit || []);
        setSelectTagIDReport2(resp.data.payload.right_tag_unit || []);
      }
    } catch (error) {
      console.log("handleGetStorePreviousSelectedData Error:", error);
    }
  };

  const dispatch = useDispatch();
  const MAX_DATE_RANGE_DAYS = 365;

  const startDateReFormat = smartTagReportStartDate
    ? dayjs(smartTagReportStartDate)
    : null;
  const endDateReFormat = smartTagReportEndDate
    ? dayjs(smartTagReportEndDate)
    : null;
  const formatStartDate = startDateReFormat
    ? dayjs(startDateReFormat).format(dateFormat)
    : "";
  const formatEndDate = endDateReFormat
    ? dayjs(endDateReFormat).format(dateFormat)
    : "";

  const formatStartTime = startDateReFormat
    ? dayjs(startDateReFormat).format(timeFormat)
    : "";
  const formatEndTime = endDateReFormat
    ? dayjs(endDateReFormat).format(timeFormat)
    : "";

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagReportStartDate(null);
      // dispatch(setSmartTagReportData({ smartTagReportStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      setSmartTagReportStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagReportStartDate: newValue }));
    } else if (!endDateReFormat) {
      setSmartTagReportStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagReportStartDate: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() > endDateReFormat.hour() ||
          (newValue.hour() === endDateReFormat.hour() &&
            newValue.minute() >= endDateReFormat.minute()))
      ) {
        setSmartTagReportStartDate(null);
        // dispatch(setSmartTagReportData({ smartTagReportStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setSmartTagReportStartDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagReportStartDate: newValue }));
      }
    } else {
      setSmartTagReportStartDate(null);
      // dispatch(setSmartTagReportData({ smartTagReportStartDate: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setsmartTagReportEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagReportEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      setsmartTagReportEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagReportEndDate: newValue }));
    } else if (!startDateReFormat) {
      setsmartTagReportEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagReportEndDate: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() < startDateReFormat.hour() ||
          (newValue.hour() === startDateReFormat.hour() &&
            newValue.minute() <= startDateReFormat.minute()))
      ) {
        setsmartTagReportEndDate(null);
        // dispatch(setSmartTagReportData({ smartTagReportEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setsmartTagReportEndDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagReportEndDate: newValue }));
      }
    } else {
      setsmartTagReportEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagReportEndDate: null }));
      notifyError("Start date must be less than end date");
    }
  };

  // Assuming that you have a slice for smartTagReportSlice similar to the one you used for VmsReportReportsSelectmachine, smartTagReportSelectUnit, and smartTagReportSelectTag

  const handleSelectMachine = (selectedMachine) => {
    if (params.deviceId) {
      const existingIndex = selectDeviceData.findIndex(
        (color) =>
          color.machineId === selectedMachine.device &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectDeviceData];
        updateMachineId.splice(existingIndex, 1);
        setSelectDeviceData(updateMachineId);
      } else {
        // If the machine is not selected, add it
        setSelectDeviceData((prev) => {
          return [
            ...prev,
            {
              machineId: selectedMachine.device,
              plantId: params.plantid,
            },
          ];
        });
      }
    }

    const existingIndex = smartTagReportSelectMachine.findIndex(
      (color) =>
        color.machineId === selectedMachine.device &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagReportSelectMachine];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagReportSelectMachine(updateMachineId);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectMachine: updateMachineId,
      //   })
      // );
    } else {
      // If the machine is not selected, add it
      const newDevice = {
        machineId: selectedMachine.device,
        plantId: params.plantid,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagReportSelectMachine((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectMachine: [
      //       ...smartTagReportSelectMachine,
      //       {
      //         machineId: selectedMachine.device,
      //         plantId: params.plantid,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportsTagUnit = (selectedUnit) => {
    if (params.deviceId) {
      const existingIndex = selectUnitData.findIndex(
        (color) =>
          color.tagUnit === selectedUnit.tag_unit &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectUnitData];
        updateMachineId.splice(existingIndex, 1);
        setSelectUnitData(updateMachineId);
      } else {
        setSelectUnitData((prev) => {
          return [
            ...prev,
            {
              tagUnit: selectedUnit.tag_unit,
              machineId: selectedUnit.device,
              plantId: params.plantid,
            },
          ];
        });
        // If the unit is not selected, add it
      }
    }
    const existingIndex = smartTagReportSelectUnit.findIndex(
      (color) =>
        color.tagUnit === selectedUnit.tag_unit &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagReportSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagReportSelectUnit(updateMachineId);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectUnit: updateMachineId,
      //   })
      // );
      setTagListData([]);
    } else {
      // If the unit is not selected, add it
      const newDevice = {
        tagUnit: selectedUnit.tag_unit,
        machineId: selectedUnit.device,
        plantId: params.plantid,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagReportSelectUnit((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectUnit: [
      //       ...smartTagReportSelectUnit,
      //       {
      //         tagUnit: selectedUnit.tag_unit,
      //         machineId: selectedUnit.device,
      //         plantId: params.plantid,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportTag = (row, color) => {
    if (params.deviceId) {
      const existingIndex = selectTagData.findIndex(
        (color) => color.tagId === row.id && color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectTagData];
        updateMachineId.splice(existingIndex, 1);
        setSelectTagData(updateMachineId);
      } else {
        // Add the object to the state array if it doesn't exist
        setSelectTagData((prev) => {
          return [
            ...prev,
            {
              tagId: row.id,
              tagName: row.tag_name,
              tagUnit: row.tag_unit,
              machineId: row.device,
              plantId: params.plantid,
              color: color,
            },
          ];
        });
      }
      setSelectTagIDReport1("none");
      setSelectTagIDReport2("none");
    }
    const existingIndex = smartTagReportSelectTag.findIndex(
      (color) => color.tagId === row.id && color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagReportSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagReportSelectTag(updateMachineId);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectTag: updateMachineId,
      //   })
      // );
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        tagId: row.id,
        tagName: row.tag_name,
        tagUnit: row.tag_unit,
        machineId: row.device,
        plantId: params.plantid,
        color: color,
        agg: row.aggregation,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagReportSelectTag((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagReportSelectTag: [
      //       ...smartTagReportSelectTag,
      //       {
      //         tagId: row.id,
      //         tagName: row.tag_name,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         plantId: params.plantid,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }
    setSelectTagIDReport1("none");
    setSelectTagIDReport2("none");
  };

  const getTagListData = async (selectedMachines, selectedTagUnits) => {
    setisShimmerdevicelist(true);

    try {
      const params = {
        device_id: selectedMachines,
        tag_unit: selectedTagUnits,
      };

      const resp = await getTagList(params);

      if (resp.status === 200 || resp.status === 201) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTagListData(resp.data.payload);
      }
    } catch (error) {
      // Handle API call error if needed
      setisShimmerdevicelist(false);

      console.error("Error fetching tag list:", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      if (selectTagData.length > 0 && selectUnitData.length) {
        getTagListData(
          selectTagData.map((e) => e.machineId),
          selectUnitData.map((e) => e.tagUnit)
        );
      }
    } else {
      const findPlantByReportsSelectmachine = smartTagReportSelectMachine.length
        ? smartTagReportSelectMachine
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.machineId)
            .filter((machineId) => machineId !== undefined)
        : [];

      const findPlantByReportsSelectTagUnit = smartTagReportSelectUnit.length
        ? smartTagReportSelectUnit
            .filter((f) => f.plantId === params.plantid)
            .map((f) => f.tagUnit)
            .filter((tagUnit) => tagUnit !== undefined)
        : [];

      if (findPlantByReportsSelectmachine.length > 0) {
        getTagListData(
          findPlantByReportsSelectmachine,
          findPlantByReportsSelectTagUnit
        );
      } else {
        // Handle the case when no machines are selected (unselect all).
        // You may want to reset or handle the state accordingly.
        setTagListData([]);
      }

      if (findPlantByReportsSelectmachine.length > 0) {
        handleGetUnitsData(findPlantByReportsSelectmachine);
      } else {
        setUnitsData([]);
      }
    }
  }, [
    smartTagReportSelectMachine,
    smartTagReportSelectUnit,
    params.gatewayid,
    selectTagData,
    selectUnitData,
  ]);

  const getReportLineChartAPIReports = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      interval: smarTagReportInterval,
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }

    const selectedTag = smartTagReportSelectTag.find(
      (tag) => tag.tagId == tagId[0]
    );
    if (selectedTag && selectedTag.agg) {
      paramReq["aggregate"] = Number(selectTimeReportRange);
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmer);
    } catch (error) {
      console.log("errror", error);
    }
  };
  // Report Line Chart
  useEffect(() => {
    if (selectTagIDReport1 != "none" && formatStartDate && formatEndDate) {
      getReportLineChartAPIReports(
        [selectTagIDReport1],
        formatStartDate,
        formatEndDate,
        formatStartTime,
        formatEndTime,
        setReportLineChartData1,
        showTime
      );
    } else {
      setReportLineChartData1([]);
    }
  }, [
    selectTagIDReport1,
    formatStartDate,
    formatEndDate,
    formatStartTime,
    formatEndTime,
    showTime,
    smarTagReportInterval,
    selectTimeReportRange,
  ]);

  useEffect(() => {
    if (selectTagIDReport2 != "none" && formatStartDate && formatEndDate) {
      getReportLineChartAPIReports(
        [selectTagIDReport2],
        formatStartDate,
        formatEndDate,
        formatStartTime,
        formatEndTime,
        setReportLineChartData2,
        showTime
      );
    } else {
      setReportLineChartData2([]);
    }
  }, [
    selectTagIDReport2,
    formatStartDate,
    formatEndDate,
    formatStartTime,
    formatEndTime,
    showTime,
    smarTagReportInterval,
    selectTimeReportRange,
  ]);
  useEffect(() => {
    if (params.deviceId) {
      if (selectTagData.length) {
        addInteractionData(selectTagData.map((Id) => Id.tagId));
      }
    } else {
      const findSelectedTag = smartTagReportSelectTag.length
        ? smartTagReportSelectTag.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagReportSelectTag
              .filter((f) => f.plantId === params.plantid)
              .map((row) => row.tagId)
          : []
        : [];

      if (findSelectedTag.length) {
        addInteractionData(findSelectedTag);
      }
    }
    addInteractionData();
  }, [smartTagReportSelectTag, selectTagData]);

  const addInteractionData = async () => {
    const param = {
      plant: params.plantid,
      tag: smartTagReportSelectTag?.map((e) => e.tagId),
    };
    try {
      const resp = await tagInteractionData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  // Report Table Data
  const getReportTableDataApi = async (tagID, startDate, endDate) => {
    const paramReq = {
      tag_id: tagID,
      start_date: `${formatStartDate} 00:00:00`,
      end_date: `${formatEndDate} 23:59:59`,
    };

    if (showTime) {
      paramReq["start_date"] = `${formatStartDate} ${formatStartTime}`;
      paramReq["end_date"] = `${formatEndDate} ${formatEndTime}`;
    }

    try {
      const resp = await getReportTableData(paramReq);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setReportsTableData(resp.data.payload);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching report table data:", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      if (selectTagData.length && formatStartDate && formatEndDate) {
        console.log("selectTagDataselectTagDataselectTagData", selectTagData);
        getReportTableDataApi(
          selectTagData.map((Id) => Id.tagId),
          formatStartDate,
          formatEndDate
        );
      } else {
        setReportsTableData([]);
      }
    } else {
      const findSelectedTag = smartTagReportSelectTag.length
        ? smartTagReportSelectTag.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagReportSelectTag
              .filter((f) => f.plantId === params.plantid)
              .map((row) => row.tagId)
          : []
        : [];

      console.log("findSelectedTag", findSelectedTag);
      if (findSelectedTag.length && formatStartDate && formatEndDate) {
        getReportTableDataApi(findSelectedTag, formatStartDate, formatEndDate);
      } else {
        setReportsTableData([]);
      }
    }
  }, [
    smartTagReportSelectTag,
    selectTagData,
    formatStartDate,
    formatEndDate,
    formatStartTime,
    formatEndTime,
  ]);

  const handleGetUnitsData = async (id) => {
    const params = {
      device: id,
    };
    try {
      const resp = await getDeviceUnits(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitsData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const requiredUnits = ["kVAH", "kVARH", "kWH", "agg"];

  const hasRequiredUnits = smartTagReportSelectUnit.some((unit) =>
    requiredUnits.some((requiredUnit) =>
      unit.tagUnit.toLowerCase().includes(requiredUnit.toLowerCase())
    )
  );
  console.log("hasRequiredUnits", hasRequiredUnits, smartTagReportSelectUnit);
  return (
    <>
      <div className="page-wraper">
        <div className="page-header">
          <div className="page-back-btn d-flex justify-content-between">
            <div className="page-header page-header-analytics">
              <div
                onClick={() =>
                  navigate(
                    `/Smarttag-machinedashboard/company/${params.companyid}/plant/${params.plantid}`
                  )
                }
                className="page-back-btn"
              >
                <ArrowBackIcon />
                <span>Overview - Reports</span>
              </div>
            </div>
            <div className="d-flex gap-2">
              <>
                <h6 className="AGG-dropdown">AGG.</h6>
                <Box
                  sx={{ minWidth: 200 }}
                  style={{ float: "right", marginRight: "13px" }}
                >
                  <select
                    className="form-input-class agg-input"
                    style={{
                      padding: "16px 8px",
                      borderRadius: "4px",
                    }}
                    id="selectedClass"
                    value={selectTimeReportRange}
                    onChange={(e) => setSelectTimeReportRange(e.target.value)}
                    required
                  >
                    <option value={15} selected disabled hidden>
                      15 Min
                    </option>
                    <option value={15}>15 Min</option>
                    <option value={30}>30 Min</option>
                    <option value={60}>Hourly</option>
                    <option value={1}>Daily</option>
                    <option value={7}>Weekly</option>
                  </select>
                </Box>
              </>

              <h6 className="AGG-dropdown" style={{ marginTop: "21px" }}>
                Interval
              </h6>
              <select
                className="form-input-class"
                style={{
                  padding: "14px 8px",
                  borderRadius: "4px",
                  // marginRight: "20px",
                }}
                id="selectedClass"
                value={smarTagReportInterval}
                onChange={(e) => setSmarTagReportInterval(e.target.value)}
                required
              >
                <option value="1" selected disabled hidden>
                  1 min
                </option>
                <option value="1">1 Min</option>
                <option value="3">3 Min</option>
                <option value="5">5 Min</option>
                <option value="15">15 Min</option>
                <option value="60">Hourly</option>
                <option value="1440">Daily</option>
              </select>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" checked={showTime} />}
                  label="Time"
                  labelPlacement="top"
                  onChange={handleToggleTime}
                />
              </FormGroup>
              <SmartTagOverviewReport
                startDateReFormat={startDateReFormat}
                endDateReFormat={endDateReFormat}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                time={showTime}
              />
            </div>
          </div>
        </div>
        <div className="pt-2 consumption-grid-card">
          <SmartTagReportList
            formatStartDate={formatStartDate}
            formatEndDate={formatEndDate}
            formatStartTime={formatStartTime}
            formatEndTime={formatEndTime}
            time={showTime}
            machines={Machines}
            units={unitsData}
            handleSelectMachine={handleSelectMachine}
            handleSelectTagUnit={handleSelectReportsTagUnit}
            handleSelectTag={handleSelectReportTag}
            tagListData={tagListData}
            isShimmerdevicelist={isShimmerdevicelist}
            isShimmerUnitlist={isShimmerUnitlist}
            isShimmerMachineslist={isShimmerMachineslist}
            smartTagSelectmachine={
              params.deviceId
                ? selectDeviceData
                : smartTagReportSelectMachine.length
                ? smartTagReportSelectMachine.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            smartTagSelectTagUnit={
              params.deviceId
                ? selectUnitData
                : smartTagReportSelectUnit.length
                ? smartTagReportSelectUnit.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            snartTagSelectTag={
              params.deviceId
                ? selectTagData
                : smartTagReportSelectTag.length
                ? smartTagReportSelectTag.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            agg={Number(selectTimeReportRange)}
            interval={smarTagReportInterval}
            smarTagSelectSwitch={smarTagSwitch}
            handleToggleTime={() => setSmarTagSwitch(!smarTagSwitch)}
          />
          <ReportsGraphComponent
            tableData={reportsTableData}
            selectedMachine={
              params.deviceId
                ? selectTagData
                : smartTagReportSelectTag.length
                ? smartTagReportSelectTag.filter(
                    (f) => f.gatewayid === params.gatewayid
                  )
                : []
            }
            selectTagID1={selectTagIDReport1}
            selectTagID2={selectTagIDReport2}
            setSelectTag1={setSelectTagIDReport1}
            setSelectTag2={setSelectTagIDReport2}
            LineChartData1={reportLineChartData1}
            LineChartData2={reportLineChartData2}
            isShimmerLineChartReports={isShimmer}
            selectAggReport={selectTimeReportRange} // aggreagate
            selectIntervalReport={smarTagReportInterval} // interval
            hasRequiredUnits={hasRequiredUnits}
            selectTag={smartTagReportSelectTag}
          />
        </div>
      </div>
    </>
  );
};

export default MachineReports;
