import axios from "axios";
import { urls } from "../config/urls";
import store from "../redux/store/store";
import { handleEmailSend } from "../components/Email/emailJob";

export const request = axios.create({
  baseURL: urls.apiBaseUrl + "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(
  function (config) {
    const user = store.getState().userState;
    if (user?.token) {
      config.headers.Authorization = `token ${user?.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Interceptor for axiosInstance
request.interceptors.response.use(
  (response) => {
    // Return the resolved response as a promise
    return Promise.resolve(response);
  },
  async (error) => {
    const user = store.getState().userState;
    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      // Clear local storage and redirect to the home page
      localStorage.clear();
      window.location.href = "/";
    }

    // Handle network errors
    // if (error.code === "ERR_NETWORK") {
    //   console.log("Network error:", error);
    //   await handleEmailSend({ ...error, user });
    // }

    console.log("useruseruser", user);
    // Handle HTTP 500 Internal Server Error
    // if (error.response && error.response.status === 500) {
    //   console.log("HTTP 500 error:", error);
    //   await handleEmailSend({...error, user});
    // }

    // Propagate the error as a rejected promise
    return Promise.reject(error);
  }
);

export const multipartRequest = axios.create({
  baseURL: urls.apiBaseUrl + "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

multipartRequest.interceptors.request.use(
  function (config) {
    const user = store.getState().userState;
    if (user?.token) {
      config.headers.Authorization = `token ${user?.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Interceptor for axiosInstance
multipartRequest.interceptors.response.use(
  (response) => {
    // Return the resolved response as a promise
    return Promise.resolve(response);
  },
  async (error) => {
    const user = store.getState().userState;
    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      // Clear local storage and redirect to the home page
      localStorage.clear();
      window.location.href = "/";
    }

    // Handle network errors
    if (error.code === "ERR_NETWORK") {
      console.log("Network error:", error);

      await handleEmailSend({ ...error, user });
    }

    console.log("HTTP", error);
    // Handle HTTP 500 Internal Server Error
    if (error.response && error.response.status === 500) {
      console.log("HTTP 500 error: multipart", error);
      await handleEmailSend({ ...error, user });
    }

    // Propagate the error as a rejected promise
    return Promise.reject(error);
  }
);
// // Interceptor for axiosInstance
// multipartRequest.interceptors.response.use(
//   (response) => {
//     // TODO: Something if needed
//     return Promise.resolve(response);
//   },
//   async (error) => {
//     console.log("HTTP", error);
//     if (error.response && error.response.status === 401) {
//       // Redirect to "/" if the request returns 401 unauthorized
//       localStorage.clear()
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );
