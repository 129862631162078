import { request, multipartRequest } from "../../api/api"


export const getWarningMsg = () => {
    return request.get(`/getWarningMessage/`)
}
// Get Tags Groups...DeviceManagement

export const getTagsGroups = (params) => {
    return request.post('/smarttag/overview/getTagsGroups/', params)
}
// count

export const deviceCount = () => {
    return request.get(`/superadmin_dashboard/device_management/getTopBarCount/`)
}

export const superAdminDeviceCount = () => {
    return request.get(`/superadmin_dashboard/getTopBarCount/`)
}

export const autoAssignData = (params) => {
    return request.post(`/device_configuration/device/bridge/autoassign/`, params)
}


//filter
export const getDevice = (params) => {
    return request.get(`device/getDevice/?search=${params.device_name || params.device_description || params.uid || params.company__company_name}`);
}

export const gatewayCloudStatus = () => {
    return request.get(`/device/getGatewayCloudStstus/`)
}
// search
export const getDevicePlant = (params) => {
    return request.get(`device/getDevice/?search=${params}`);
}

export const getDeviceCount = (params) => {
    return request.get(`device/getDevice/`);
}

export const getClientDashboardCount = () => {
    return request.get('/superadmindashboard/getcount/');
}

// export const getDeviceById = (id) => {
//     return request.post(`/device/getDevice/${id}/`);
// }

export const deviceTypeApi = () => {
    return request.get('device/getDeviceType/');
}

export const licenseApi = () => {
    return request.get('device/getLicense/');
}

export const addDeviceSubmit = (data) => {
    return request.post('/device/addDevice/', data);
}

export const editDevice = (id, data) => {
    return request.put(`/device/updateDevice/${id}/`, data);
}

export const deleteDevice = (id) => {
    return request.put(`/device/deleteDevice/${id}/`);
}

export const deviceExcel = (params) => {
    return request.post(`/device/exportDeviceManagementExcelReport/`, params)
}


export const getGatewayId = () => {
    return request.get(`/device/getAllGateway/`)
}

export const getGateway = (page, pageSize) => {
    return request.get(`/device/getGateway/?page=${page + 1}&page_size=${pageSize}`)
}

// filetr gateway
export const filetrAPIDeviceManagement = (param, page, pageSize) => {
    return request.post(`/device/filterDeviceManagementViewSet/?page=${page + 1}&page_size=${pageSize}`, param)
}


export const addGateway = (params) => {
    return request.post(`/device/addGateway/`, params)
}

export const deleteGateway = (id) => {
    return request.put(`/device/deleteGateway/${id}/`)
}

export const updateGatewayAPI = (id, params) => {
    return request.put(`/device/updateGateway/${id}/`, params)
}

export const addMachineData = (params) => {
    return request.post(`/device/addMachine/`, params)
}

export const getMachineData = (params) => {
    return request.post(`/device/getMachine/`, params)
}

export const updateMachineData = (id, params) => {
    return request.put(`/device/updateMachine/${id}/`, params)
}

export const deleteMachineData = (id) => {
    return request.put(`/device/deleteMachine/${id}/`)
}

export const getDeviceTypeData = (renewableEnergyQuery) => {
    return request.get(`/device/getDeviceType/?renewable_energy=${renewableEnergyQuery}`);
  };
  

export const addDeviceDataAPI = (params) => {
    return request.post(`/device/addDevice/`, params)
}

export const getDeviceData = (params) => {
    return request.post(`/device/getDevice/`, params)
}

export const updateDeviceAPI = (id, params) => {
    return request.put(`/device/updateDevice/${id}/`, params)
}

export const deleteDeviceAPI = (id) => {
    return request.put(`/device/deleteDevice/${id}/`)
}

export const getGatewayAssign = (params) => {
    return request.post(`/plant_management/getGatewayForAssign/`, params)
}

export const getDeviceByMachineData = (params) => {
    return request.post(`/device_configuration/device/getDeviceByMachine/`, params)
}

export const createVirtualUid = (params) => {
    return request.post(`/device/createVirtualUID/`, params)
}
// device_configuration

//Alarm device_configuration
export const getAlarmConfiguration = (data) => {
    return request.post('/device_configuration/device/getAlarmConfiguration/', data)
}

// tag device_configuration
export const getTagConfiguration = (data) => {
    return request.post('/device_configuration/device/getTagConfiguration/', data)
}

// Export CSV
export const exportCSVConfiguration = (data) => {
    return request.post('/device_configuration/device/exportTagData/', data)
}

// import CSV
export const importCSVConfiguration = (data) => {
    return multipartRequest.post('/device_configuration/device/importTagData/', data)
}

// Add Configuration
export const addConfigurationApi = (data) => {
    return multipartRequest.post('/device_configuration/device/addManualTagconfiguration/', data)
}

// Edit Configuration
export const updateConfigurationApi = (id, data) => {
    return request.put(`/device_configuration/device/editTagconfiguration/${id}/`, data)
}

// Bridge
export const getDeviceAndHicloudTag = (data) => {
    return request.post('/device_configuration/device/getDeviceTag/', data)
}

//create Bridge
export const createBridgeDeviceAndHicloudTag = (data) => {
    return request.post('/device_configuration/device/bridge/createBridge/', data)
}


export const getSiteConfigData = (data) => {
    return request.post(`/user/getSiteConfig/`, data)
}

//Client Management - Company list get api
export const getcompanyList = (search) => {
    return request.get(`/client_managent/getCompanyList/?search=${search}`)
}

//Client Management - sort by Company list get api
export const getSortbycompanyList = (search) => {
    return request.get(`/client_managent/getCompanyListByName/?search=${search}`)
}

//Client Management - Company add api
export const addCompany = (data) => {
    return request.post('/client_managent/addCompany/', data)
}

//Client Management - Company edit api
export const editCompany = (id, data) => {
    return request.put(`/client_managent/updateCompany/${id}/`, data)
}

//Client Management - Company delete api
export const deleteCompany = (id) => {
    return request.put(`/client_managent/deleteCompany/${id}/`)
}

//Client Management && Plant management - get plant api
export const getPlantByCompany = (data) => {
    return request.post('/plant_management/getPlant/', data)
}

//Client Management - get device api
export const getDeviceByCompany = (data) => {
    return request.post('/client_managent/getDeviceList/', data)
}

//Client Management - invite User api
export const inviteUserByCompany = (data) => {
    return request.post('/client_managent/inviteUser/', data)
}

export const updateCompanyActivation = (id) => {
    return request.put(`/client_managent/updateCompanyActivation/${id}/`)
}

export const getCompanyUserManage = (params, searchUserDataList) => {
    return request.post(`/client_managent/getCompanyUser/?search=${searchUserDataList.first_name || searchUserDataList.last_name || searchUserDataList.department__department_name || searchUserDataList.user_role__name || searchUserDataList.email}`, params)
}

export const getCompanyByDetails = (params) => {
    return request.post(`/client_managent/getCompanyDetail/`, params)
}

export const getDeviceCloudStatusData = () => {
    return request.get(`/client_managent/getDeviceCloudStatus/`)
}
//Company List
export const getcompanyListPlantManegement = () => {
    return request.get('/client_managent/getCompanyList/')
}
// Plant management
// Plant management - Add Plant
export const addPlantData = (data) => {
    return request.post('/plant_management/addPlant/', data);
}

//Plant management - delete api  
export const deletePlant = (id) => {
    return request.put(`/plant_management/deletePlant/${id}/`)
}

//Plant management - edit api 
export const editPlant = (id, data) => {
    return request.put(`/plant_management/updatePlant/${id}/`, data);
}

//Plant management - AssignDevice api 
export const assignDeviceApi = (data) => {
    return request.post('/plant_management/assignDevice/', data);
}

//Plant management - DeviceByDevice api 
export const DeviceByPlantApi = (data) => {
    return request.post('/plant_management/deleteAssignDevice/', data);
}

// get Plant By Id
export const getPlantByID = (data) => {
    return request.post('/plant_management/getAllPlant/', data);
}

// export const getDeviceAssign = (params) => {
//     return request.post(`/plant_management/getDeviceForAssign/`, params)
// }

// User management - get api 
export const getUser = (params) => {
    return request.get(`/user_management/getUserManagement/?search=${params.first_name || params.last_name || params.department__department_name || params.user_role__name || params.email}`);
}
// User management - get department list
export const getUserDepartment = (data) => {
    return request.post('access_management/getDepartment/', data);
}
//User management - add department
export const adduserDepartment = (data) => {
    return request.post('/user_management/addUserManagement/', data)
}

//User management - upadate department
export const updateUserDepartment = (id, data) => {
    return request.put(`/user_management/updateUserManagement/${id}/`, data)
}
//Plant management - edit api 
// export const editPlant = (id, data) =>{
//     return request.put(`/plant_management/updatePlant/${id}/`, data);
// }
//User management - delete department
export const deleteUserDepartment = (id) => {
    return request.put(`/user_management/deleteUserManagement/${id}/`)
}

//User management - search 
export const searchData = (params) => {
    return request.get(`/user_management/getUserManagement/?search=${params.first_name || params.last_name || params.department__department_name || params.user_role__name || params.email}`);
}

export const exportUserReport = (params) => {
    return request.post(`/user_management/exportUserManagementExcelReport/`, params)
}

export const deleteMultipleUser = (params) => {
    return request.post(`/user_management/deleteMultipleUserManagement/`, params)
}

export const createAdmin = (params) => {
    return request.post(`/user_management/createNewAdminUser/`, params)
}
//Access management - get department list
export const getDepartment = (data) => {
    return request.post('/access_management/getDepartment/', data);
}

export const getDepartmentBycompany = (data, serchParam) => {
    return request.post(`/access_management/getAccessManagementByCompany/?search=${serchParam.department__department_name || serchParam.role__role_name}`, data);
}
//Access management - add department
export const addDepartment = (data) => {
    return request.post('/access_management/addDepartment/', data)
}

//Access Management -- delete department
export const deleteDepartment = (id) => {
    return request.put(`/access_management/deleteDepartment/${id}/`)
}

//Access Management -- Edit Department

export const editDepartManage = (id, params) => {
    return request.put(`/access_management/updateDepartment/${id}/`, params)
}

//Access Management -- Add role

export const addRoleAPI = (params) => {
    return request.post(`/access_management/addRole/`, params)
}

//Access Management -- delete role

export const deleteRole = (id) => {
    return request.put(`/access_management/deleteRole/${id}/`)
}

//Access Management --- update Role

export const editRoleManage = (id, params) => {
    return request.put(`/access_management/updateRole/${id}/`, params)
}

//Access Management -- accessModule

export const getAccessModuleByCompany = (id) => {
    return request.get(`/access_management/getAccessModule/?company_id=${id}`)
}

export const getAccessModule = (id) => {
    return request.get(`/access_management/getAccessModule/`)
}

export const exportDepartmentExcel = (params) => {
    return request.post(`/access_management/exportDepartmentManagementExcelReport/`, params)
}

export const exportAccessManagement = (params) => {
    return request.post(`/access_management/exportAccessManagementExcelReport/`, params)
}
// add Accessmanagement
export const addAccessManagement = (data) => {
    return request.post('/access_management/addAccessManagement/', data)
}

// get access management data
export const getAccessManagement = (param) => {
    return request.get(`/access_management/getAccessManagement/?search=${param.department__department_name || param.role__role_name}`)
}

//  get access managementBy Id data
export const getAccessManagementById = (id) => {
    return request.get(`/access_management/getAccessManagement/${id}/`)
}

// delete access management data
export const deleteAccessManagement = (id) => {
    return request.put(`/access_management/deleteAccessManagement/${id}/`)
}

// edit access management data
export const editAccessManagement = (id, data) => {
    return request.put(`/access_management/updateAccessManagement/${id}/`, data)
}

export const getAccessmanagement = (search) => {
    return request.get(`/device/getDevice/?search=${search}`)
}

export const plantDashboardCount = (param) => {
    return request.post(`/dashboard/plantDashboardCount/`, param)
}

export const companyDashboardCountData = (params) => {
    return request.post(`/dashboard/companyDashboardCount/`, params)
}


// Subscription Management

export const subscriptionPlan = () => {
    return request.get(`/subscription/getSubscriptionPLan/`)
}

export const contactUsDetail = (params) => {
    return request.post(`/subscription/contactUs/`, params)
}

export const getPurchaseDevice = (params) => {
    return request.post(`/subscription/getPurchaseDevicePage/`, params)
}

export const getCotationPlan = (params) => {
    return request.post(`/subscription/getQuotationForHikemm/`, params)
}

// company list
export const getCompanyList = (params) => {
    return request.post(`/dashboard/getCompanyList/`, params)
}

//plant list
export const getPlantList = (params) => {
    return request.post(`/dashboard/getPlant/`, params)
}


export const sendErrorEmail = (params) => {
    return request.post(`/sendErrorEmail/`, params)
}