import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { images } from "../../../config";
import { setHeaderDetails } from "../../../container/Header/headerSlice";
import { useDispatch } from "react-redux";

const SmarTagMap = ({ location, companyId, plantAllData,plantName,plantId }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(2);

  console.log("location",location)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const parsedLocation = location ? location.split(",").map(Number) : [0, 0];
    setCenter({ lat: parsedLocation[0], lng: parsedLocation[1] });
    setZoom(parsedLocation[0] !== 0 && parsedLocation[1] !== 0 ? 30 : 2);

    const matchingPlant = plantAllData.find((plant) => {
      if (!plant.plant_location) return false;
      const [lat, lng] = plant.plant_location.split(",").map(Number);
      return lat === parsedLocation[0] && lng === parsedLocation[1];
    });

    if (matchingPlant) {
      setSelectedLocation({ lat: parsedLocation[0], lng: parsedLocation[1] });
      setSelectedPlant(matchingPlant);
      fetchAddress(parsedLocation[0], parsedLocation[1]);
      setShowInfoWindow(true);
    }
  }, [location, plantAllData]);


  // useEffect (()=>{
  //   if(location == null){
  //     setShowInfoWindow(false);
  //   }
  // },[showInfoWindow])

  useEffect(() => {
    const scriptId = "google-maps-api-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAhALM3q3fyD_1T7sHMd2kSXwHAUSbSvf0&libraries=places`;
      script.id = scriptId;
      script.onload = () => setIsScriptLoaded(true);
      document.body.appendChild(script);
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  const fetchAddress = (lat, lng) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API not loaded");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        console.error("Geocode error:", status);
        setAddress("Address not found");
      }
    });
  };

  const mapContainerStyle = {
    height: "650px",
    width: "100%",
  };

  const mapOptions = {
    mapTypeId: "satellite",
    disableDefaultUI: true,
    zoomControl: true,
  };

  return (
    <div>
      {isScriptLoaded && center && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={zoom}
          options={mapOptions}
        >
          {plantAllData.map((plant) => {
            if (!plant.plant_location) return null;
            const [lat, lng] = plant.plant_location.split(",").map(Number);
            const position = { lat, lng };

            return (
              <Marker
                key={plant.id}
                position={position}
                onClick={() => {
                  setShowInfoWindow(true);
                  setSelectedLocation(position);
                  setSelectedPlant(plant);
                  fetchAddress(lat, lng);
                  setCenter(position);
                  setZoom(16);
                }}
              />
            );
          })}

          {showInfoWindow && selectedLocation && selectedPlant && (
            <InfoWindow
              position={selectedLocation}
              onCloseClick={() => setShowInfoWindow(false)}
            >
              <>
                <img
                  src={images.closeIcon}
                  onClick={() => setShowInfoWindow(false)}
                  alt="close-icon"
                  className="close-img-map"
                />
                <div className="d-flex gap-2">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                         `/Smarttag-machinedashboard/company/${companyId}/plant/${plantId}`
                      );
                      dispatch(
                        setHeaderDetails({
                          headerName: plantName,
                        })
                      );
                    }}
                  >
                    <h6>
                      <b>{plantName}</b>
                    </h6>
                    <p>{address}</p>
                  </div>
                  {/* <div>
                    <img
                      src={
                        selectedPlant && selectedPlant.plant_image
                          ? selectedPlant.plant_image
                          : images.plantImage
                      }
                      alt="Google Street View"
                      style={{ width: "113px", height: "85%" }}
                    />
                  </div> */}
                </div>
              </>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default SmarTagMap;
