import { sendErrorEmail } from "../../container/SuperAdminDashboard/service";

function currentDateAndTime() {
  const rawDate = new Date();
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    weekday: "long",
  };
  const formattedDate = rawDate.toLocaleString("en-US", options);
  return formattedDate;
}

export const handleEmailSend = async (error) => {
  const emailDetails = {
    hostUrl: process.env.REACT_APP_API_URL,
    pageUrl: window.location.href,
    apiEndPoint: error?.config?.url,
    header: error?.config?.headers?.Authorization,
    requestBody: error?.config?.data,
    errorDetails: error.message,
    userId: error?.user?.id,
    emailId: error?.user?.email,
    dateTime: currentDateAndTime(),
    userAgent: navigator.userAgent,
    ipAddress: "-",
  };

  try {
    await sendErrorEmail(emailDetails);
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
