import React, { useEffect, useState } from "react";
import BPCLAvailability from "../../../components/BPCL/BPCLavailability/BPCLavailability";
import BPCLGeneration from "../../../components/BPCL/BPCLGeneration/BPCLGeneration";
import BPCLWeathers from "../../../components/BPCL/BPCLWeathers/BPCLWeathers";
import BPCLInverters from "../../../components/BPCL/BPCLInverters/BPCLInverters";
import { Grid, Box, Typography } from "@mui/material";
import "../../../container/BPCL/BPCL-ProcessDashboard/BPCL-ProcessDashboard.css";
import {
  getHikemmREPlantDashboardGeneration,
  getHikemmREPlantDashboardGenerationBox,
  getHikemmREPlantDashboardInverter,
  getHikemmREPlantDashboardDateWiseInverter,
  getPlantProcessDashboardCo2Data,
  getPlantProcessDashboardCostSavingData,
  getPlantProcessDashboardPlantPeak,
  getPlantProcessDashboardWeatherGraphData,
  getPlantProcessDashboardWeatherPeakData,
  getHikemmREPlantDashboardDateWiseGeneration,
} from "../services";
import { useNavigate, useParams } from "react-router-dom";
import BPCLCost from "../../../components/BPCL/BPCL-Cost/BPCLCost";

import { useSelector } from "react-redux";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";
import OverViewMachineMqttData from "../../SmartTag/MachineDashboard/mqttDataMachineDashboard/BpclmqttData";

const BPCLProcessDashboard = () => {
  const [plantPeakData, setPlantPeakData] = useState([]);
  const [plantPeakDateWiseData, setPlantPeakDateWiseData] = useState([]);

  const param = useParams();
  const [dateRange, setDateRange] = React.useState(1);
  const [generationTime, setGenerationTime] = React.useState(1);
  const [generationValue, setGenerationValue] = React.useState(1);
  const [generationData, setGenerationData] = useState([]);
  const [generationDateWiseData, setGenerationDateWiseData] = useState([]);
  const [generationBoxData, setGenartionBoxData] = useState({});
  const [weatherTime, setWeatherTime] = useState(1);
  const [weatherValue, setWeatherValue] = useState(1);
  const [weatherGraphData, setWeatherGraphData] = useState([]);
  const [weatherGraphDateWiseData, setWeatherGraphDateWiseData] = useState([]);
  const [inverterData, setInverterData] = useState([]);
  const [inverterDateWiseData, setInverterDateWiseData] = useState([]);
  const [inverterTime, setInverterTime] = useState(1);
  const [costData, setCostData] = useState([]);
  const [costDateWiseData, setCostDateWiseData] = useState([]);
  const [costTime, setCostTime] = useState(1);
  const [co2Data, setCo2Data] = useState([]);
  const [co2DateWiseData, setCo2DateWiseData] = useState([]);
  const [co2Time, setCo2Time] = useState(1);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [availabilityPiechart, setAvailabilityPiechart] = useState([]);
  const [weatherRealTime, setWeatherRealTime] = useState([]);
  const [weatherRealData, setWeatherRealData] = useState([]);
  const [inverterRealTimeData, setInverterRealTimeData] = useState([]);
  const [costStartDate, setCostStartDate] = useState(null);
  const [costEndDate, setCostEndDate] = useState(null);
  const [co2StartDate, setCo2StartDate] = useState(null);
  const [co2EndDate, setCo2EndDate] = useState(null);
  const [availabilityStartDate, setAvailabilityStartDate] = useState(null);
  const [availabilityEndDate, setAvailabilityEndDate] = useState(null);
  const [inverterStartDate, setInverterStartDate] = useState(null);
  const [inverterEndDate, setInverterEndDate] = useState(null);
  const [generationStartDate, setGenerationStartDate] = useState(null);
  const [generationEndDate, setGenerationEndDate] = useState(null);
  const [weatherStartDate, setWeatherStartDate] = useState(null);
  const [weatherEndDate, setWeatherEndDate] = useState(null);

  const { selectTimeZoneProfile, is_renewable_energy } = useSelector(
    (state) => state.userState
  );

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  console.log("mergedArray", mergedArray);
  const mqttDataReduce = sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  let tagValue = mergedArray.length
    ? mergedArray.find(
        (f) =>
          f.device__uid === availabilityPiechart[0]?.device_uid &&
          f.device_tag === availabilityPiechart[0]?.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;
  let tagValue1 = mergedArray.length
    ? mergedArray.find(
        (f) =>
          f.device__uid === availabilityPiechart[1]?.device_uid &&
          f.device_tag === availabilityPiechart[1]?.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;
  let tagValue2 = mergedArray.length
    ? mergedArray.find(
        (f) =>
          f.device__uid === availabilityPiechart[2]?.device_uid &&
          f.device_tag === availabilityPiechart[2]?.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;
  let tagValue3 = mergedArray.length
    ? mergedArray.find(
        (f) =>
          f.device__uid === availabilityPiechart[3]?.device_uid &&
          f.device_tag === availabilityPiechart[3]?.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  console.log(
    "tagValuetagValuetagValuetagValuetagValue",
    sliderRealTimeTagData,
    availabilityPiechart[0]?.device_uid,
    availabilityPiechart[0]?.device_tag
  );
  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        // Handle soft tags
        if (row.is_soft_tag && row.soft_tag_id__formula) {
          // Compute current value using the formula
          const evaluatedCurrentValue = SoftTagFormula(
            row.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
          console.log("Evaluated current value:", evaluatedCurrentValue);

          // Set the evaluated value as the new previous value
          setPreviousValues((prev) => ({
            ...prev,
            [row.soft_tag_id__formula]: evaluatedCurrentValue,
          }));

          // Use the previous value from state
          const evaluatedPreviousValue =
            previousValues[row.soft_tag_id__formula] || 0;
          console.log("Evaluated previous value:", evaluatedPreviousValue);
          console.log(
            "evaluatedCurrentValue",
            evaluatedCurrentValue,
            evaluatedPreviousValue
          );

          const statusValue =
            evaluatedPreviousValue !== 0
              ? ((evaluatedCurrentValue - evaluatedPreviousValue) /
                  evaluatedPreviousValue) *
                100
              : 0;

          console.log(
            "evaluatedCurrentValue",
            evaluatedCurrentValue,
            evaluatedPreviousValue,
            statusValue
          );

          return {
            dvName: row.dvName,
            statusValue: statusValue,
            formula: row.soft_tag_id__formula,
          };
        } else {
          const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;

          return {
            dvName: row.dvName,
            statusValue: isFinite(avgCal) ? avgCal : 0.0,
          };
        }
      });

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  useEffect(() => {
    if (realTimeData.length) {
      const topicArray = realTimeData.map(
        (e) => "Hikar/DeviceUID/" + e.device_uid
      );
      // console.log("topicArray", topicArray);
      setTopics(topicArray);
    }
  }, [realTimeData]);
  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  useEffect(() => {
    handleGenerationRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    const intervalId = setInterval(() => {
      handleGenerationRealTimeData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handlePieRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    const intervalId = setInterval(() => {
      handlePieRealTimeData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleWeatherRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    // const intervalId = setInterval(() => {
    //   handleWeatherRealTimeData();
    // }, 60000); // 60000 milliseconds = 1 minute

    // // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleWeatherspeedometerRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    const intervalId = setInterval(() => {
      handleWeatherspeedometerRealTimeData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleInverterRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    const intervalId = setInterval(() => {
      handleInverterRealTimeData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleGenerationRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [11276, 11277, 11278, 11279],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setRealTimeData(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };

  const handlePieRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [11270, 11271, 11272, 11273],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setAvailabilityPiechart(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };

  const handleWeatherRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [11276, 11281],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setWeatherRealTime(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };
  const handleWeatherspeedometerRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [11281, 11282, 11283, 11284, 11285, 11286],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setWeatherRealData(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };

  const handleInverterRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [11287, 11288, 11289, 11290, 11291, 11293, 11292],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setInverterRealTimeData(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };

  const handleDateCo2Time = (event) => {
    setCo2Time(event.target.value);
  };

  const handleDateCostTime = (event) => {
    setCostTime(event.target.value);
  };
  const handleDateInverterTime = (event) => {
    setInverterTime(event.target.value);
  };

  const handleDateWeatherTime = (event) => {
    setWeatherTime(event.target.value);
  };
  const handleDateWeatherValue = (event) => {
    setWeatherValue(event.target.value);
  };

  const handleDateGenerationTime = (event) => {
    setGenerationTime(event.target.value);
  };
  const handleDateGenerationValue = (event) => {
    setGenerationValue(event.target.value);
  };

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  useEffect(() => {
    if (availabilityStartDate && availabilityEndDate) {
      handlePlantPeakDataDateWise();
    }
  }, [availabilityStartDate, availabilityEndDate]);

  useEffect(() => {
    handlePlantPeakData();
  }, [dateRange]);

  const handlePlantPeakDataDateWise = async () => {
    const params = {
      plant_id: param.id,
      peak_tags: 11276,
      peak_tilt_tags: 11281,
      start_date: `${availabilityStartDate} 00:00:00`,
      end_date: `${availabilityEndDate} 23:59:59`,
    };
    try {
      const resp = await getPlantProcessDashboardPlantPeak(params);
      if (resp.status == 200 || resp.status == 201) {
        setPlantPeakDateWiseData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handlePlantPeakData = async () => {
    const params = {
      plant_id: param.id,
      peak_tags: 11276,
      peak_tilt_tags: 11281,
      time_period: dateRange,
    };
    try {
      const resp = await getPlantProcessDashboardPlantPeak(params);
      if (resp.status == 200 || resp.status == 201) {
        setPlantPeakData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (generationValue && generationStartDate && generationEndDate) {
      handleGenerationBarChartDateWise();
    }
  }, [generationValue, generationStartDate, generationEndDate]);

  useEffect(() => {
    handleGenerationGraphData();
    handleGenerationBoxData();
  }, [generationTime,generationValue]);

  const handleGenerationBarChartDateWise = async () => {
    const params = {
      plant_id: param.id,
      start_date: `${generationStartDate} 00:00:00`,
      end_date: `${generationEndDate} 23:59:59`,
    };
    if (generationValue == 1) {
      params["generation_tag"] = 11280;
      params["graph_tag"] = 1;
    } else if (generationValue == 2) {
      params["pr_tag"] = 11279;
      params["graph_tag"] = 2;
    } else {
      params["cuf_tag"] = 11278;
      params["graph_tag"] = 3;
    }

    try {
      const resp = await getHikemmREPlantDashboardDateWiseGeneration(params);
      if (resp.status == 200 || resp.status == 201) {
        setGenerationDateWiseData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleGenerationGraphData = async () => {
    const params = {
      plant_id: param.id,
      time_period: generationTime,
    };
    if (generationValue == 1) {
      params["generation_tag"] = 11280;
      params["graph_tag"] = 1;
    } else if (generationValue == 2) {
      params["pr_tag"] = 11279;
      params["graph_tag"] = 2;
    } else {
      params["cuf_tag"] = 11278;
      params["graph_tag"] = 3;
    }

    try {
      const resp = await getHikemmREPlantDashboardGeneration(params);
      if (resp.status == 200 || resp.status == 201) {
        setGenerationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleGenerationBoxData = async () => {
    const params = {
      plant_id: param.id,
      generation_tag: 11280,
      pr_tag: 11279,
      cuf_tag: 11278,
      time_period: generationTime,
    };
    try {
      const resp = await getHikemmREPlantDashboardGenerationBox(params);
      if (resp.status == 200 || resp.status == 201) {
        setGenartionBoxData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesumit", error);
    }
  };

  useEffect(() => {
    handleWeatherGraphData();
  }, [weatherTime, weatherValue]);

  useEffect(() => {
    if (weatherValue && weatherStartDate && weatherEndDate) {
      handleWeatherGraphDataDateWise();
    }
  }, [weatherValue, weatherStartDate, weatherEndDate]);

  const handleWeatherGraphDataDateWise = async () => {
    const params = {
      plant_id: param.id,
      gti_tag: 11281,
      ghi_tag: 11284,
      wind_speed_tag: 11282,
      ambient_temperature_tag: 11283,
      module_temperature_tag: 11286,
      weather_graph: weatherValue,
      start_date: `${weatherStartDate} 00:00:00`,
      end_date: `${weatherEndDate} 23:59:59`,
    };
    try {
      const resp = await getPlantProcessDashboardWeatherGraphData(params);
      if (resp.status == 200 || resp.status == 201) {
        setWeatherGraphDateWiseData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleWeatherGraphData = async () => {
    const params = {
      plant_id: param.id,
      gti_tag: 11281,
      ghi_tag: 11284,
      wind_speed_tag: 11282,
      ambient_temperature_tag: 11283,
      module_temperature_tag: 11286,
      weather_graph: weatherValue,
      time_period: weatherTime,
    };
    try {
      const resp = await getPlantProcessDashboardWeatherGraphData(params);
      if (resp.status == 200 || resp.status == 201) {
        setWeatherGraphData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (inverterStartDate && inverterEndDate) {
      handleInverterBarChartDateWise();
    }
  }, [inverterStartDate, inverterEndDate]);

  useEffect(() => {
    handleInverterData();
  }, [inverterTime]);

  const handleInverterBarChartDateWise = async () => {
    const params = {
      plant_id: param.id,
      tag_ids: [
        10917, 10938, 10959, 10980, 11001, 11022, 11055, 11090, 11111, 11132,
        11153, 11174, 11195, 11216, 11237,
      ],
      start_date: `${inverterStartDate} 00:00:00`,
      end_date: `${inverterEndDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardDateWiseInverter(params);
      if (resp.status == 200 || resp.status == 201) {
        setInverterDateWiseData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleInverterData = async () => {
    const params = {
      plant_id: param.id,
      tag_ids: [
        10917, 10938, 10959, 10980, 11001, 11022, 11055, 11090, 11111, 11132,
        11153, 11174, 11195, 11216, 11237,
      ],
      time_period: inverterTime,
    };
    try {
      const resp = await getHikemmREPlantDashboardInverter(params);
      if (resp.status == 200 || resp.status == 201) {
        setInverterData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (costStartDate && costEndDate) {
      handleCostDataDateWise();
    }
  }, [costStartDate, costEndDate]);

  useEffect(() => {
    handleCostData();
  }, [costTime]);

  const handleCostDataDateWise = async () => {
    const params = {
      plant_id: param.id,
      cost_saving_tag: 11294,
      start_date: `${costStartDate} 00:00:00`,
      end_date: `${costEndDate} 23:59:59`,
    };
    try {
      const resp = await getPlantProcessDashboardCostSavingData(params);
      setCostDateWiseData(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleCostData = async () => {
    const params = {
      plant_id: param.id,
      cost_saving_tag: 11294,
      time_period: costTime,
    };
    try {
      const resp = await getPlantProcessDashboardCostSavingData(params);
      setCostData(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (co2StartDate && co2EndDate) {
      handleCo2DataDateWise();
    }
  }, [co2StartDate, co2EndDate]);

  useEffect(() => {
    handleCo2Data();
  }, [co2Time]);

  const handleCo2DataDateWise = async () => {
    const params = {
      plant_id: param.id,
      co2_tag: 11295,
      start_date: `${co2StartDate} 00:00:00`,
      end_date: `${co2EndDate} 23:59:59`,
    };
    try {
      const resp = await getPlantProcessDashboardCo2Data(params);
      setCo2DateWiseData(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleCo2Data = async () => {
    const params = {
      plant_id: param.id,
      co2_tag: 11295,
      time_period: co2Time,
    };
    try {
      const resp = await getPlantProcessDashboardCo2Data(params);
      setCo2Data(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="Bpcl-page-wrapper">
        {topics.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
            mqttTagData={mqttTagData}
            setMqttTagData={setMqttTagData}
          />
        ) : null}
        <BPCLGeneration
          generationData={generationData}
          generationDateWiseData={generationDateWiseData}
          generationTime={generationTime}
          generationValue={generationValue}
          handleDateGenerationTime={handleDateGenerationTime}
          handleDateGenerationValue={handleDateGenerationValue}
          generationBoxData={generationBoxData}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={realTimeData}
          mergedArray={mergedArray}
          generationStartDate={generationStartDate}
          generationEndDate={generationEndDate}
          setGenerationStartDate={setGenerationStartDate}
          setGenerationEndDate={setGenerationEndDate}
        />
        <BPCLAvailability
          plantPeakData={plantPeakData}
          plantPeakDateWiseData={plantPeakDateWiseData}
          handleDateRangeChange={handleDateRangeChange}
          dateRange={dateRange}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={availabilityPiechart}
          tagValue={tagValue}
          tagValue1={tagValue1}
          tagValue2={tagValue2}
          tagValue3={tagValue3}
          availabilityStartDate={availabilityStartDate}
          setAvailabilityStartDate={setAvailabilityStartDate}
          availabilityEndDate={availabilityEndDate}
          setAvailabilityEndDate={setAvailabilityEndDate}
        />

        <BPCLWeathers
          handleDateWeatherTime={handleDateWeatherTime}
          handleDateWeatherValue={handleDateWeatherValue}
          weatherTime={weatherTime}
          weatherValue={weatherValue}
          weatherGraphData={weatherGraphData}
          weatherGraphDateWiseData={weatherGraphDateWiseData}
          sliderRealTimeTagData={sliderRealTimeTagData}
          realTimeData={weatherRealTime}
          realWeatherData={weatherRealData}
          mergedArray={mergedArray}
          weatherStartDate={weatherStartDate}
          weatherEndDate={weatherEndDate}
          setWeatherStartDate={setWeatherStartDate}
          setWeatherEndDate={setWeatherEndDate}
        />

        <BPCLInverters
          handleDateInverterTime={handleDateInverterTime}
          inverterTime={inverterTime}
          inverterData={inverterData}
          inverterDateWiseData={inverterDateWiseData}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={inverterRealTimeData}
          mergedArray={mergedArray}
          inverterStartDate={inverterStartDate}
          setInverterStartDate={setInverterStartDate}
          inverterEndDate={inverterEndDate}
          setInverterEndDate={setInverterEndDate}
        />

        <BPCLCost
          costData={costData}
          costDateWiseData={costDateWiseData}
          handleDateCostTime={handleDateCostTime}
          costTime={costTime}
          costStartDate={costStartDate}
          costEndDate={costEndDate}
          setCostStartDate={setCostStartDate}
          setCostEndDate={setCostEndDate}
          handleDateCo2Time={handleDateCo2Time}
          co2Time={co2Time}
          co2Data={co2Data}
          co2DateWiseData={co2DateWiseData}
          co2StartDate={co2StartDate}
          co2EndDate={co2EndDate}
          setCo2StartDate={setCo2StartDate}
          setCo2EndDate={setCo2EndDate}
        />
        {/* <Grid container spacing={1} className="deshbord_BPCL sticky-container">
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="card_weather1"
              onClick={() =>
                navigate(
                  `/energy_trends/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              <Typography variant="h5" className="deshbord_bpcl">
                ENERGY TRENDS
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="card_weather1">
              <Typography
                variant="h5"
                className="deshbord_bpcl"
                onClick={() =>
                  navigate(
                    `/inverters/company/${param.companyId}/plant/${param.id}`
                  )
                }
              >
                INVERTERS
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="card_weather1"
              onClick={() =>
                navigate(
                  `/reports/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              <Typography variant="h5" className="deshbord_bpcl">
                REPORTS
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            onClick={() =>
              navigate(
                `/bpcl-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }
          >
            <div className="card_weather1">
              <Typography variant="h5" className="deshbord_bpcl">
                ANALYTICS
              </Typography>
            </div>
          </Grid>
        </Grid> */}
      </div>
      <div>
        <Grid container className="sticky-container" spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <button
              className="bpcl-pro-dashboard-btns"
              onClick={() =>
                navigate(
                  `/energy_trends/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              ENERGY TRENDS
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <button
              className="bpcl-pro-dashboard-btns"
              onClick={() =>
                navigate(
                  `/inverters/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              INVERTERS
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <button
              className="bpcl-pro-dashboard-btns"
              onClick={() =>
                navigate(
                  `/reports/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              REPORTS
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <button
              className="bpcl-pro-dashboard-btns"
              onClick={() =>
                navigate(
                  `/bpcl-analytics/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              ANALYTICS
            </button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default BPCLProcessDashboard;