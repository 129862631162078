import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts);
exporting(Highcharts);

const SinglePlantCombineChart = ({
  barChartData,
  lineChartData,
  colorCode,
}) => {
  // Merge barChartData and lineChartData by plant_id
  const combinedData = [];
  console.log("combinedData", lineChartData);

  if (barChartData.length > 0 && lineChartData.length > 0) {
    const barPlant = barChartData[0];
    const linePlant = lineChartData[0];

    // Determine the number of points based on the length of capacity_data or generation_value
    const length = Math.max(
      barPlant.capacity_data.length,
      linePlant.generation_value.length
    );

    for (let i = 0; i < length; i++) {
      const capacityDataPoint = barPlant.capacity_data[i] || {};
      const generationDataPoint = linePlant.generation_value[i] || {};

      // Extract only the date portion from MIN_KEY
      const date = capacityDataPoint.MIN_KEY
        ? capacityDataPoint.MIN_KEY.split(" ")[0]
        : generationDataPoint.MIN_KEY
        ? generationDataPoint.MIN_KEY.split(" ")[0]
        : "";

      combinedData.push({
        plant_id: barPlant.plant_id,
        plant_name: barPlant.plant_name,
        plant_capacity: parseFloat(capacityDataPoint.capacity || 0), // Use 0 if missing
        generation_value: parseFloat(generationDataPoint.tag_value || 0), // Use 0 if missing
        MIN_KEY: date, // Take only the date part
      });
    }
  }

  // Calculate average values for the combined dataset
  const averageData = combinedData.map((item) => {
    return (item.plant_capacity + item.generation_value) / 2;
  });

  // Dynamically calculate the minimum values for each y-axis
  const minMW = Math.min(0, ...combinedData.map((item) => item.plant_capacity));
  const minBU = Math.min(
    0,
    ...combinedData.map((item) => item.generation_value)
  );

  const options = {
    chart: {
      type: "column",
    },
    scrollbar: {
      enabled: true,
    },
    title: {
      text: null, // No title
    },
    xAxis: {
      categories: combinedData.map((item) => item.MIN_KEY), // Use plant_name as categories
    },
    yAxis: [
      {
        title: {
          text: "MW",
        },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: false,
      },
      {
        title: {
          text: "MWh",
        },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const index = this.points[0].point.index; // Get the current point index
        const xValue = this.points[0].x; // Get the x-value (usually the MIN_KEY or timestamp)

        // Convert xValue to just the date portion to compare with MIN_KEY (if required)
        const xDate = new Date(xValue).toISOString().split("T")[0]; // Extract date portion (YYYY-MM-DD)

        // Find the generation_value entry that matches the xDate
        const generationData = lineChartData[0]?.generation_value.find(
          (item) => item.MIN_KEY.split(" ")[0] === xDate
        );

        const fullMIN_KEY = generationData ? generationData.MIN_KEY : "N/A";

        // Calculate average value (ensure the index matches the combinedData)
        const averageValue = averageData[index]?.toFixed(2) || "0.00"; // Safely retrieve the average value

        // Construct the tooltip content
        let tooltipContent = `<b>${fullMIN_KEY}</b><br/>`;

        this.points.forEach((point) => {
          tooltipContent += `
            <span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b><br/>`;
        });

        // tooltipContent += `
        //   <span style="color:#4CAF50">\u25CF</span> Average: <b>${averageValue}</b><br/>`;

        return tooltipContent;
      },
    },

    credits: {
      enabled: false, // Remove the Highcharts logo
    },
    legend: {
      enabled: false, // Remove the legend
    },
    scrollbar: {
      enabled: true,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 5,
        groupPadding: 0.1,
        pointPadding: 0.1,
      },
      line: {
        lineWidth: 1,
        marker: {
          enabled: true,
          radius: 4,
          symbol: "circle",
          fillColor: "#FFFFFF",
          lineWidth: 1,
          lineColor: "black",
        },
      },
    },
    series: [
      {
        name: "MW",
        type: "column",
        data: combinedData.map((item) => item.plant_capacity), // Use plant_capacity for MW data
        color: colorCode[0],
        yAxis: 0,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: "MW",
        type: "line",
        data: combinedData.map((item) => item.generation_value), // Use generation_value for MW data
        color: "#45748b",
        yAxis: 1,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: "Average",
        type: "line",
        data: averageData.map((value) => value), // Average of bar and line data
        color: "#4CAF50",
        dashStyle: "Dash",
        marker: {
          enabled: false,
        },
        enableMouseTracking: true,
        yAxis: 0, // Align with the MW axis
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SinglePlantCombineChart;
