import React, { useEffect, useState } from "react";
import { getOverviewReportGraphData } from "./services";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const OverViewAnalyticsReportApiData = ({
  showTimeReport,
  setFisrtLineChartReportData,
  setSecondLineChartReportData,
  selectFirstUnit,
  selectAggReport,
  selectIntervalReport,
  deviceIdReportRedux,
  deviceUnitReportRedux,
  selectSecondUnit,
  startTimeFormatRep,
  endTimeFormatRep,
  formatStartDateRep,
  formatEndDateRep,
  setLoadingReport,
  setisShimmerMultiAxisLineChartReports,
}) => {
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const param = useParams();
  console.log("selectFirstUnit", selectFirstUnit, selectSecondUnit);

  const handleAnalyticsChartLineData1 = async () => {
    const findDeviceByPlant = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if ((findDeviceByPlant.length, formatStartDateRep, formatEndDateRep)) {
    }
    try {
      setisShimmerMultiAxisLineChartReports(true);
      setLoadingReport(true);
      const params = {
        device_id: findDeviceByPlant.map((row) => row.deviceID),
        tag_unit: selectFirstUnit,
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        unit: selectUnit,
        aggregate: Number(selectAggReport),
        interval: Number(selectIntervalReport),
        // aggregate: Number(selectAggReport),
      };

      if (showTimeReport) {
        params["start_date"] = `${formatStartDateRep} ${startTimeFormatRep}`;
        params["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      // if (["kwh", "kvah", "kvarh "].includes(selectFirstUnit.toLowerCase())) {
      //   params["aggregate"] = Number(selectAggReport);
      //   console.log("selectFirstUnit",selectFirstUnit);
      // } else {
      //   params["interval"] = Number(selectIntervalReport);
      // }

      // if (["kwh", "kvah", "kvarh","agg"].some(unit => selectFirstUnit.toLowerCase().includes(unit))) {
      //   params["aggregate"] = Number(selectAggReport);
      //   console.log("selectFirstUnit", selectFirstUnit);
      // } else {
      //   params["interval"] = Number(selectIntervalReport);
      // }

      const resp = await getOverviewReportGraphData(params);
      if (resp.data.success === true) {
        setTimeout(() => {
          setisShimmerMultiAxisLineChartReports(false);
        }, 2000);
        setLoadingReport(false);
        console.log("kp", resp.data);
        setFisrtLineChartReportData(resp.data.payload);
        // setAnalyticsData(resp.data.payload);
      }
    } catch (error) {
      setFisrtLineChartReportData([]);
      setisShimmerMultiAxisLineChartReports(false);
      setLoadingReport(false);
      console.log("handleAnalyticsData", error);
    }
  };

  const handleAnalyticsChartLineData2 = async () => {
    const findDeviceByPlant = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if ((findDeviceByPlant.length, formatStartDateRep, formatEndDateRep)) {
    }
    try {
      setisShimmerMultiAxisLineChartReports(true);
      setLoadingReport(true);
      const params = {
        device_id: findDeviceByPlant.map((row) => row.deviceID),
        tag_unit: selectSecondUnit,
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        unit: selectUnit,
        aggregate: Number(selectAggReport),
        interval: Number(selectIntervalReport),
        // aggregate: Number(selectAggReport),
      };

      if (showTimeReport) {
        params["start_date"] = `${formatStartDateRep} ${startTimeFormatRep}`;
        params["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      // if (["kwh", "kvah", "kvah"].includes(selectSecondUnit.toLowerCase())) {
      //   params["aggregate"] = Number(selectAggReport);
      //   console.log("selectFirstUnit123", selectSecondUnit);
      // } else {
      //   params["interval"] = Number(selectIntervalReport);
      // }

      // if (["kwh", "kvah", "kvarh","agg"].some(unit => selectSecondUnit.toLowerCase().includes(unit))) {
      //   params["aggregate"] = Number(selectAggReport);
      //   console.log("selectFirstUnit2", selectSecondUnit);
      // } else {
      //   params["interval"] = Number(selectIntervalReport);
      // }

      const resp = await getOverviewReportGraphData(params);
      if (resp.data.success === true) {
        setTimeout(() => {
          setisShimmerMultiAxisLineChartReports(false);
        }, 2000);
        setLoadingReport(false);
        console.log("kp", resp.data);
        setSecondLineChartReportData(resp.data.payload);
        // setAnalyticsData(resp.data.payload);
      }
    } catch (error) {
      setSecondLineChartReportData([]);
      setisShimmerMultiAxisLineChartReports(false);
      setLoadingReport(false);
      console.log("handleAnalyticsData", error);
    }
  };

  useEffect(() => {
    if (deviceIdReportRedux.length && formatStartDateRep && formatEndDateRep) {
      handleAnalyticsChartLineData1();
    }
  }, [
    selectFirstUnit,
    deviceIdReportRedux,
    selectAggReport,
    startTimeFormatRep,
    endTimeFormatRep,
    formatStartDateRep,
    formatEndDateRep,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    selectIntervalReport,
    selectAggReport,
    selectUnit,
  ]);

  useEffect(() => {
    if (deviceIdReportRedux.length && formatStartDateRep && formatEndDateRep) {
      handleAnalyticsChartLineData2();
    }
  }, [
    selectSecondUnit,
    deviceIdReportRedux,
    selectAggReport,
    startTimeFormatRep,
    endTimeFormatRep,
    formatStartDateRep,
    formatEndDateRep,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    selectIntervalReport,
    selectAggReport,
    selectUnit,
  ]);

  return <div>{false ? "" : ""}</div>;
};
